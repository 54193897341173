import { toast } from "react-toastify";
import { HTTPURL } from '../../../constants/Matcher';

// get all comments
export async function get_comments() {
    try {
        const response = await fetch(HTTPURL + 'api/comments-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}



// add comments
export async function add_comment(data) {
    try {
        const response = await fetch(HTTPURL + 'api/comment-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// comments delete
export async function comment_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/comment-destroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// comments edit 
export async function comment_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/comment-edit?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// comments update 
export async function comment_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/comment-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
