import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import { class_list_all } from '../../../../apis/ClassManagement/ClassList';
import { class_detail_destroy, class_details_all, get_data_class_id } from '../../../../apis/ClassManagement/ClassDetails';
import CustomTable from '../../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Delete',
        selector: 'action',
        sortable: true,
    }
];

const AllClassDetails = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [className, setClassName] = useState([]);

    const onChange = async (event) => {
        const value = event.target.value;
        let resClassData = await get_data_class_id(value);
        setData(resClassData, setShowResults(true));
    };
    useEffect(() => {
        async function get_class_list() {
            const result = await class_list_all();
            setClassName(result?.data);
        }
        get_class_list();

        async function get_data() {
            const result = await class_details_all();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, subject, book_page, movie_name } = elem;

                return {
                    no: index + 1,
                    subject: subject,
                    name: movie_name,
                    action: <div data-tag="allowRowEvents">
                        <Link class="edit-btn" href={`/admin/class-detail/edit/${id}`}>
                            <i class="fa fa-pencil" aria-hidden="true"></i></Link>
                        <button className="dlt-btn" onClick={() => destroy(id)}> <i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete this class details?");
            if (result) {
                class_detail_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Class Details List</h4>
                </div>
                {/* <div className='col-md-8'>
                    <Link className='ui button float-end' to="/admin/class-detail/add">Add Class Detail</Link>
                </div> */}
            </div>
            <div className='row'>
                <div className='col-md-3 col-sm-12'>
                    <div className='form-group mt-4'>
                        <label>Select a class:</label>
                        <select className='form-select form-control' onChange={onChange} name="select_class_id">
                            <option value="">Choose Class Name</option>
                            {
                                className && className?.map((elem) => {
                                    return <option value={elem?.id}>{elem?.c_name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>

            {
                showResults ?

                    <CustomTable
                        columns={columns}
                        data={data}
                    />
                    :
                    <DataLoader />
            }
        </div>
    )
}

export default AllClassDetails;
