import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { comment_edit, comment_update } from '../../../../apis/testManagement/writingComment'
import { get_item } from '../../../../apis/testManagement/Items'
import { useEffect, useState } from 'react';


const EditWritingComment = () => {
    const [item, setItem] = useState([]);

    useEffect(() => {
        async function get_data() {
            const result = await get_item();
            setItem(result?.data);
        }
        get_data();
    }, []);
    
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const id = useParams();
    const onSubmit = async (data) => {
        let result = await comment_update(id, data);
        if (result.status) {
            return navigate('/admin/test-management/writing-comments');
        }
    }

    useEffect(() => {
        async function get_data() {
            const result = await comment_edit(id);
            const { an_item, comment_description, score } = result?.data[0];
            setValue('an_item', an_item);
            setValue('comment_description', comment_description);
            setValue('score', score);
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Comment</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>An item</label>
                                    <select className=" form-select form-control" name="an_item" {...register("an_item")}>
                                        <option value="">Please Choose Item</option>
                                        {item?.map((ele) => {
                                            return (<>
                                                <option value={ele.id}>{ele.item_name}</option>
                                            </>
                                            )
                                        })}

                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Score</label>
                                    <input type="text" className="form-control" name="score"  {...register('score')} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Comment Description</label>
                                    <textarea className="form-control" name="comment_description" rows="3" {...register('comment_description')}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default EditWritingComment;
