import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const EditEassyWriting = () => {

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await `add_comment(data)`;
        if (result.status) {
            return navigate('/admin/test-management/writing-comments');
        }
    }

    useEffect(() => {
        
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modify essay writing</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>ID</label>
                                    <input type="text" className="form-control" name="id" value={`QuintinLin`} {...register('id')} readOnly />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Content/Relevant details</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('content_relevant')} id="inlineRadio0" value="0" />
                                        <label class="form-check-label" for="inlineRadio0">0</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('content_relevant')} id="inlineRadio1" value="1" />
                                            <label class="form-check-label" for="inlineRadio1">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('content_relevant')} id="inlineRadio2" value="2" />
                                            <label class="form-check-label" for="inlineRadio2">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('content_relevant')} id="inlineRadio3" value="3" />
                                            <label class="form-check-label" for="inlineRadio3">3</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('content_relevant')} id="inlineRadio4" value="4" />
                                            <label class="form-check-label" for="inlineRadio4">4</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Form/Structure</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('form_structure')} id="inlineRadio0" value="0" />
                                        <label class="form-check-label" for="inlineRadio0">0</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('form_structure')} id="inlineRadio1" value="1" />
                                            <label class="form-check-label" for="inlineRadio1">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('form_structure')} id="inlineRadio2" value="2" />
                                            <label class="form-check-label" for="inlineRadio2">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('form_structure')} id="inlineRadio3" value="3" />
                                            <label class="form-check-label" for="inlineRadio3">3</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('form_structure')} id="inlineRadio4" value="4" />
                                            <label class="form-check-label" for="inlineRadio4">4</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Organising ideas/ Paragraphs/Devices</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('organising_ideas')} id="inlineRadio0" value="0" />
                                        <label class="form-check-label" for="inlineRadio0">0</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('organising_ideas')} id="inlineRadio1" value="1" />
                                            <label class="form-check-label" for="inlineRadio1">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('organising_ideas')} id="inlineRadio2" value="2" />
                                            <label class="form-check-label" for="inlineRadio2">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('organising_ideas')} id="inlineRadio3" value="3" />
                                            <label class="form-check-label" for="inlineRadio3">3</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('organising_ideas')} id="inlineRadio4" value="4" />
                                            <label class="form-check-label" for="inlineRadio4">4</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Style/Vocabulary</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('style_vocabulary')} id="inlineRadio0" value="0" />
                                        <label class="form-check-label" for="inlineRadio0">0</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('style_vocabulary')} id="inlineRadio1" value="1" />
                                            <label class="form-check-label" for="inlineRadio1">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('style_vocabulary')} id="inlineRadio2" value="2" />
                                            <label class="form-check-label" for="inlineRadio2">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('style_vocabulary')} id="inlineRadio3" value="3" />
                                            <label class="form-check-label" for="inlineRadio3">3</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Sentences</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('sentences')} id="inlineRadio0" value="0" />
                                        <label class="form-check-label" for="inlineRadio0">0</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('sentences')} id="inlineRadio1" value="1" />
                                            <label class="form-check-label" for="inlineRadio1">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('sentences')} id="inlineRadio2" value="2" />
                                            <label class="form-check-label" for="inlineRadio2">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('sentences')} id="inlineRadio3" value="3" />
                                            <label class="form-check-label" for="inlineRadio3">3</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('sentences')} id="inlineRadio4" value="4" />
                                            <label class="form-check-label" for="inlineRadio4">4</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Spelling/Grammar</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('spelling_grammar')} id="inlineRadio0" value="0" />
                                        <label class="form-check-label" for="inlineRadio0">0</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('spelling_grammar')} id="inlineRadio1" value="1" />
                                            <label class="form-check-label" for="inlineRadio1">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('spelling_grammar')} id="inlineRadio2" value="2" />
                                            <label class="form-check-label" for="inlineRadio2">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('spelling_grammar')} id="inlineRadio3" value="3" />
                                            <label class="form-check-label" for="inlineRadio3">3</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Punctuation</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('punctuation')} id="inlineRadio0" value="0" />
                                        <label class="form-check-label" for="inlineRadio0">0</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('punctuation')} id="inlineRadio1" value="1" />
                                            <label class="form-check-label" for="inlineRadio1">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('punctuation')} id="inlineRadio2" value="2" />
                                            <label class="form-check-label" for="inlineRadio2">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('punctuation')} id="inlineRadio3" value="3" />
                                            <label class="form-check-label" for="inlineRadio3">3</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control" name="description" rows="3" {...register('description')}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default EditEassyWriting;
