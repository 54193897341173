import React, { useEffect, useState } from 'react'
import { get_question } from '../../../../apis/testManagement/Question.jsx';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
import { AddeTutoring } from './Add.jsx';
export const EditeTutoring = ({ setEtutoring, eTutroing,setEtutoringData,eTuoringData }) => {
    const [subjects, setSubject] = useState([]);
    const [question, setQuestion] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const questionList = await get_question();
            const result = await subject_all();
            setQuestion(questionList?.data);
            setSubject(result?.data);
        };
        fetchData();
    }, []);
    //lecture handle change

    //SelfStudy handle change for image
    const handleEtutoringDataChange = (index, fieldname, value) => {
        if (fieldname == 'subject_id') {
            localStorage.setItem('courseSubjectID', value)
        }
        const updatedData = [...eTuoringData];
        updatedData[index] = { ...updatedData[index], [fieldname]: value };
        setEtutoringData(updatedData);
    };
    //lecture handle change for image
    const handleEtutoringImgChange = (e, index) => {
        const { name, files } = e.target;
        const list = [...eTuoringData];
        list[index][name] = files[0];
        setEtutoringData(list);
    }
   
    return (
        <>
              {
                eTuoringData && eTuoringData.length > 0 ?

                eTuoringData && eTuoringData?.map((x, i, data) => {
                    return (
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel1" aria-labelledby="pills-home-tab">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6" key={i}>
                                        <div className="form-group">
                                            <label>Questionaire {i + 1}</label>
                                            <select className="form-control form-select"
                                                name='q_id' value={x.q_id}   onChange={(e) => handleEtutoringDataChange(i, 'q_id', e.target.value)}>
                                                <option value="">Choose Question</option>
                                                {
                                                    question && question?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.question_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Subject</label>
                                            <select className="form-control form-select"
                                                name='subject_id' value={x.subject_id}   onChange={(e) => handleEtutoringDataChange(i, 'subject_id', e.target.value)}>
                                                <option value="">Choose Subject</option>
                                                {
                                                    subjects && subjects?.map((elem) => {
                                                        return <option value={elem?.id}>{elem?.subject}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Document</label>
                                            <input type="file" className="form-control"
                                                name='u_document' onChange={(e) => handleEtutoringImgChange(e, i)}
                                                accept=".pdf" />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Embedded Link</label>
                                            <input type="url" className="form-control"
                                                placeholder='Enter Link'
                                                value={x.link}
                                                name='link'
                                                onChange={(e) => handleEtutoringDataChange(i, 'link', e.target.value)} />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Image</label>
                                            <input type="file" className="form-control"
                                                name='u_image'
                                                onChange={(e) => handleEtutoringImgChange(e, i)} />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Audio</label>
                                            <input type="file" className="form-control"
                                                name='u_audio'
                                                onChange={(e) => handleEtutoringImgChange(e, i)}
                                                accept='audio/*' />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    )
                }):<AddeTutoring setEtutoring={setEtutoring} eTutroing={eTutroing}/>
            }
        </>
    )
}
