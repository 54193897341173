import React from 'react';
import { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { subject_all, branch_all, grade_all } from '../../../../apis/common';
import { admin_class_list_all } from '../../../../apis/ClassManagment/ClassList';
import { homeWork_add } from '../../../../apis/HomeWork'
const EditClassList = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [subjects, setSubject] = useState([]);
    const [gradelist, setGradeList] = useState([]);
    const [branch, setBranch] = useState([]);
    const [images, setImages] = useState([]);
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm();
    const id = useParams();
    const navigate = useNavigate();
    const handleFileChange = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            if (!images.includes(e.target.files[i])) {
                setImages(pre => [...pre, e.target.files[i]])
            }

        }
    }
    const onSubmit = async (data) => {
        if (currentStep === 2) {
            const form = new FormData();
            for (const img of images) {
                form.append('file', img);
            }
            form.append('status', getValues('status'))
            form.append('description', getValues('description'))
            form.append('class_id', id?.id)
            let result = await homeWork_add(form);
            if (result.status) {
                return navigate('/teacher/class-list');
            }
        } else {
            // Move to the next step
            setCurrentStep(currentStep + 1);
        }
    }
    const fetchData = async () => {
        let res = await admin_class_list_all(id.id);
        setValue('subject_id', res?.data?.subject_id);
        setValue('c_name', res?.data?.c_name);
        setValue('branch', res?.data?.branch);
        setValue('grade', res?.data?.grade);
    }
    useEffect(() => {
        async function get_data() {
            const result = await subject_all();
            const gradeList = await grade_all();
            const branchList = await branch_all();
            setGradeList(gradeList?.data);
            setSubject(result?.data);
            setBranch(branchList?.data)
        }
        get_data();
        fetchData();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Class Details</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(1)} className={`nav-link ${currentStep === 1 ? 'active ' : ''}`} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Class Detail</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(2)} className={`nav-link ${currentStep === 2 ? 'active ' : ''}`} id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Add HomeWork</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            {currentStep === 1 && (
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Branch Name</label>
                                            <select className="form-control form-select" {...register("branch")} disabled>
                                                <option value="">Choose Branch</option>
                                                {
                                                    branch && branch?.map((elem) => {
                                                        return <option value={elem?.id}>{elem?.branch_name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Subject</label>
                                            <select className="form-control form-select" {...register("subject_id")} disabled>
                                                <option value="">Choose Subject</option>
                                                {
                                                    subjects && subjects?.map((elem) => {
                                                        return <option value={elem?.id}>{elem?.subject}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Grade</label>
                                            <select className="form-control form-select"  {...register("grade")} disabled>
                                                <option value="">Choose Grade</option>
                                                {
                                                    gradelist && gradelist?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {/* {errors.grade && errors.grade.type === "required" && <span className="validation-error">This is required</span>} */}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Class Name</label>
                                            <input type="text" className="form-control" name="movie_period"  {...register('c_name')} disabled />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {currentStep === 2 && (
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>status</label>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio1" value="1" />
                                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio2" value="0" />
                                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                                    </div>
                                                </div>
                                                {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Upload Material</label>
                                                    <input type="file" className="form-control"
                                                        {...register("file")} onChange={(e) => handleFileChange(e)} />

                                                </div>
                                                {errors.study_file && errors.study_file.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label> Description</label>
                                                    <textarea className="form-control"
                                                        placeholder='Enter Description'
                                                        rows="3" {...register('description')}></textarea>
                                                </div>
                                                {errors.description && errors.description.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            {currentStep > 1 && (
                                <button className="btn btn-secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                                    Previous
                                </button>
                            )}
                            {currentStep < 2 && (
                                <button className="btn btn-primary">
                                    Next
                                </button>
                            )}
                            {currentStep === 2 && (
                                <button type="submit" className="btn btn-primary">
                                    Save
                                </button>
                            )}
                        </div>
                        {/* <div className="row">
                            <div className="col-12">
                                <hr className='mt-3' />
                                <div className="col-md-12">
                                    <label><b>Type</b></label>
                                </div>
                                <div className="form-group">
                                    <label>Title</label>
                                    <input type="text" className="form-control" name="movie_title"
                                        {...register('movie_title')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Period</label>
                                    <input type="text" className="form-control" name="movie_period"  {...register('movie_period')} /> days
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Price</label>
                                    <input type="number" className="form-control" name="movie_price"  {...register('movie_price')} /> $
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Class Info.</label>
                                    <textarea className="form-control" name="class_info" rows="3" {...register('class_info')}></textarea>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div> */}
                    </Form>
                </div>
            </div>
        </div>
    )
}
export default EditClassList;
