import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { grade_all } from "../../../apis/Grade";
import { course_add, course_consulting, course_etutoring, course_evaluation, course_homework, course_lecture, course_self_study } from "../../../apis/Course";
import { get_category } from "../../../apis/category";
import { get_terms } from "../../../apis/Common.jsx";
import { get_question } from "../../../apis/testManagement/Question.jsx";
import {
  subject_all,
  subject_by_coursetype,
  unit_all,
} from "../../../apis/ClassManagement/Subjects.jsx";
import AddAssessment from "./Assessment/Add.jsx";
import AddLecture from "./Lecture/Add.jsx";
import AddHomeWork from "./HomeWork/Add.jsx";
import AddSelfStudy from "./SelfStudy/Add.jsx";
import { AddeTutoring } from "./E_Tutoring/Add.jsx";
import AddConsultings from "./Consulting/Add.jsx";
import AddEvaluation from "./Evaluation/Add.jsx";
import AddSubjects from "./Subject/Add.jsx";
const AddCourse = () => {
  const [subjects, setSubject] = useState([]);
  const [coursesubjects, setCoursesubjects] = useState([]);
  const [coursesubjectsId, setCoursesubjectsId] = useState([]);
  const [selectedSubjects, setSelectedSubject] = useState([]);
  const [units, setUnits] = useState([]);
  const [gradelist, setGradeList] = useState([]);
  const [question, setQuestion] = useState([]);
  const [courseType, setCourseType] = useState([]);
  const [termsList, setTermList] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [learnigStep, setLearningStep] = useState(1);
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);
  const [date, setDate] = useState("");
  const [homeWorkDate, setHomeWorkDate] = useState("");
  // const subject = useContext(SubjectContext);
  const id = localStorage.getItem("courseSubjectID");
  const unit_id = localStorage.getItem("courseUnitID");
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [inputList, setInputList] = useState([
    {
      u_document: "",
      link: "",
      u_image: "",
      u_audio: "",
      q_id: "",
      subject_id: id,
    },
  ]);

  const [sub, setSub] = useState([
    {
      unit_id: unit_id,
      week: "",
      q_id: "",
      u_image: "",
      subject_id: id,
      u_audio: "",
      description: "",
      u_document: "",
      link: "",
    },
  ]);

  const [lecture, setLecture] = useState([
    {
      unit: "",
      week: "",
      q_id: "",
      u_image: "",
      subject_id: id,
      u_audio: "",
      description: "",
      u_document: "",
      link: "",
    },
  ]);
  const [homeWork, setHomeWork] = useState([
    {
      unit: "",
      week: "",
      q_id: "",
      u_image: "",
      subject_id: id,
      u_audio: "",
      description: "",
      u_document: "",
      link: "",
    },
  ]);
  const [selfStudy, setSelfStudy] = useState([
    {
      q_id: "",
     // subject_id: id,
      u_document: "",
      link: "",
      u_image: "",
      u_audio: "",
    },
  ]);
  const [eTutroing, setEtutoring] = useState([
    {
      q_id: "",
      subject_id: id,
      u_document: "",
      link: "",
      u_image: "",
      u_audio: "",
    },
  ]);
  const [consulting, setConsulting] = useState([
    {
      q_id: "",
      subject_id: id,
      u_document: "",
      link: "",
      u_image: "",
      u_audio: "",
    },
  ]);
  const [evaluation, setEvaluation] = useState([
    {
      q_id: "",
      subject_id: id,
      u_document: "",
      link: "",
      u_image: "",
      u_audio: "",
    },
  ]);
  useEffect(() => {
    setHomeWork((prevHomeWork) =>
      prevHomeWork.map((hw) => ({
        ...hw,
        subject_id: id,
      }))
    );
    setSub((prev) =>
      prev.map((le) => ({
        ...le,
        subject_id: id,
        unit_id: unit_id,
      }))
    );
    setLecture((prev) =>
      prev.map((le) => ({
        ...le,
        subject_id: id,
      }))
    );
    setSelfStudy((prev) =>
      prev.map((le) => ({
        ...le,
        subject_id: id,
      }))
    );
    setConsulting((prev) =>
      prev.map((le) => ({
        ...le,
        subject_id: id,
      }))
    );
    setEvaluation((prev) =>
      prev.map((le) => ({
        ...le,
        subject_id: id,
      }))
    );
    setEtutoring((prev) =>
      prev.map((le) => ({
        ...le,
        subject_id: id,
      }))
    );
  }, [id]);

  console.log("evaluation", evaluation);

  const handleFileChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      if (!images.includes(e.target.files[i])) {
        setImages((pre) => [...pre, e.target.files[i]]);
      }
    }
  };

  const onSubmit = async () => {
    const form = new FormData();
    form.append("image", file);
    form.append("grade_id", getValues("grade_id"));
    form.append("price", getValues("price"));
    const totalUnits = getValues("totalUnits");
    form.append("totalUnits", totalUnits);
    localStorage.setItem("totalUnits", totalUnits);
    form.append("term_id", getValues("term_id"));
    form.append("course_type_id", getValues("course_type_id"));
    form.append("name", getValues("name"));
    form.append("status", getValues("status"));
    inputList &&
      inputList?.forEach((item, index) => {
        for (const key in item) {
          form.append(`assessment[${index}][${key}]`, item[key]);
        }
      });
    if (currentStep === 1) {
      let result = await course_add(form);
      if (result.status) {
        let course_id = result.data;
        
        localStorage.setItem("course_id", course_id); // Store course_id in localStorage
        setCurrentStep(currentStep + 1); // Move to the next step
        setLearningStep(1);
      }
    } else {
      // Move to the next step
      setCurrentStep(currentStep + 1);
    }
  };

  const onSubmit2 = async () => {
    const form = new FormData();
    const course_id = localStorage.getItem("course_id");
    form.append("course_id", course_id);
    
    // form.append("subject_id", selectedSubjects);

    inputList &&
      inputList.forEach((item, index) => {
        for (const key in item) {
          form.append(`assessment[${index}][${key}]`, item[key]);
        }
      });

    form.append("sub[0][start_date]", date);
    form.append("sub[0][subject_id]", selectedSubjects);
    sub.forEach((item, index) => {
      for (const key in item) {
        form.append(`sub[${index}][${key}]`, item[key]);
      }
    });

    let result = await course_lecture(form,course_id);
    if (result.status) {
     // setLearningStep(2); // Update the step
    } else {
    //  setLearningStep(2); // Handle failure case
    }
  };

  // const onSubmit2 = async () => {
  //     const form = new FormData();
  //     const course_id = localStorage.getItem('course_id');
  //     form.append("course_id", course_id);

  //     inputList && inputList.forEach((item, index) => {
  //         for (const key in item) {
  //             form.append(`assessment[${index}][${key}]`, item[key]);
  //         }
  //     });

  //     form.append("lecture[0][start_date]", date);
  //     lecture.forEach((item, index) => {
  //         for (const key in item) {
  //             form.append(`lecture[${index}][${key}]`, item[key]);
  //         }
  //     });

  //     console.log('Before submission, learnigStep:', learnigStep);

  //     let result = await course_add(form);

  //     if (result.status) {
  //         setLearningStep(2); // Update the step
  //     } else {
  //         setLearningStep(2); // Handle failure case
  //     }
  // };

  const onSubmit3 = async () => {
    const form = new FormData();
    const course_id = localStorage.getItem("course_id");
    form.append("course_id", course_id);

    inputList &&
      inputList.forEach((item, index) => {
        for (const key in item) {
          form.append(`assessment[${index}][${key}]`, item[key]);
        }
      });

      form.append("selfDir[0][subject_id]", selectedSubjects);
    selfStudy.forEach((item, index) => {
      for (const key in item) {
        form.append(`selfDir[${index}][${key}]`, item[key]);
      }
    });

    let result = await course_self_study(form,course_id);
    if (result.status) {
     // setLearningStep(3); // Update the step
    } else {
    //  setLearningStep(3); // Handle failure case
    }
  };

  const onSubmit4 = async () => {
    const form = new FormData();
    const course_id = localStorage.getItem("course_id");
    form.append("course_id", course_id);
    inputList &&
      inputList.forEach((item, index) => {
        for (const key in item) {
          form.append(`assessment[${index}][${key}]`, item[key]);
        }
      });
    form.append("homework[0][start_date]", homeWorkDate);
    form.append("homework[0][subject_id]", selectedSubjects);
    homeWork.forEach((item, index) => {
      for (const key in item) {
        form.append(`homework[${index}][${key}]`, item[key]);
      }
    });

    let result = await course_homework(form,course_id);
    if (result.status) {
     // setLearningStep(4); // Update the step
    } else {
    //  setLearningStep(4); // Handle failure case
    }
  };

  const onSubmit5 = async () => {
    const form = new FormData();
    const course_id = localStorage.getItem("course_id");
    form.append("course_id", course_id);
    inputList &&
      inputList.forEach((item, index) => {
        for (const key in item) {
          form.append(`assessment[${index}][${key}]`, item[key]);
        }
      });
      form.append("etutoring[0][subject_id]", selectedSubjects);
    eTutroing.forEach((item, index) => {
      for (const key in item) {
        form.append(`etutoring[${index}][${key}]`, item[key]);
      }
    });

    let result = await course_etutoring(form,course_id);
    if (result.status) {
    //  setLearningStep(5); // Update the step
    } else {
    //  setLearningStep(5); // Handle failure case
    }
  };

  const onSubmit6 = async () => {
    const form = new FormData();
    const course_id = localStorage.getItem("course_id");
    form.append("course_id", course_id);
    inputList &&
      inputList.forEach((item, index) => {
        for (const key in item) {
          form.append(`assessment[${index}][${key}]`, item[key]);
        }
      });
      form.append("consulting[0][subject_id]", selectedSubjects);
    consulting.forEach((item, index) => {
      for (const key in item) {
        form.append(`consulting[${index}][${key}]`, item[key]);
      }
    });

    let result = await course_consulting(form,course_id);
    if (result.status) {
    //  setLearningStep(6); // Update the step
    } else {
    //  setLearningStep(6); // Handle failure case
    }
  };

  const onSubmit7 = async () => {
    const form = new FormData();
    const course_id = localStorage.getItem("course_id");
    form.append("course_id", course_id);

    form.append("evaluation[0][subject_id]", selectedSubjects);
    evaluation.forEach((item, index) => {
      for (const key in item) {
        form.append(`evaluation[${index}][${key}]`, item[key]);
      }
    });

    let result = await course_evaluation(form,course_id);
    if (result.status) {
      //localStorage.removeItem("course_id");
     // return navigate("/admin/course-managment/course/all");
    } else {
      // setCurrentStep(currentStep + 1); // Move to the next step
    }
  };
  useEffect(() => {
    console.log("Updated learnigStep:", learnigStep);
    const fetchData = async () => {
      const gradeList = await grade_all();
      const courseType = await get_category();
      const terms = await get_terms();
      const questionList = await get_question();
      const result = await subject_all();

      const results = await unit_all();
      setGradeList(gradeList?.data);
      setCourseType(courseType?.data);
      setTermList(terms?.data);
      setQuestion(questionList?.data);
      setSubject(result?.data);
      setUnits(results?.data);
    };
    fetchData();
  }, [learnigStep]);
  useEffect(() => {
    if(coursesubjectsId?.length > 0){

        const getData = async () => {
          const result2 = await subject_by_coursetype(coursesubjectsId);
          setCoursesubjects(result2?.data);
        };
        getData();
    }
  }, [coursesubjectsId]);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear());
  const TodayDate = maxDate.toISOString().split("T")[0];
  // handle select button

  console.log("selectedSubjects", coursesubjects);

  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <div className="col-md-6">
            <h4 className="modal-title">Add Course</h4>
          </div>
          <div className="col-md-6 text-end">
            <div
              className="modify-right"
              onClick={() => localStorage.removeItem("courseSubjectID")}
            >
              <Link
                className="edit-btn"
                to={`/admin/course-managment/course/all`}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
        <br />

        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              onClick={() => setCurrentStep(1)}
              className={`nav-link ${currentStep === 1 ? "active " : ""}`}
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Basic Info
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              onClick={() => setCurrentStep(2)}
              className={`nav-link ${currentStep === 2 ? "active " : ""}`}
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              Steps of learning
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {currentStep === 1 && (
            <form
              className="form-horizontal"
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Grade</label>
                      <select
                        className="form-select form-control"
                        {...register("grade_id", { required: true })}
                      >
                        <option value="">Choose Grade</option>
                        {gradelist?.length > 0 ? (
                          gradelist.map((elem) => {
                            return (
                              <option key={elem?.id} value={elem?.id}>
                                {elem.name === 0
                                  ? `Year ${elem.name} (kindy)`
                                  : `Year ${elem.name}`}
                              </option>
                            );
                          })
                        ) : (
                          <option>No result</option>
                        )}
                      </select>
                    </div>
                    {errors.grade_id && errors.grade_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Term</label>
                      <select
                        className="form-select form-control"
                        name="term"
                        {...register("term_id", { required: true })}
                      >
                        <option value="">Choose Term </option>
                        {termsList &&
                          termsList?.map((elem) => {
                            return (
                              <option value={elem?.id}>{elem?.name}</option>
                            );
                          })}
                      </select>
                    </div>
                    {errors.term_id && errors.term_id.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Course Type</label>
                      <select
                        className="form-select form-control"
                        {...register("course_type_id", { required: true })}
                        onChange={(e) => setCoursesubjectsId(e.target.value)}
                      >
                        <option value="">Choose Course Type</option>
                        {courseType &&
                          courseType?.map((elem) => {
                            return (
                              <option value={elem?.id}>
                                {elem?.category_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {errors.course_type_id &&
                      errors.course_type_id.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>course Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Course Name"
                        {...register("name", { required: true })}
                      />
                    </div>
                    {errors.name && errors.name.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Unit Price</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Course Price"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("price", { required: true })}
                      />
                    </div>
                    {errors.price && errors.price.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Total Units</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter total units"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("totalUnits", { required: true })}
                      />
                    </div>
                    {errors.totalUnits &&
                      errors.totalUnits.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>course Image</label>
                      <input
                        type="file"
                        className="form-control"
                        placeholder="ENTER file"
                        {...register("image")}
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </div>
                    {/* {errors.image && errors.image.type === "required" && <span className="validation-error">This is required</span>} */}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>status</label>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          {...register("status", { required: true })}
                          id="inlineRadio1"
                          value="1"
                          defaultChecked
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          Active
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          {...register("status", { required: true })}
                          id="inlineRadio2"
                          value="0"
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          Inactive
                        </label>
                      </div>
                    </div>
                    {errors.status && errors.status.type === "required" && (
                      <span className="validation-error">This is required</span>
                    )}
                  </div>
                </div>
              </div>
              {currentStep === 1 && (
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Save & Next
                  </button>
                </div>
              )}
            </form>
          )}
          {currentStep === 2 && (
            <>
              <div
                className="tab-pane fade show active"
                id="pills-home1"
                role="tabpane"
                aria-labelledby="pills-home-tab1"
              >
                <div className="modal-body">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab1"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      {/* <button onClick={() => setLearningStep(1)} className={`nav-link ${learnigStep === 1 ? 'active ' : ''}`} id="pills-assesment-test-tab" data-bs-toggle="pill" data-bs-target="#pills-assesment-test" type="button" role="tab" aria-controls="pills-assesment-test" aria-selected="true">Assessment Test</button> */}
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setLearningStep(0)}
                        className={`nav-link ${
                          learnigStep === 1 ? "active " : ""
                        }`}
                        id="pills-lectures-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-lectures"
                        type="button"
                        role="tab"
                        aria-controls="pills-lectures"
                        aria-selected="false"
                      >
                        Lectures
                      </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                                                <button onClick={() => setLearningStep(1)} className={`nav-link ${learnigStep === 1 ? 'active ' : ''}`} id="pills-lectures-tab" data-bs-toggle="pill" data-bs-target="#pills-lectures" type="button" role="tab" aria-controls="pills-lectures" aria-selected="false">Lectures</button>
                                            </li> */}
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setLearningStep(2)}
                        className={`nav-link ${
                          learnigStep === 2 ? "active " : ""
                        }`}
                        id="pills-self-diverted-study-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-self-diverted-study"
                        type="button"
                        role="tab"
                        aria-controls="pills-self-diverted-study"
                        aria-selected="false"
                      >
                        Self Directed Study
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setLearningStep(3)}
                        className={`nav-link ${
                          learnigStep === 3 ? "active " : ""
                        }`}
                        id="pills-homework-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-homework"
                        type="button"
                        role="tab"
                        aria-controls="pills-homework"
                        aria-selected="false"
                      >
                        Homework / Topic Test
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setLearningStep(4)}
                        className={`nav-link ${
                          learnigStep === 4 ? "active " : ""
                        }`}
                        id="pills-e-tutoring-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-e-tutoring"
                        type="button"
                        role="tab"
                        aria-controls="pills-e-tutoring"
                        aria-selected="false"
                      >
                        E-Tutoring
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setLearningStep(5)}
                        className={`nav-link ${
                          learnigStep === 5 ? "active " : ""
                        }`}
                        id="pills-consulting-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-consulting"
                        type="button"
                        role="tab"
                        aria-controls="pills-consulting"
                        aria-selected="false"
                      >
                        Consulting
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        onClick={() => setLearningStep(6)}
                        className={`nav-link ${
                          learnigStep === 6 ? "active " : ""
                        }`}
                        id="pills-evaluation-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-evaluation"
                        type="button"
                        role="tab"
                        aria-controls="pills-evaluation"
                        aria-selected="false"
                      >
                        Evaluation
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content1" id="pills-tabContent1">
                    {/* {learnigStep === 1 && (
                                                <AddAssessment setInputList={setInputList} inputList={inputList} />
                                            )} */}
                    {learnigStep === 1 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 1 - Lectures</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit2)}
                          encType="multipart/form-data"
                        >
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li key={elem.value} className="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedSubjects === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>
                                            setSelectedSubject(elem?.value)
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Start Date </label>
                              <input
                                type="date"
                                className="form-control"
                                name="start_date"
                                value={date}
                                min={TodayDate}
                                onChange={(e) => setDate(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <AddSubjects
                            setSub={setSub}
                            sub={sub}
                            courseId={23}
                          />
                            <div className="modal-footer">
                          {learnigStep === 1 && (
                              <button type="submit" className="btn btn-primary">
                               Save
                              </button>
                            )}
                            
                          </div>
                          <div className="modal-footer">  
                            {currentStep > 1 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setCurrentStep(currentStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 1 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                                 </button>
                            )}
                            
                          </div>
                          
                        </form>
                      </>
                    )}
                    {/* {learnigStep === 1 && (
                                                <>
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">Step 2 - Lectures</h4>
                                                    </div>
                                                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit2)} encType="multipart/form-data">
                                                        <div className="col-md-6" >
                                                            <div className="form-group">
                                                                <label>Start Date </label>
                                                                <input type="date" className="form-control"
                                                                    name='start_date'
                                                                    value={date}
                                                                    min={TodayDate}
                                                                    onChange={(e) => setDate(e.target.value)} required/>
                                                            </div>
                                                        </div>
                                                        <AddLecture setLecture={setLecture} lecture={lecture} courseId={23} />
                                                        <div className="modal-footer">
                                                            {learnigStep === 1 && (
                                                               <button className="btn btn-secondary" onClick={() => setLearningStep(learnigStep - 1)}>
                                                                Previous
                                                                </button>
                                                                )}
                                                                {learnigStep === 1 && (
                                                                <button type="submit" className="btn btn-primary">
                                                                Save & Next
                                                                </button>
                                                            )}
                                                        </div>
                                                    </form>
                                                </>
                                            )} */}
                    {learnigStep === 2 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Step 2 - Self Diverted Study
                          </h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit3)}
                          encType="multipart/form-data"
                        >
                           <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li key={elem.value} className="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedSubjects === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>
                                            setSelectedSubject(elem?.value)
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          <AddSelfStudy
                            setSelfStudy={setSelfStudy}
                            selfStudy={selfStudy}
                          />
                           <div className="modal-footer">
                          {learnigStep === 2 && (
                              <button type="submit" className="btn btn-primary">
                               Save
                              </button>
                            )}
                            
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 2 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 2 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                                 </button>
                            )}
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 3 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 3 - homework</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit4)}
                          encType="multipart/form-data"
                        >
                           <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li key={elem.value} className="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedSubjects === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>
                                            setSelectedSubject(elem?.value)
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Start Date </label>
                              <input
                                type="date"
                                className="form-control"
                                name="start_date"
                                // value={x.start_date}
                                min={TodayDate}
                                onChange={(e) =>
                                  setHomeWorkDate(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <AddHomeWork
                            setHomeWork={setHomeWork}
                            homeWork={homeWork}
                            setDate={setDate}
                          />
                           <div className="modal-footer">
                          {learnigStep === 3 && (
                              <button type="submit" className="btn btn-primary">
                               Save
                              </button>
                            )}
                            
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 3 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 3 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                                 </button>
                            )}
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 4 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 4 - E-Tutoring</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit5)}
                          encType="multipart/form-data"
                        >
                           <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li key={elem.value} className="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedSubjects === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>
                                            setSelectedSubject(elem?.value)
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          <AddeTutoring
                            setEtutoring={setEtutoring}
                            eTutroing={eTutroing}
                          />
                          <div className="modal-footer">
                          {learnigStep === 4 && (
                              <button type="submit" className="btn btn-primary">
                               Save
                              </button>
                            )}
                            
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 4 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 4 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                                 </button>
                            )}
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 5 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 5 - Consulting</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit6)}
                          encType="multipart/form-data"
                        >
                           <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li key={elem.value} className="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedSubjects === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>
                                            setSelectedSubject(elem?.value)
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          <AddConsultings
                            setConsulting={setConsulting}
                            consulting={consulting}
                          />
                           <div className="modal-footer">
                          {learnigStep === 5 && (
                              <button type="submit" className="btn btn-primary">
                               Save
                              </button>
                            )}
                            
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 5 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                            {learnigStep === 5 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep + 1)}
                              >
                                Next
                                 </button>
                            )}
                          </div>
                        </form>
                      </>
                    )}
                    {learnigStep === 6 && (
                      <>
                        <div className="modal-header">
                          <h4 className="modal-title">Step 6 - Evaluation</h4>
                        </div>
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit7)}
                          encType="multipart/form-data"
                        >
                           <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li key={elem.value} className="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedSubjects === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>
                                            setSelectedSubject(elem?.value)
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          <AddEvaluation
                            setEvaluation={setEvaluation}
                            evaluation={evaluation}
                          />
                           <div className="modal-footer">
                          {learnigStep === 6 && (
                              <button type="submit" className="btn btn-primary">
                               Save
                              </button>
                            )}
                            
                          </div>
                          <div className="modal-footer">
                            {learnigStep === 6 && (
                              <button
                                className="btn btn-secondary"
                                onClick={() => setLearningStep(learnigStep - 1)}
                              >
                                Previous
                              </button>
                            )}
                           {learnigStep === 6 && (
                              <>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    navigate("/admin/course-managment/course/all");
                                    localStorage.removeItem("course_id");
                                  }}
                                >
                                  Submit
                                </button>
                              </>
                            )}
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                  {/* <div className="modal-footer">
                                            {currentStep > 1 && (
                                                <button className="btn btn-secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                                                    Previous
                                                </button>
                                            )}
                                            {currentStep < 4 && (
                                                <button className="btn btn-primary">
                                                    Next
                                                </button>
                                            )}
                                            {currentStep === 4 && (
                                                <button type="submit" className="btn btn-primary">
                                                    Save
                                                </button>
                                            )}
                                        </div> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default AddCourse;
