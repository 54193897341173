import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function checkToken() {
  var now = new Date().getTime();
  var setupTime = JSON.parse(localStorage.getItem('setupTime'));
  if (setupTime != null) {
    console.log("check user is logged in or not...")
    if (now - setupTime > 60 * 60 * 3000) {
      localStorage.clear();
      localStorage.removeItem("userData");
    }
  }
}

function RequireAuthAdmin() {
  checkToken();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let location = useLocation();
  if (!userData) {
    return <Navigate replace to='/admin' state={{ from: location }} />
  }
  // Assuming userData has a 'roles' property to represent user roles
  if (userData && userData?.data?.roles === 'admin' || userData && userData?.data?.roles === 'Admin' ) {
    return <Outlet />;
  }
  return <p>You are not authorized access this view</p>
}

function AdminLogout() {
  localStorage.clear();
  localStorage.removeItem("userData");
  localStorage.removeItem("setupTime");
  return <Navigate replace to='/admin' />
}

function RequireAuthTeacher() {
  checkToken();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let location = useLocation();
  if (!userData) {
    return <Navigate replace to='/teacher' state={{ from: location }} />
  }
  
  // Assuming userData has a 'roles' property to represent user roles
  if (userData && userData?.data?.roles === 'Teacher' || userData && userData?.data?.roles === 'teacher') {
    return <Outlet />;
  }
  return <p>You are not authorized access this view</p>
}

function TeacherLogout() {
  localStorage.clear();
  localStorage.removeItem("userData");
  localStorage.removeItem("setupTime");
  return <Navigate replace to='/teacher' />
}

function RequireAuthBranch() {
  checkToken();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let location = useLocation();
  if (!userData) {
    return <Navigate replace to='/branch' state={{ from: location }} />
  }
  // Assuming userData has a 'roles' property to represent user roles
  if (userData && userData?.data?.roles === 'branch' || userData && userData?.data?.roles === 'Branch') {
    return <Outlet />;
  }
  return <p>You are not authorized access this view</p>
}

function BranchLogout() {
  localStorage.clear();
  localStorage.removeItem("userData");
  localStorage.removeItem("setupTime");
  return <Navigate replace to='/branch' />
}


const ProtectedRoute = ({ children }) => {
  var token = window.localStorage.getItem("userData");
  token = JSON.parse(token);
  const data = token?.data?.roles?.toLowerCase();
  if (!token) {
    if(data=='admin'){
      return <Navigate to="/login" />;
    }else if (data=='teacher'){
      return <Navigate to="/teacher"/>
    }
    else if (data =='branch'){
      return <Navigate to="/branch"/>
    }
   
  }
  else {
    return children;
  }
};


const AfterLogin = ({ children }) => {
  var admin = window.localStorage.getItem("userData");
  admin = JSON.parse(admin);
  const data = admin?.data?.roles
  if (admin) {
    if (data == 'admin') {
      return <Navigate to="/admin/dashboard" />;
    }else if(data=='teacher'){
      return <Navigate to="/teacher/dashboard" />;
    } else if (data =='branch'){
      return <Navigate to="/branch/dashboard" />;
    }
    
  }
  else {
    return children;
  }
};

export function random_generate_string(length) 
{
    // declare all characters
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random()*charactersLength));
    }
    return result;
}

export function data_format(data){
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const date = new Date(data);
  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${day} ${month} ${year} ${hours}:${minutes}`;
}

export { RequireAuthAdmin, AdminLogout, RequireAuthTeacher, TeacherLogout, RequireAuthBranch, BranchLogout, ProtectedRoute, AfterLogin }


