import React, { useState, useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import {  subject_edit, subject_update } from '../../../api/Subject';
import { course_all } from '../../../api/Course/Course';



const EditSubject = () => {
    const [courseList, setCourseList] = useState([]);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const id = useParams();

    useEffect(() => {
        async function fetch_data() {
            const course = await course_all();
            setCourseList(course?.data);
        }
        fetch_data();
    }, []);

    const onSubmit = async (data) => {
        let result = await subject_update(id, data);
        if (result.status) {
            return navigate('/branch/subject/all');
        }
    }
    const fetchData = async () => {
        let res = await subject_edit(id);
        setValue('subject', res?.data[0]?.subject);
        setValue('course_id',res?.data[0]?.course_id);
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modify Subject</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                        <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Course</labell>
                                    <select className='form-select form-control' {...register('class_id')}>
                                        <option value="">CHOOSE COURSE</option>
                                        {
                                            courseList && courseList?.map((elem) => {
                                                return (
                                                    <option value={elem?.id}>{elem?.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Subject</label>
                                    <input type="text" className="form-control"
                                     placeholder='ENTER SUBJECT'
                                        {...register('subject', { required: true })}
                                    />
                                </div>
                                {errors.subject && errors.subject.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default EditSubject ;