import React, { useEffect,useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { subject_add } from '../../../../apis/ClassManagment/Subject';
import { course_all } from '../../../../apis/common';
const AddSubject = () => {
    const [courseList, setCourseList] = useState([]);
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    useEffect(() => {
        async function fetch_data() {
            const course = await course_all();
            setCourseList(course?.data);
        }
        fetch_data();
    }, []);

    const onSubmit = async (data) => {
        let result = await subject_add(data);
        if (result.status) {
            return navigate('/teacher/admin/subject-list');
        }
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Subject</h4>
                </div>
                <div className="modal-body">
                <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Course</label>
                                    <select className='form-select form-control' {...register('course_id')}>
                                        <option value="">Please Select</option>
                                        {
                                            courseList && courseList?.map((elem) => {
                                                return (
                                                    <option value={elem?.id}>{elem?.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Subject</labell>
                                    <input type="text" className="form-control"
                                        {...register('subject', { required: true })}
                                    />
                                </div>
                                {errors.subject && errors.subject.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}



export default  AddSubject;