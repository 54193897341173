import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import { homeWork_all, homeWork_destroy }from '../../../apis/HomeWork'
import CustomTable from '../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Class',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },
    {
        name: 'Description',
        selector: 'description',
        sortable: true,
    },
    {
        name: 'Delete',
        selector: 'action',
        sortable: true,
    }
];

const AllHomeWork = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        async function get_data() {
            const result
                = await homeWork_all();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, class_name, status, description } = elem;
                return {
                    no: `#${index + 1}`,
                    name: class_name ,
                    status: status==0?'Inactive':'Active',
                    description: description,
                    action: <div data-tag="allowRowEvents">
                    <Link className='edit-btn' to={`/teacher/home-work/edit/${id}`}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                    <button className="dlt-btn" onClick={() => destroy(id)}><i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete this subject?");
            if (result) {
                homeWork_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Homeworks</h4>
                </div>
                <div className='col-md-8'>
                    <Link className='ui button float-end' to="/teacher/home-work/add">Add Homework</Link>
                </div>
            </div>
            {
                showResults ?

                    <CustomTable
                        columns={columns}
                        data={data}
                    />
                    :
                    <DataLoader />
            }
        </div>
    )
}

export default AllHomeWork;