import React, { useEffect, useState } from 'react';
import { get_question } from '../../../../apis/testManagement/Question.jsx';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
import {get_text_books} from '../../../../apis/TextBook.jsx';
const AddHomeWork = ({ setHomeWork, homeWork }) => {
    const [units, setUnits] = useState([{ unit_id: 1 }]);
    // const [subjects, setSubject] = useState([]);
    const [question, setQuestion] = useState([]);
    const [textBook, setTextBook] = useState([]);
    const [forms, setForms] = useState([]);

    useEffect(() => {
        const id = localStorage.getItem('totalUnits');
        const initialSubs = Array(parseInt(id)).fill({
          unit_id: '',
          week: '',
          q_id: '',
          u_image: '',
          u_audio: '',
          description: '',
          u_document: '',
          link: '',
          text_book_id: ''
        });
        setForms(initialSubs.map((form, index) => ({ ...form, unit_id: index + 1 })));
   // localStorage.removeItem('totalUnits');
  }, []);
  useEffect(() => {
    const fetchData = async () => {
        const questionList = await get_question();
        // const result = await subject_all();
        const textBookList=await get_text_books();
        setQuestion(questionList?.data);
        // setSubject(result?.data);
        setTextBook(textBookList?.data);
    };
    fetchData();
}, []);

    //homeWork handle change
    // const handlehomeWorkInputChange = (e, index) => {
    //     const { name, value } = e.target;
    //     if(name=='subject_id'){
    //         localStorage.setItem('courseSubjectID',value)
    //     }
    //     const list = [...homeWork];
    //     list[index][name] = value;
    //     setHomeWork(list);
    // };

    const handlehomeWorkInputChange = (e, i) => {
        const { name, value } = e.target;
        const updatedForms = [...forms];
        updatedForms[i][name] = value;
        setForms(updatedForms);
        setHomeWork(updatedForms);
      };
    //homeWork handle change for image
    // const handlehomeWorkImgChange = (e, index) => {
    //     const { name, files } = e.target;
    //     const list = [...homeWork];
    //     list[index][name] = files[0];
    //     setHomeWork(list);
    // }

    const handlehomeWorkImgChange = (e, index) => {
        const { name, files } = e.target;
        const updatedForms = [...forms];
        updatedForms[index][name] = files[0];
        setForms(updatedForms);
        setHomeWork(updatedForms);
      };

    //homeWork handle click remove
    // const handlehomeWorkRemoveClick = (index) => {
    //     const list = [...homeWork];
    //     const remove = list.filter((_, indexFilter) => !(indexFilter === index));
    //     setHomeWork(remove);
    // }
    //homeWork handle click add button
    // const handlehomeWorkAddClick = () => {
    //     setHomeWork([...homeWork, {
    //         unit: '',
    //         week: '',
    //         q_id: '',
    //         u_image: '',
    //         subject_id: '',
    //         u_audio: '',
    //         description: '',
    //         u_document: '',
    //         link: '',
    //         text_book_id:''
    //     }])
    // }
   
    return (
        <div className="accordion" id="accordionExample">
            {
                forms?.map((x, i) =>  (
                    <div key={i} className="accordion-item">
                    <h2 className="accordion-header" id={`heading-${i}`}>
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${i}`}
                        aria-expanded={i === 0 ? true : false}
                        aria-controls={`collapse-${i}`}
                      >
                        Unit {i + 1}
                      </button>
                    </h2>
                    <div
            id={`collapse-${i}`}
            className={`accordion-collapse collapse ${i === 0 ? 'show' : ''}`} 
            aria-labelledby={`heading-${i}`}
            data-bs-parent="#accordionExample"
          >
                                <div className="modal-body">
                                    <div className="row">
                                    <input type="hidden" name="unit_id" value={x.unit_id} />
                                        <div className="col-md-6" key={i}>
                                            <div className="form-group">
                                                <label>Upload Document {i + 1}</label>
                                                <input type="file" className="form-control"
                                                    name='u_document'
                                                    onChange={(e) => handlehomeWorkImgChange(e, i)}  accept=".pdf" />

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Embedded Link</label>
                                                <input type="url" className="form-control"
                                                    placeholder='Enter Link'
                                                    value={x.link}
                                                    name='link'
                                                    onChange={(e) => handlehomeWorkInputChange(e, i)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Upload Image</label>
                                                <input type="file" className="form-control"
                                                    name='u_image'
                                                    onChange={(e) => handlehomeWorkImgChange(e, i)} />

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Upload Audio</label>
                                                <input type="file" className="form-control"
                                                    name='u_audio'
                                                    onChange={(e) => handlehomeWorkImgChange(e, i)}  accept='audio/*'/>

                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Unit</label>
                                                <input type="text" className="form-control"
                                                    placeholder='ENTER UNIT'
                                                    value={x.unit}
                                                    name='unit'
                                                    onChange={(e) => handlehomeWorkInputChange(e, i)}
                                                />
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Week</label>
                                                <input type="text" className="form-control"
                                                    placeholder='Enter Week'
                                                    value={x.week}
                                                    name='week'
                                                    onChange={(e) => handlehomeWorkInputChange(e, i)}
                                                />
                                            </div>
                                        </div> */}
                                        <div className="col-md-6" >
                                            <div className="form-group">
                                                <label>Questionaire</label>
                                                <select className="form-control form-select"
                                                    value={x.q_id}
                                                    name='q_id'
                                                    onChange={(e) => handlehomeWorkInputChange(e, i)} >
                                                    <option value="">Choose Question</option>
                                                    {
                                                        question && question?.map((elem) => {
                                                            return (
                                                                <option value={elem?.id}>{elem?.question_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <select className="form-control form-select"
                                                    name='subject_id'
                                                    value={x.subject_id}
                                                    onChange={(e) => handlehomeWorkInputChange(e, i)}>
                                                    <option value="">Choose Subject</option>
                                                    {
                                                        subjects && subjects?.map((elem) => {
                                                            return <option value={elem?.id}>{elem?.subject}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Text Book</label>
                                                <select className="form-control form-select"
                                                    name='text_book_id'
                                                    value={x.text_book_id}
                                                    onChange={(e) => handlehomeWorkInputChange(e, i)}>
                                                    <option value="">Choose text book</option>
                                                    {
                                                        textBook && textBook?.map((elem) => {
                                                            return <option value={elem?.id}>{elem?.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <textarea type="text" className="form-control"
                                                    placeholder='Enter Description'
                                                    value={x.description}
                                                    name='description'
                                                    onChange={(e) => handlehomeWorkInputChange(e, i)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                {/* <div className="col-md-2">
                                    <div className='add_btn'>
                                        {
                                            homeWork.length !== 1 && (
                                                <button className='btn-warning' onClick={() => handlehomeWorkRemoveClick(i)}>Remove</button>
                                            )
                                        }
                                        {console.log('i', i)}
                                        {
                                            homeWork.length - 1 === i && (
                                                <button className='btn-success' onClick={() => handlehomeWorkAddClick()}>Add</button>
                                            )
                                        }
                                    </div>
                                </div> */}
                                <hr />
                            </div>
                    ))}
                    </div>
                );
            };
export default AddHomeWork;






