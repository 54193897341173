import React, { useEffect, useState } from 'react'
import { get_question } from '../../../../apis/testManagement/Question.jsx';
// import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
const AddSelfStudy = ({ setSelfStudy, selfStudy }) => {
    const [units, setUnits] = useState([{ unit_id: 1 }]);
    // const [subjects, setSubject] = useState([]);
    const [question, setQuestion] = useState([]);
    const [forms, setForms] = useState([]);


    useEffect(() => {
        const id = localStorage.getItem('totalUnits');
        const initialSubs = Array(parseInt(id)).fill({
            unit_id: '',
            q_id: '',
            // subject_id: '',
            u_document: '',
            link: '',
            u_image: '',
            u_audio: ''
        });
        setForms(initialSubs.map((form, index) => ({ ...form, unit_id: index + 1 })));
       // localStorage.removeItem('totalUnits');
      }, []);
    useEffect(() => {
        const fetchData = async () => {
            const questionList = await get_question();
            // const result = await subject_all();
            setQuestion(questionList?.data);
            // setSubject(result?.data);
        };
        fetchData();
    }, []);
    //SelfStudy handle change
    // const handleSelfStudyInputChange = (e, index) => {
    //     const { name, value } = e.target;
    //     if (name == 'subject_id') {
    //         localStorage.setItem('courseSubjectID', value)
    //     }
    //     const list = [...selfStudy];
    //     list[index][name] = value;
    //     setSelfStudy(list);
    // };

    const handleSelfStudyInputChange = (e, i) => {
        const { name, value } = e.target;
        const updatedForms = [...forms];
        updatedForms[i][name] = value;
        setForms(updatedForms);
        setSelfStudy(updatedForms);
      };

    //SelfStudy handle change for image
    // const handleSelfStudyImgChange = (e, index) => {
    //     const { name, files } = e.target;
    //     const list = [...selfStudy];
    //     list[index][name] = files[0];
    //     setSelfStudy(list);
    // }
    const handleSelfStudyImgChange = (e, index) => {
        const { name, files } = e.target;
        const updatedForms = [...forms];
        updatedForms[index][name] = files[0];
        setForms(updatedForms);
        setSelfStudy(updatedForms);
      };


    //SelfStudy handle click remove
    // const handleSelfStudyRemoveClick = (index) => {
    //     const list = [...selfStudy];
    //     const remove = list.filter((_, indexFilter) => !(indexFilter === index));
    //     setSelfStudy(remove);
    // }
    //SelfStudy handle click add button
    // const handleselfStudyAddClick = () => {
    //     setSelfStudy([...selfStudy, {
    //         q_id: '',
    //         subject_id: '',
    //         u_document: '',
    //         link: '',
    //         u_image: '',
    //         u_audio: ''
    //     }])
    // }
    return (
        <div className="accordion" id="accordionExample">
            {
                forms?.map((x, i) => (
                    <div key={i} className="accordion-item">
                    <h2 className="accordion-header" id={`heading-${i}`}>
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${i}`}
                        aria-expanded={i === 0 ? true : false}
                        aria-controls={`collapse-${i}`}
                      >
                        Unit {i + 1}
                      </button>
                    </h2>
                    <div
            id={`collapse-${i}`}
            className={`accordion-collapse collapse ${i === 0 ? 'show' : ''}`} 
            aria-labelledby={`heading-${i}`}
            data-bs-parent="#accordionExample"
          >
                            <div className="modal-body">
                                <div className="row">
                            
                            <input type="hidden" name="unit_id" value={x.unit_id} />
                                    <div className="col-md-6" key={i}>
                                        <div className="form-group">
                                            <label>Questionaire {i + 1}</label>
                                            <select className="form-control form-select"
                                                name='q_id' value={x.q_id} onChange={(e) => handleSelfStudyInputChange(e, i)}>
                                                <option value="">Choose Question</option>
                                                {
                                                    question && question?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.question_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Subject</label>
                                            <select className="form-control form-select"
                                                name='subject_id' value={x.subject_id} onChange={(e) => handleSelfStudyInputChange(e, i)}>
                                                <option value="">Choose Subject</option>
                                                {
                                                    subjects && subjects?.map((elem) => {
                                                        return <option value={elem?.id}>{elem?.subject}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Document</label>
                                            <input type="file" className="form-control"
                                                name='u_document' onChange={(e) => handleSelfStudyImgChange(e, i)}
                                                accept=".pdf"  />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Embedded Link</label>
                                            <input type="url" className="form-control"
                                                placeholder='Enter Link'
                                                value={x.link}
                                                name='link'
                                                onChange={(e) => handleSelfStudyInputChange(e, i)} />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Image</label>
                                            <input type="file" className="form-control"
                                                name='u_image'
                                                onChange={(e) => handleSelfStudyImgChange(e, i)} />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Audio</label>
                                            <input type="file" className="form-control"
                                                name='u_audio'
                                                onChange={(e) => handleSelfStudyImgChange(e, i)}
                                                accept='audio/*'  />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            {/* <div className="col-md-2">
                                <div className='add_btn'>
                                    {
                                        selfStudy.length !== 1 && (
                                            <button className='btn-warning' onClick={() => handleSelfStudyRemoveClick(i)}>Remove</button>
                                        )
                                    }
                                    {
                                        selfStudy.length - 1 === i && (
                                            <button className='btn-success' onClick={() => handleselfStudyAddClick()}>Add</button>
                                        )
                                    }
                                </div>
                            </div> */}
                            <hr />
                        </div>
                  ))}
                </div>
              );
          };

export default AddSelfStudy