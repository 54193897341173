import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RequireAuthBranch, BranchLogout, ProtectedRoute, AfterLogin } from '../../Utils/Utils';
import {
    BranchLogin, Dashboard, BranchLayout, AddBrance, AllBrances,
    EditBrance, AddCourse, AllCourses, EditCourse, AddSubject, AllSubjects, EditSubject
    , AddClassList, AllClassList, EditClassList, ClassView, Forgotpassword, ResetPassword, Websit_env_view,EditMember,
     QuestionList,Student_enroll_list,Student_enroll_view,Free_student_list,AddTeacher,AllTeachers,EditTeacher
,Attendance,AttendanceView} from '../components/pages/Index';
import DetailsCourse from '../components/pages/course/Details';
function BranchRoute() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/branch" element={<AfterLogin><BranchLogin /></AfterLogin>} />
                <Route path="/branches/forgot-password" element={<AfterLogin><Forgotpassword /></AfterLogin>} />
                <Route path="/branches/reset-password/:token" element={<AfterLogin><ResetPassword /></AfterLogin>} />
                <Route element={<RequireAuthBranch />}>
                    <Route path="/branch" element={<ProtectedRoute><BranchLayout /></ProtectedRoute>} >
                        <Route path="dashboard" element={<Dashboard />} />
                        {/* branch */}
                        <Route path="/branch/add" element={<AddBrance />} />
                        <Route path="/branch/all" element={<AllBrances />} />
                        <Route path="/branch/edit" element={<EditBrance />} />
                        {/* course */}
                        <Route path="/branch/course/add" element={<AddCourse />} />
                        <Route path="/branch/course/all" element={<AllCourses />} />
                        <Route path="/branch/course/edit/:id" element={<EditCourse />} />
                        <Route path="/branch/course/details/:id" element={<DetailsCourse />} />
                        {/* subject */}
                        <Route path="/branch/subject/add" element={<AddSubject />} />
                        <Route path="/branch/subject/all" element={<AllSubjects />} />
                        <Route path="/branch/subject/edit/:id" element={<EditSubject />} />

                        {/* class List */}
                        <Route path="/branch/classList/add" element={<AddClassList />} />
                        <Route path="/branch/classList/all" element={<AllClassList />} />
                        <Route path="/branch/classList/edit/:id" element={<EditClassList />} />

                        {/* classView */}
                        <Route path="/branch/classList/classView/:id" element={<ClassView />} />
                        <Route path="/branch/question-list" element={<QuestionList />} />
                        <Route path="websiteEnv/all" element={<Websit_env_view />} />
                        {/* student enroll list */}
                        <Route path="/branch/student_enroll-list" element={<Student_enroll_list />} />
                        <Route path="/branch/free_student_list" element={<Free_student_list />} />
                        <Route path="/branch/member-edit/:id" element={<EditMember />} />
                        <Route path="/branch/student_enroll-view/:id" element={<Student_enroll_view />} />
                         {/* teacher  */}
                         <Route path="/branch/teacher/add" element={<AddTeacher />} />
                         <Route path="/branch/teacher/all" element={<AllTeachers />} />
                        <Route path="/branch/teacher/edit/:id" element={<EditTeacher />} />
                         {/* Attendance  */}
                         <Route path="/branch/student-attendance"  element={<Attendance />}/>
                         <Route path="/branch/student-attendance/view/:id"  element={<AttendanceView />}/>
                    </Route>
                    <Route path="/logout" element={<BranchLogout />} />
                </Route>

            </Routes>
        </BrowserRouter>
    )
}

export default BranchRoute;
