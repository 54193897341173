import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import { get_item, item_destroy } from '../../../../apis/testManagement/Items'
import CustomTable from '../../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Item Name',
        selector: 'item_name',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];

const Allitems = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        async function get_data() {
            const result = await get_item();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, item_name } = elem;
                return {
                    no: `#${index + 1}`,
                    item_name: item_name,
                    action: <div data-tag="allowRowEvents">
                        <Link className='edit-btn' to={`/admin/test-management/edit/${id}`}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                        <button className="dlt-btn" onClick={() => destroy(id)}>   <i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete this item?");
            if (result) {
                item_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Items List</h4>
                </div>
                <div className='col-md-8'>
                    <Link className='ui button float-end' to="/admin/test-management/add">Add Item</Link>
                </div>
            </div>
            {
                showResults ?
                    <CustomTable
                        columns={columns}
                        data={data}
                    />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default Allitems;


