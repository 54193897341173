import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import { get_Category } from '../../../../../src/apis/Catergory';
import { get_SubCategory, SubCategory_destroy, get_SubCategory_by_id } from '../../../../apis/Catergory'
import EditSubCategory from './Edit'
import CustomTable from '../../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Category',
        selector: 'category',
        sortable: true,
    },
    {
        name: 'Sub Category',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },

    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];





const AllSubCategory = () => {
    const [grade, setGrade] = useState([]);
    const [allcategory, setallcategory] = useState([]);
    console.log('allcategory',allcategory)
    const [showResults, setShowResults] = useState(false);
    const [status, setStatus] = useState("")
    const [inputValue, setInputValue] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [duplicateItemId, setDuplicateItemId] = useState(null);
    const handleChange = async(event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        console.log('selectedOption',selectedOption.text)
        setInputValue(event.target.value);
        const selectedValue = event.target.value
        if(!selectedValue){
           return get_data()
        }
        const result = await get_SubCategory_by_id(selectedValue);
        const arrayData = result?.data?.map((elem, index) => {
            const { id, name, status } = elem;

            return {
                no: `#${index + 1}`,
                name: name,
                status: status == 0 ? 'Inactive' : 'Active',
                category:selectedOption.text,
                action:<div data-tag="allowRowEvents">
                <button onClick={() => {
                    setDuplicateItemId(id); setIsModalOpen(true);
                    setStatus('grade')
                }}
                    className="dlt-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <i class="fa fa-pencil" aria-hidden="true"></i></button>
                    <button className="dlt-btn" onClick={() => destroy(id)}>     <i class="fa fa-trash" aria-hidden="true"></i></button></div>
            };
        });
        setGrade(arrayData, setShowResults(true));
    };

    async function get_cat(){
        const allData = await get_Category();
        const finalReuslt = allData.data
        setallcategory(finalReuslt, setShowResults(true));
    }
    async function get_data() {
        const result = await get_SubCategory();
        const arrayData = result?.data?.map((elem, index) => {
            const { id, name, status,category } = elem;

            return {
                no: `#${index + 1}`,
                name: name,
                status: status == 0 ? 'Inactive' : 'Active',
                category:category,
                action:<div data-tag="allowRowEvents">
                <button onClick={() => {
                    setDuplicateItemId(id); setIsModalOpen(true);
                    setStatus('grade')
                }}
                    className="dlt-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <i class="fa fa-pencil" aria-hidden="true"></i></button>
                    <button className="dlt-btn" onClick={() => destroy(id)}>     <i class="fa fa-trash" aria-hidden="true"></i></button></div>
            };
        });
        setGrade(arrayData, setShowResults(true));
    }
    const destroy = (id) => {
        const result = window.confirm("Are you sure you want to delete this Sub Category ?");
        if (result) {
            SubCategory_destroy(id);
           return get_data();
        }
       
    }
    useEffect(() => {
        get_data();
        get_cat();
    }, []);
    return (
        <>
                <div className="modal-content">
      
      <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div className="right-nav" id="dash-event">
                  <div className='row'>
                      <div className='col-md-4'>
                          <h4 className='modal-title'>Sub Categroy List</h4>
                      </div>
                      <div className='col-md-8'>
                          <Link className='ui button float-end' to="/admin/general-setting/sub-category/add">Add Sub Category
                          </Link>
                      </div>
                  </div>
                  <div className='row'>
                    
                  </div>
                  <div className='row'>
                  <div className="col-md-12">
                        <div className="form-group">
                            <label>Category Filter</label>
                            <select className="form-select form-control" name="category"  value={inputValue}  onChange={handleChange}>
                                <option value="">Choose Category</option>
                                {
                                    allcategory?.length > 0 ?
                                    allcategory && allcategory?.map((elem) => {
                                            return (
                                                <option value={elem?.id}>{elem?.name}</option>
                                            )
                                        })
                                        :
                                        <option>No result</option>
                                }
                            </select>
                        </div>
                    </div>
                    </div>
                  {
                      showResults ?
                          <CustomTable
                              columns={columns}
                              data={grade}
                          />
                          :
                          <DataLoader />
                  }
                   
              </div>
          </div>
      </div>
  </div>
  <EditSubCategory duplicateItemId={duplicateItemId} isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen} status={status} setStatus={setStatus} />
        </>

       

    )
}
export default AllSubCategory;



