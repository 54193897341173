import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import CustomTable from '../CustomTable';
import {cousultancy_list} from '../../../apis/studentManagement/StudentManagement'

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'First Name',
        selector: 'first_name',
        sortable: true,
    },
    {
        name: 'Last Name',
        selector: 'last_name',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
    }
];

const Consultancy = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    
    useEffect(() => {
        async function get_data() {
            const result = await cousultancy_list();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, first_name, last_name, subject } = elem;

                return {
                    no: index + 1,
                    first_name: first_name,
                    last_name: last_name,
                    subject: subject,
                };
            });
            setData(arrayData, setShowResults(true));
        }
        get_data();
    }, []);
    return (
        <>
            <div className="right-nav" id="dash-event">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Consultancy List</h4>
                    </div>
                </div>
                {
                    showResults ?
                        <CustomTable
                            columns={columns}
                            data={data}
                        />
                        :
                        <DataLoader />
                }
            </div>
        </>
    )
}
export default Consultancy;


