import React, { useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
const UploadFile = () => {
    const inputFile = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {

        // let result = await grade_add(data);
        // if (result.status) {
        //     // return navigate('/admin/general-setting/cse-brances');
        // }
        reset()
    }

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Upload Image</h4>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal"
                        onSubmit={handleSubmit(onSubmit)} >
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Files Uploads</label>
                                    <input className="form-control" type='file' multiple ref={inputFile}
                                        name='image'  {...register('image')} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type='submit' className="btn btn-primary">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UploadFile