import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

export async function events_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/event-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all events
export async function events_all() {
    try {
        const response = await fetch(HTTPURL + 'api/event-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// events edit 
export async function events_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/event-edit?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// events update 
export async function events_update(id, data)
{
    try {
        const response = await fetch(HTTPURL + `api/event-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// events delete
export async function events_destroy(id) 
{
    try {
        const response = await fetch(HTTPURL + `api/event-destroy?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
