import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
import { grade_all } from '../../../../apis/Grade.jsx'
import { course_all } from '../../../../apis/Course.jsx'
import { add_testType } from '../../../../apis/testManagement/TestType.jsx'
import { get_category } from '../../../../apis/category.jsx';
import { get_terms } from '../../../../apis/Common.jsx';
import { get_item } from '../../../../apis/testManagement/Items.jsx'
import { useEffect } from 'react';
import { useState } from 'react';


const AddTestType = () => {
    const [subjects, setSubject] = useState([]);
    const [grades, setGrades] = useState([]);
    const [course, setCourse] = useState([]);
    const [termsList, setTermList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [item, setItem] = useState([]);


    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await add_testType(data);
        if (result.status) {
            return navigate('/admin/test-management/test-type');
        }
    }

    useEffect(() => {
        async function get_data() {
            const grade1 = await grade_all();
            const subject1 = await subject_all();
            const course = await course_all();
            const terms = await get_terms();
            const category = await get_category();
            const item = await get_item();
            setCourse(course?.data);
            setSubject(subject1?.data);
            setGrades(grade1?.data);
            setTermList(terms?.data);
            setCategoryList(category?.data);
            setItem(item?.data);
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Test Type </h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Category</label>
                                    <select className="form-select form-control" name="kind" {...register("kind")}>
                                        <option value="">Choose Category</option>
                                        {
                                            categoryList && categoryList?.map((elem) => {
                                                return (
                                                    <option value={elem?.id}>{elem?.category_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Test Type</label>
                                    <select className="form-select form-control" {...register("offine_test_type")}>
                                        <option value="">Choose Test Type</option>
                                        {
                                            item && item.map((elem) => {
                                                return (
                                                    <option value={elem?.id}>{elem?.item_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>course</label>
                                    <select className=" form-select form-control" {...register("offine_course")}>
                                        <option value="">Choose Course</option>
                                        {
                                            course && course?.map((elem) => {
                                                return <option value={elem?.id}>{elem?.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Grade</label>
                                    <select className="form-select form-control" name="grade" {...register("grade")}>
                                        <option value="">Choose Grade </option>
                                        {
                                            grades && grades?.map((elem) => {
                                                return <option name="grade" value={elem?.id}>{elem?.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Year</label>
                                    <select className="form-select form-control" name="year" {...register("year")}>
                                        <option value="">Choose Year</option>
                                        <option value="1">2020</option>
                                        <option value="2">2021</option>
                                        <option value="3">2022</option>
                                        <option value="4">2023</option>
                                        <option value="5">2024</option>
                                        <option value="6">2025</option>
                                        <option value="7">2026</option>
                                        <option value="8">2027</option>
                                        <option value="9">2028</option>
                                        <option value="10">2029</option>
                                        <option value="11">2030</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Term</label>
                                    <select className="form-select form-control" name="term" {...register("term")}>
                                        <option value="">Choose Term </option>
                                        {
                                            termsList && termsList?.map((elem) => {
                                                return (
                                                    <option value={elem?.id}>{elem?.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Subject</label>
                                    <select className="form-select form-control" name="subject" {...register("subject")}>
                                        <option value="">Choose Subject</option>
                                        {
                                            subjects && subjects?.map((elem) => {
                                                return <option value={elem?.id}>{elem?.subject}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Test Kind</label>
                                    <div className='highlight_inner'>
                                        <div className=" checkbox">
                                            <label> <input type="radio" value="Objective"
                                                {...register('test_kind')}
                                            /> Objective </label>
                                        </div>
                                        <div className=" checkbox">
                                            <label> <input type="radio" value="Subjective"
                                                {...register('test_kind')}
                                            /> Subjective </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <hr className='mt-3' />
                                <div className="col-md-12">
                                    <label><b>test type</b></label>
                                </div>
                                <div className="form-group">
                                    <label>test Title</label>
                                    <input type="text" className="form-control" name="test_title"
                                        placeholder='Enter Test Title'
                                        {...register('test_title')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>test Period</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Test Period'
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} name="test_period"  {...register('test_period')} /> days
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>test Price</label>
                                    <input type="text"
                                        placeholder='Enter Test Price'
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        className="form-control" name="test_price"  {...register('test_price')} /> $
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>test number</label>
                                    <input type="text"
                                        placeholder='Enter Test Number'
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        className="form-control" name="test_number"  {...register('test_number')} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddTestType;
