import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

// get all items
export async function get_Category() {
    try {
        const response = await fetch(HTTPURL + 'api/new-category-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}



// add item
export async function add_Category(data) {
    try {
        const response = await fetch(HTTPURL + 'api/new-category-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// item delete
export async function Category_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/new-category-delete?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item edit 
export async function Category_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/new-category-edit?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item update 
export async function Category_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/new-category-update/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}











export async function get_SubCategory_by_id(id) {
    try {
        const response = await fetch(HTTPURL + `api/sub-category-all-data?category_id=${id}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}



// get all items
export async function get_SubCategory() {
    try {
        const response = await fetch(HTTPURL + 'api/sub-category-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}



// add item
export async function add_SubCategory(data) {
    try {
        const response = await fetch(HTTPURL + 'api/sub-category-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// item delete
export async function SubCategory_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/sub-category-delete?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item edit 
export async function SubCategory_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/sub-category-edit?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item update 
export async function SubCategory_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/sub-category-update/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
