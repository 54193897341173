import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import { get_days, day_destroy } from '../../../apis/Common'
import CustomTable from '../CustomTable';
import EditDays from './Edit'


export const columns1 = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Day',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },

    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];



const AllDays = () => {
    const [showResults, setShowResults] = useState(false);
    const [day, setDay] = useState([]);
    const [status, setStatus] = useState("")

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [duplicateItemId, setDuplicateItemId] = useState(null);



    useEffect(() => {

        async function get_data() {
            const result = await get_days();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, name, status } = elem;

                return {
                    no: `#${index + 1}`,
                    name:name,
                    status: status == '0' ? 'Inactive' : 'active',
                    action: <div data-tag="allowRowEvents">
                        <button onClick={() => {
                            setDuplicateItemId(id); setIsModalOpen(true); setStatus('day')
                        }}
                            className="dlt-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <i class="fa fa-pencil" aria-hidden="true"></i></button><button className="dlt-btn" onClick={() => destroy(id)}>     <i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                };
            });
            setDay(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete this day ?");
            if (result) {
                day_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);


    return (
        <div className="modal-content">
            <div className="modal-body">


                <div className="right-nav" id="dash-event">
                    <div className='row'>
                        <div className='col-md-4'>
                            <h4 className='modal-title'>Days List</h4>
                        </div>
                        <div className='col-md-8'>
                            <Link className='ui button float-end' to="/admin/general-setting/day/add">Add Day
                            </Link>
                        </div>
                    </div>
                    {
                        showResults ?
                            <CustomTable
                                columns={columns1}
                                data={day}
                            />
                            :
                            <DataLoader />
                    }


                </div>
            </div>
            <EditDays duplicateItemId={duplicateItemId} isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen} status={status} setStatus={setStatus} />
        </div>

    )
}
export default AllDays;



