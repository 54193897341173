import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader.jsx';
import {free_student_list} from '../../../api/Student_Enroll.jsx';
import CustomTable from '../../pages/CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'first_name',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];
const Free_student_list = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    useEffect(() => 
    {
        async function get_data() {
          const branch_id=localStorage.getItem('b_id')
            const result = await free_student_list(branch_id);
            const arrayData = result?.data?.map((elem, index) => {
              const {id,first_name,subject,grade,user_id} = elem;
              return {
                no: `#${index + 1}`,
                first_name:first_name,
                grade:grade,
                subject: subject?subject:'-',
                action: <div data-tag="allowRowEvents"><Link className='edit-btn' to={`/branch/student_enroll-view/${id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link><Link className='edit-btn' to={`/branch/member-edit/${user_id}`}><i class="fa fa-pencil" aria-hidden="true"></i></Link></div>
                
              };
            });
            setData(arrayData, setShowResults(true));
          }
        //   const destroy = (id) => {
        //     const result = window.confirm("Are you sure you want to delete this subject?");
        //     if (result) {
        //       subject_destroy(id);
        //     }
        //     get_data();
        //   }
          get_data();         
    }, []);

    return (
      <div className="right-nav" id="dash-event">
        <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Free Student List</h4>
                </div>
                {/* <div className='col-md-8'>
                    <Link className='ui button float-end' to="/branch/subject/add">Add Subject</Link>
                </div> */}
            </div>
      {
          showResults ?
          
          <CustomTable
              columns={columns}
              data={data}
          />
        : 
        <DataLoader />
      }
    </div>
    )
}

export default Free_student_list;