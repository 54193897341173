import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { get_item } from '../../../../apis/testManagement/Items'
import { add_comment } from '../../../../apis/testManagement/writingComment'
import { useEffect } from 'react';
import { useState } from 'react';


const AddWritingComment = () => {
    const [item, setItem] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await add_comment(data);
        if (result.status) {
            return navigate('/admin/test-management/writing-comments');
        }
    }

    useEffect(() => {
        async function get_data() {
            const result = await get_item();
            setItem(result?.data);
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Comment</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group ">
                                    <label>An item</label>
                                    <select className="form-select form-control" name="an_item" {...register("an_item")}>
                                        <option value="">Please Choose Item</option>
                                        {item?.map((ele) => {
                                            return (<>
                                                <option value={ele.id}>{ele.item_name}</option>
                                            </>
                                            )
                                        })}

                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Score</label>
                                    <input type="text" className="form-control" name="score"  {...register('score')} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Comment Description</label>
                                    <textarea className="form-control" name="comment_description" rows="3" {...register('comment_description')}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddWritingComment;
