import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import CustomTable from '../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
    },
    {
        name: 'Year',
        selector: 'year',
        sortable: true,
    },
    {
        name: 'Term',
        selector: 'term',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Page',
        selector: 'page',
        sortable: true,
    },
    {
        name: 'Modify',
        selector: 'modify',
        sortable: true,
    },
    {
        name: 'Delete',
        selector: 'action',
        sortable: true,
    },
    {
        name: 'Play',
        selector: 'play',
        sortable: true,
    }
];

const SttStudent = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        async function get_data() {
            const result = await `class_details_all()`;
            const arrayData = result?.data?.map((elem, index) => {
                const { _id, class_id, book_page } = elem;

                return {
                    no: index + 1,
                    subject: class_id?.subject_id?.subject,
                    year: class_id?.year,
                    term: class_id?.term,
                    grade: class_id?.grade,
                    name: class_id?.movie_title,
                    page: book_page,
                    modify: <div className='edit-btn'><Link className='dlt-btn' to={`/admin/class-detail/edit/${class_id?._id}`}>Modify</Link></div>,
                    action: <button className="dlt-btn" onClick={() => destroy(_id)}> Delete</button>,
                    play: '- - - -'
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete this class details?");
            if (result) {
                // class_detail_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='col-4-lg'>
                <Link className='ui button' to="/admin/class-detail/add">Test Students List</Link>
            </div>
            {
                showResults ?
                        <CustomTable
                            columns={columns}
                            data={data}
                        />
                    :
                    <DataLoader />
            }
        </div>
    )
}

export default SttStudent;
