import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { class_list_all } from '../../../../apis/ClassManagement/ClassList';
import { class_details_add } from '../../../../apis/ClassManagement/ClassDetails';

const AddClassDeatils = () => {
    const [rowToDisPlay, setDowToDisPlay] = useState(0);
    const [className, setClassName] = useState([]);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            list: [{ movie_name: "", file_path: "", movie_time: '', book_page: '', sort: '', media_id: '', maths_es: '', maths_en: '', maths_l2: '', maths_l3: '' }]
        }
    });

    const {
        fields,
    } = useFieldArray({
        control,
        name: "list"
    });

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await class_details_add(data);
        if (result.status) {
            return navigate('/admin/class-details');
        }
    }

    useEffect(() => {
        async function get_data() {
            const result = await class_list_all();
            setClassName(result?.data);
        }
        get_data();
    }, []);

    return (
        <div className="modal-content">
            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className="col-md-3 col-sm-12">
                        <div className='form-group'>
                            <label>Select a class:</label>
                            <select className='mt-2 mb-4 form-select form-control' {...register("class_id")}>
                                <option value="">Choose Class</option>
                                {
                                    className && className?.map((elem) => {
                                        return <option value={elem?.id}>{elem?.c_name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className='form-group'>
                            <label>File:</label>
                            <input type="file" className='form-control' {...register("file_path")}/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddClassDeatils;
