import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";


// get all users
// export async function usersAll(id) {
//     try {
//         const response = await fetch(HTTPURL + `api/get-all-users?id=${id}`);
//         const responseData = await response.json();
//         if(responseData.status)
//         {
//             // toast.success(responseData?.message);
//         } else {
//             toast.error(responseData?.message);
//         }
//         return responseData;
//     } catch (error) {
//         console.log(error.message);
//         return error.message;
//     }
// }


export async function usersAll(data,branch_id) {
    console.log('data',branch_id);
 const useData={
        data,branch_id  
    }
    try {
        const response = await fetch(HTTPURL + 'api/get-all-users', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(useData)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

