import { toast } from "react-toastify";
import {HTTPURL} from '../../constants/Matcher';


// get branch student
export async function get_branch_dashboard() {
    try {
        const response = await fetch(HTTPURL + `api/branches/get-dashboard-data/?b_id=${localStorage.getItem('b_id')}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}