import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { add_naplanCriteria } from '../../../../apis/testManagement/NaplanCriteria'
import { useEffect } from 'react';
import { useState } from 'react';


const AddNaplanCriteria = () => {
    const [item, setItem] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await add_naplanCriteria(data);
        if (result.status) {
            return navigate('/admin/test-management/naplanCriteria');
        }
    }

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Naplan(Criteria)</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Task</label>
                                    <input type="text" className="form-control" name="task"  {...register('task')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Criteria</label>
                                    <input type="text" className="form-control" name="criteria"  {...register('criteria')} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Score</label>
                                    <input type="Number" className="form-control" name="score"  {...register('score')} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control" name="description" rows="3" {...register('description')}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddNaplanCriteria;
