// Data.js
export const columns = [
    {
      name: 'No.',
      selector: 'no',
      sortable: true,
    },
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
    },
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
    },
    // {
    //   name: 'Title',
    //   selector: 'title',
    //   sortable: true,
    // },
    {
        name: 'Venue',
        selector: 'venue',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    },
  ];

    export const data = [
    {
        no: 1,
        type: 'Seminar',
        date: '11/05/2023',
        title: 'OC Application for 2024 & Parent Interview (Castle Hill)',
        venue: 'CS Education Castle Hill',
        bookingOffline: '8/22',
        bookingOnline:'-',
        action:'delete'
    },
    {
        no: 2,
        type: 'Seminar',
        date: '11/05/2023',
        title: 'OC Application for 2024 & Parent Interview (Castle Hill)',
        venue: 'CS Education Castle Hill',
        bookingOffline: '8/22',
        bookingOnline:'-',
        action:'delete'
    },
    {
        no: 3,
        type: 'Seminar',
        date: '11/05/2023',
        title: 'OC Application for 2024 & Parent Interview (Castle Hill)',
        venue: 'CS Education Castle Hill',
        bookingOffline: '8/22',
        bookingOnline:'-',
        action:'delete'
    },{
        no: 4,
        type: 'Seminar',
        date: '11/05/2023',
        title: 'OC Application for 2024 & Parent Interview (Castle Hill)',
        venue: 'CS Education Castle Hill',
        bookingOffline: '8/22',
        bookingOnline:'-',
        action:'delete'
    },{
        no: 5,
        type: 'Seminar',
        date: '11/05/2023',
        title: 'OC Application for 2024 & Parent Interview (Castle Hill)',
        venue: 'CS Education Castle Hill',
        bookingOffline: '8/22',
        bookingOnline:'-',
        action:'delete'
    },
    ];