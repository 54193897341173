import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
    // accordion-header active 
    const [tabActive, setTabActive] = useState({
        events: false,
        marketSupporting: false,
        studentManagement: false,
        homeWork: false,
        teacherManage: false,
    });

    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath.includes('marketing-support')) {
            setTabActive(({ marketSupporting: true }));
        }
        if (currentPath.includes('events')) {
            setTabActive(({ events: true }));
        }
        if (currentPath.includes('home-work')) {
            setTabActive(({ homeWork: true }));
        }
        if (currentPath.includes('teachers-management') || currentPath.includes('category')) {
            setTabActive(({ teacherManage: true }));
        }
    }, [location]);

    return (
        <div id="layoutSidenav_nav">
            <div className="left-main-heading">
                <Link to="/branch/dashboard"><i className="fa-solid fa-table-cells-large"></i> Dashboard</Link>
            </div>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <div className="accordion-body">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link to="/branch/course/all" className="nav-link">Courses</Link>
                        </nav>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-body">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link to="/branch/edit" className="nav-link">Branch Setting</Link>
                        </nav>
                    </div>
                </div>
                {/* <div className="accordion-item">
                    <div className="accordion-body">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link to="/branch/subject/all" className="nav-link">Subject</Link>
                        </nav>
                    </div>
                </div> */}
                <div className="accordion-item">
                    <div className="accordion-body">
                        <nav className="sb-sidenav-menu-nested nav">
                            {/* <Link to="/branch/classList/all" className="nav-link">Class List</Link> */}
                        </nav>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-body">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link to="/branch/question-list" className="nav-link">Questionnaire List</Link>
                        </nav>
                    </div>
                </div>
                {/* <div className="accordion-item">
                    <div className="accordion-body">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link to="/branch/websiteEnv/all" className="nav-link">Website Env</Link>
                        </nav>
                    </div>
                </div> */}
                <div className="accordion-item">
                    <div className="accordion-body">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link to="/branch/student_enroll-list" className="nav-link">Enroll Student</Link>
                        </nav>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-body">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link to="/branch/free_student_list" className="nav-link">Free Student</Link>
                        </nav> 
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-body">
                        <nav className="sb-sidenav-menu-nested nav">
                        <Link to="/branch/teacher/all" className="nav-link">Teachers</Link>
                        </nav> 
                    </div>
                </div>
                <div className="accordion-item">
                    <div className="accordion-body">
                        <nav className="sb-sidenav-menu-nested nav">
                        <Link to="/branch/student-attendance" className="nav-link">Attendance</Link>
                        </nav> 
                    </div>
                </div>
                <div className="sb-sidenav-footer"  >
                    <div className="small">Logged in as:</div>
                    Branch
                </div>
            </div>
        </div>
    )
};
export default Sidebar;