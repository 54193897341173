import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import DataLoader from '../../../../Utils/DataLoader';
import { get_testDetail, testDetail_destroy, get_data_test_type } from '../../../../apis/testManagement/TestDetail';
import { class_list_all } from '../../../../apis/ClassManagement/ClassList';
import { get_testType, duplicate_add_testType } from '../../../../apis/testManagement/TestType';
import CustomTable from '../../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Test Kind',
        selector: 'test_kind',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
    },
    {
        name: 'Test Detail Title',
        selector: 'test_detail_title',
        sortable: true,
    },
    {
        name: 'Duplicate',
        selector: 'duplicate',
        sortable: true,
    },
    {
        name: 'Delete',
        selector: 'action',
        sortable: true,
    }
];

const AllTestDetail = () => {
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [duplicateItemId, setDuplicateItemId] = useState(null);
    const [classList, setClassList] = useState([]);
    const [testTypeList, setTestTypeList] = useState([]);
    const [testtypeId, setTestType] = useState('');
    const [classId ,setClassId] = useState('');

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    // get data type
    const test_type_on_change = async(e) => 
    {
        const value = e.target.value;
        console.log('value',value);
        setTestType(value);
        const res = await get_data_test_type(value);
        console.log('res',res.data);
        setValue('subject', res?.data?.subject);
        setValue('year', res?.data?.year);
        setValue('term', res?.data?.term);
        setValue('grade', res?.data?.grade);
        setValue('test_title', res?.data?.test_title);
        setValue('test_number', res?.data?.test_number);
    }

    const onSubmit = async (data) => {
        let result = await duplicate_add_testType(data);
        if (result.status) {
            // Close the modal when the result is successful
            setIsModalOpen(false);
        }
    }
    
    useEffect(() => {
        async function get_data() {
            const classlist = await class_list_all();
            const testtypelist = await get_testType();
            setClassList(classlist?.data);
            setTestTypeList(testtypelist?.data);
            const result = await get_testDetail();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, test_type, test_kind, subject, test_detail_title, test_no, test_total_number, test_time, sort, tstm_guid, sample_movie_name } = elem;

                return {
                    no: index + 1,
                    test_kind: test_kind,
                    subject: subject,
                    test_detail_title: test_detail_title,
                    duplicate: <button onClick={ () =>{
                        setDuplicateItemId(id); setIsModalOpen(true);
                    }} 
                        className="dlt-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> Duplicate Questionaire</button>,
                    action:<div data-tag="allowRowEvents">
                    <Link className='edit-btn' to={`/admin/test-management/testDetail-edit/${id}`}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                    <button className="dlt-btn" onClick={() => destroy(id)}> <i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>,
                };   
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete this test details ?");
            if (result) {
                testDetail_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <>
            <div className="right-nav" id="dash-event">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Test Detail List</h4>
                    </div>
                    <div className='col-md-8'>
                        <Link className='ui button float-end' to="/admin/test-management/testDetail-add">Add Test (Detail)</Link>
                    </div>
                </div>
                {
                    showResults ?
                        <CustomTable
                            columns={columns}
                            data={data}
                        />
                        :
                        <DataLoader />
                }
            </div>
            <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden={isModalOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="mx-auto modal-title mt-2" id="staticBackdropLabel">Select a Source Detail and a Destination Type</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <label>Test Class:</label>
                                        <input type="hidden" value={duplicateItemId} />
                                        <select className='form-select form-control' value={classId} {...register("class_id")} onChange={(e) => setClassId(e.target.value)}>
                                            <option value=''>Choose Class</option>
                                            {
                                                classList && classList?.map((elem) => {
                                                    return (
                                                        <option value={elem?.id}>{elem?.c_name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                        <label>Test Type:</label>
                                        <select className='form-select form-control' value={testtypeId} {...register("test_type")} onChange={(e) => test_type_on_change(e)}>
                                            <option value=''>Choose Test Type</option>
                                            {
                                                testTypeList && testTypeList?.map((elem) => {
                                                    return (
                                                        <option value={elem?.id}>{elem?.test_title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <label>Detail :	</label>
                                        <div className='row'>
                                            <div class="col-md-6">
                                                <label>Subject </label>
                                                <input className='form-control' {...register("subject")} readOnly />
                                            </div>
                                            <div class="col-md-6">
                                                <label>Grade </label>
                                                <input className='form-control' {...register("grade")}  />
                                            </div>
                                            <div class="col-md-6">
                                                <label>Year </label>
                                                <input className='form-control' {...register("year")}  />
                                            </div>
                                            <div class="col-md-6">
                                                <label>Term </label>
                                                <input className='form-control' {...register("term")}  />
                                            </div>
                                            <div class="col-md-6">
                                                <label>Test no </label>
                                                <input className='form-control' {...register("test_number")} />
                                            </div>  <div class="col-md-6">
                                                <label>Title </label>
                                                <input className='form-control' {...register("test_title")}  />
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="d-print-none"><button type="submit" className='ui button'>Duplicate it!</button></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AllTestDetail;


