import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { subject_all } from '../../../../apis/ClassManagment/Subject';
import { class_list_add } from '../../../../apis/ClassManagment/ClassList';
import { grade_all } from '../../../../apis/common';
import { get_category } from '../../../../apis/common';
import { get_terms } from '../../../../apis/common';
// import { google_class_add } from '../../../../apis/ClassManagement/GoogleClass.jsx';
import AddGoogleClass from '../AddGoogleClass';
// import AddClassDeatils from '../classDetails/Add.jsx';

import { useState, useEffect } from 'react';

const Addclass = () => {
    const [subjects, setSubject] = useState([]);
    const [gradelist, setGradeList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [termsList, setTermsList] = useState([]);
    const [file, setFile] = useState(null);

    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        data.file_path = file;

        const form = new FormData();
        form.append("file_path", file);
        form.append("class_info", getValues('class_info'));
        form.append("grade", getValues('grade'));
        form.append("kind", getValues('kind'));
        form.append("movie_period", getValues('movie_period'));
        form.append("movie_price", getValues('movie_price'));
        form.append("movie_title", getValues('movie_title'));
        form.append("sort", '1');
        form.append("subject_id", getValues('subject_id'));
        form.append("term", getValues('term'));
        form.append("year", getValues('year'));
        
        let result = await class_list_add(form);
        if (result.status) {
            return navigate('/teacher/class-list');
        }
    }

    useEffect(() => {
        async function get_data() {
            const result = await subject_all();
            const categorylist = await get_category();
            const gradeList = await grade_all();
            const termslist = await get_terms();

            setGradeList(gradeList?.data);
            setCategoryList(categorylist?.data);
            setSubject(result?.data);
            setTermsList(termslist?.data)
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Classes</h4>
                </div>
                <div className="modal-body">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Add Class</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Online Google Class</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Categories</label>
                                            <select className="form-select form-control" name="event_type" {...register("kind")}>
                                                <option value="">Please Choose</option>
                                                {
                                                    categoryList && categoryList?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.category_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Grade</label>
                                            <select className="form-control" name="grade" {...register("grade")}>
                                                <option value="">Please choose grade</option>
                                                {
                                                    gradelist?.length > 0 ?
                                                        gradelist && gradelist?.map((elem) => {
                                                            return (
                                                                <option value={elem?.id}>{elem?.name}</option>
                                                            )
                                                        })
                                                        :
                                                        <option>No result</option>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Year</label>
                                            <select className="form-control" name="event_type" {...register("year")}>
                                                <option value="">Please choose year</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Term</label>
                                            <select className="form-control" name="term" {...register("term")}>
                                                <option value="">Please choose term</option>
                                                {
                                                    termsList && termsList?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Subject</label>
                                            <select className="form-control" {...register("subject_id")}>
                                                <option value="">Please choose subject</option>
                                                {
                                                    subjects && subjects?.map((elem) => {
                                                        return <option value={elem?.id}>{elem?.subject}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Sort</label>
                                            <select className="form-control" name="sort" {...register('sort')}>
                                                <option value="1">1. Main (Default)</option>
                                                <option value="2">2</option>
                                                <option value="3">3. OC</option>
                                                <option value="4">4. WEMG</option>
                                                <option value="5">5. Selective</option>
                                                <option value="6">6. CS Class Material</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12. HSC</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <hr className='mt-3' />
                                        <div className="col-md-12">
                                            <label><b>Type</b></label>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label>Title</label>
                                                    <input type="text" className="form-control" name="movie_title"
                                                        {...register('movie_title')}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label>File:</label>
                                                        <input 
                                                        type="file" className='form-control' 
                                                        {...register("file_path")} 
                                                        onChange={(e) => setFile(e.target.files[0])}
                                                        />
                                                    </div>
                                            </div>
                                        </div>
                                       
                                        
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Period</label>
                                            <input type="text" className="form-control" name="movie_period"  {...register('movie_period')} /> days
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Price</label>
                                            <input type="number" className="form-control" name="movie_price"  {...register('movie_price')} /> $
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Class Info.</label>
                                            <textarea className="form-control" name="class_info" rows="3" {...register('class_info')}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <Button type='submit' className="btn btn-primary">Save</Button>
                                </div>
                            </Form>
                        </div>
                        {/* <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <AddClassDeatils />
                        </div> */}

                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <AddGoogleClass />
                        </div>
                    </div>
                </div>
                {/* <div className="modal-body">
                    
                </div> */}
            </div>
        </div>
    )
}

export default Addclass;
