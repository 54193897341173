import React, { useEffect, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from 'react-router-dom';
import {events_edit, events_update } from '../../../apis/Events';

const EditEvent = () => {
  const [show,setShow]=useState(false)

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const id = useParams();
    const showField = (e) => {
        const selectedValue = e.target.value;
        selectedValue == 'interview' ? setShow(true) : setShow(false)
        setValue("event_type", selectedValue, { shouldValidate: true });
    }
    const fetchData = async () => 
    {
        let res = await events_edit(id);
        setValue('event_type',res?.data[0]?.event_type);
        setValue('title',res?.data[0]?.title);
        setValue('date',res?.data[0]?.date);
        setValue('time_start',res?.data[0]?.time_start);
        setValue('time_end',res?.data[0]?.time_end);
        setValue('interview_time_limit',res?.data[0]?.interview_time_limit);
        setValue('venue_name',res?.data[0]?.venue_name);
        setValue('offine_address',res?.data[0]?.offine_address);
        setValue('online_event',res?.data[0]?.online_event=='false'?'':res?.data[0]?.online_event);
        setValue('offline_event',res?.data[0]?.offline_event=='false'?'':res?.data[0]?.offline_event);
        setValue('offine_seats',res?.data[0]?.offine_seats);
        setValue('online_seats',res?.data[0]?.online_seats);
        setValue('description', res?.data[0]?.description);
        setValue('online_url',res?.data[0]?.online_url);
        setValue('booking_date_start',res?.data[0]?.booking_date_start);
        setValue('booking_date_end',res?.data[0]?.booking_date_end);
        setValue('break_start',res?.data[0]?.break_start);
        setValue('break_end',res?.data[0]?.break_end);
    }

    useEffect(() => {fetchData();},[]);

    const onSubmit = async (data) => {
        let result = await events_update(id, data);
        if(result.status) 
        {
            return navigate('/admin/administrator-setting/events');
        }
    }
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1);
    const TodayDate = maxDate.toISOString().split('T')[0];
    return (
        <div className="modal-content">
            <div className="modal-header">
                <h4 className="modal-title">Modify Event</h4>
                <Link className='edit-btn' to={`/admin/administrator-setting/events`}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
            </div>
            <div className="modal-body">
                <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                    <div className="col-md-6">
                        <small>Event Type</small>
                        <select className="form-control" name="event_type" {...register("event_type", { required: true })} onChange={(e)=>showField(e)}>
                            <option value="">Event</option>
                            <option value="seminar">Seminar</option>
                            <option value="interview">Interview</option>
                        </select>
                        {errors.event_type && errors.event_type.type === "required" && <span className="validation-error">This is required</span>}

                    </div>

                    <div className="col-md-6">
                        <small>Title</small>
                        <input type="text" className="form-control" name="title"
                            placeholder='Enter Title'
                        {...register('title', { required: true })}
                        />
                        {errors.title && errors.title.type === "required" && <span className="validation-error">This is required</span>}

                    </div>
                    <div className="col-md-6">
                        <small>Date</small>
                        <input type="date" className="form-control" name="date" 
                        {...register('date', { required: true })} min={TodayDate}
                        />
                        {errors.date && errors.date.type === "required" && <span className="validation-error">This is required</span>}
                    </div>
                    <div className="col-md-6">
                        <small>Time Start</small>
                        <input type="time" className="form-control" name="time_start"
                        {...register('time_start', { required: true })}
                        />
                        {errors.time_start && errors.time_start.type === "required" && <span className="validation-error">This is required</span>}
                    </div>
                    <div className="col-md-6">
                        <small>Time End</small>
                        <input type="time" className="form-control" name="time_end" 
                        {...register('time_end', { required: true })}
                        />
                        {errors.time_end && errors.time_end.type === "required" && <span className="validation-error">This is required</span>}
                    </div>
                    {
                        show?
                    <div className="col-md-6">
                        <small>Interview Time Limit</small>
                        <select className="form-control" name="interview_time_limit" {...register('interview_time_limit')}>
                        <option value="">N/A</option>
                            <option value="15">15 minutes</option>
                            <option value="20">20 minutes</option>
                        </select>
                    </div>
                        :''
                    }
                    </div>
                    <div className="row">
                
                    <div className="col-12">
                    <hr className='mt-3'/>
                        <small>Venue Name</small>
                        <input type="text" className="form-control" name="venue_name" 
                         placeholder='Enter Venue Name'
                        {...register('venue_name', { required: true })}
                        />
                        {errors.venue_name && errors.venue_name.type === "required" && <span className="validation-error">This is required</span>}
                    </div>

                    <div className="col-md-12">
                        
                        <div className="highlight_event">
                        <small>Offline/Online Event</small>
                     <div className='highlight_inner'>
                        <div className=" checkbox">
                            <label> <input type="checkbox" id="event_offline" value="offine"
                            {...register('offline_event')}
                            /> Offline </label>
                        </div>
                        <div className=" checkbox ms-4">
                            <label> <input type="checkbox" id="event_online" value="online" 
                             {...register('online_event')}
                            /> Online </label>
                        </div>
                        </div>
                        </div>
                    </div>
                    
                    <div className="col-md-9">
                        <small>(Offline) Address</small>
                        <input type="text" className="form-control" name="offine_address" 
                         placeholder='Enter Offline Address'
                        {...register('offine_address')}
                        />
                    </div>
                    <div className="col-md-3">
                        <small>(Offline) Seats</small>
                        <input type="number"
                        placeholder='Enter Offline Seats'
                        className="form-control" name="offine_seats" {...register('offine_seats')} />
                    </div>
                    <div className="col-md-9">
                        <small>(Online) URL</small>
                        <input type="text"
                         placeholder='Enter Url'
                        className="form-control"
                         name="online_url"  {...register('online_url')} />
                    </div>
                    <div className="col-md-3">
                        <small>(Online) Seats</small>
                        <input type="number"
                        placeholder='Enter Online Seats'
                        className="form-control" name="online_seats"  {...register('online_seats')} />
                    </div>
                    <div className="col-md-12">
                        <small>Description</small>
                        <textarea className="form-control"
                         placeholder='Enter Description'
                        rows="3" {...register('description')}></textarea>
                    </div>
                    
                   
                    <div className="col-md-12">
                    <hr className='mt-3'/>
                        <small><b>Booking Date</b></small>
                    </div>
                    <div className="col-md-6">
                        <small>Start</small>
                        <input type="date" className="form-control" name="booking_date_start" 
                        {...register('booking_date_start')} min={TodayDate}
                        />
                    </div>
                    <div className="col-md-6">
                        <small>End</small>
                        <input type="date" className="form-control" name="booking_date_end" 
                        {...register('booking_date_end')} min={TodayDate}
                        />
                    </div>
                    <br />
                    <div className="col-md-12">
                        <small><b>Break (if applicable)</b></small>
                    </div>
                    <div className="col-md-6">
                        <small>Start</small>
                        <input type="time" className="form-control" name="break_start" 
                        {...register('break_start')}
                        />
                    </div>
                    <div className="col-md-6">
                        <small>End</small>
                        <input type="time" className="form-control" name="break_end" 
                        {...register('break_end')}
                        />
                    </div>
                    </div>
                    <div className="modal-footer">
                        <button type='submit' className="btn btn-primary">Save Event</button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default EditEvent;
