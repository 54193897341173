import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import CustomTable from '../CustomTable';
import { EnrollMembers_all } from '../../../apis/studentManagement/StudentManagement';

export const columns = [
    // {
    //     name: 'No.',
    //     selector: 'no',
    //     sortable: true,
    // },
    {
        name: 'Student Id',
        selector: 'id',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Branch',
        selector: 'branch',
        sortable: true,
    },
    {
        name: 'Student Type',
        selector: 'student_type',
        sortable: true,
    },
    {
        name: 'Contact',
        selector: 'contact',
        sortable: true,
    },
    {
        name: 'Sign In Date',
        selector: 'created_at',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];

const EnrolledMembers = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        async function get_data() {
            const result = await EnrollMembers_all();
            const arrayData = result?.data?.map((elem, index) => {
                const { id,s_id, first_name, last_name, created_at, branch_name, name, student_number, school,student_type } = elem;
                const date = new Date(created_at);

                const month = (date?.getMonth() + 1).toString().padStart(2, '0'); 
                const day = date?.getDate().toString().padStart(2, '0');
                const year = date?.getFullYear();
                const formattedDate = `${day}-${month}-${year}`;
                return {
                    no: `#${index + 1}`,
                    id:`CSPRO00${id}`,
                    name: first_name?.toLowerCase() + ' ' + last_name?.toLowerCase(),
                    grade: name,
                    branch: branch_name,
                    school: school,
                    contact: student_number || '-',
                    created_at: formattedDate?.slice(0,10),
                    student_type:student_type=='1'?'Enrolled':'free',
                    action: <>
                   {<Link className='edit-btn' to={`/admin/students/student_enroll_view/${s_id}`}>View</Link>}</>,
                };
            });
            setData(arrayData, setShowResults(true));
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Enrolled Student List</h4>
                </div>
            </div>
            {
                showResults ?
                   
                        <CustomTable
                        columns={columns}
                        data={data}
                        />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default EnrolledMembers;
