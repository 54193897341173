import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import CustomTable from '../CustomTable';
import { teacher_all} from '../../../api/Teacher';
export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Branch',
        selector: 'branch',
        sortable: true,
    },
    {
        name: 'TeacherId',
        selector: 'teacher_id',
        sortable: true,
    },
    {
        name: 'Teacher Name',
        selector: 'teacher_name',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    },  
];
const Attendance = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    useEffect(() => {
        async function get_data() {
            const result = await teacher_all();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, branch_name, teacher_id, teacher_name,status,user_id } = elem;
                return {
                    no: `#${index + 1}`,
                    branch: branch_name?.toLowerCase(),
                    teacher_id: teacher_id,
                    teacher_name: teacher_name.toLowerCase(),
                    status: status == 0 ? 'Inactive' : 'Active',
                    action: <div data-tag="allowRowEvents">
                    <Link className='edit-btn' to={`/branch/student-attendance/view/${user_id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link>
                    </div>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Attendance</h4>
                </div>
            </div>
            {
                showResults ?
                        <CustomTable
                            columns={columns}
                            data={data}
                        />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default Attendance;
