import { toast } from "react-toastify";
import { HTTPURL } from '../../constants/Matcher';
// get all website env data
export async function get_website_data() {
    try {
        const response = await fetch(HTTPURL + 'api/get-website-data');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}