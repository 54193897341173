import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";

// get all items
export async function get_testType() {
    try {
        const response = await fetch(HTTPURL + 'api/test-types-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

// add item
export async function add_testType(data) {
    try {
        const response = await fetch(HTTPURL + 'api/test-type-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),sort:'1'
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item delete
export async function testType_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/test-type-destroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item edit 
export async function testType_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/test-type-edit?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item update 
export async function testType_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/test-type-update?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// add duplicate data in test type
export async function duplicate_add_testType(data) {
    try {
        const response = await fetch(HTTPURL + 'api/duplicate-test-type', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
