import { toast } from "react-toastify";
import { HTTPURL } from '../../constants/Matcher';


// get all days
export async function get_days() {
    try {
        const response = await fetch(HTTPURL + 'api/days-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}


// get all grades
export async function grade_all() {
    try {
        const response = await fetch(HTTPURL + 'api/grades-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get all branches
export async function branch_all() {
    try {
        const response = await fetch(HTTPURL + 'api/branches-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all term
export async function get_terms() {
    try {
        const response = await fetch(HTTPURL + 'api/terms-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_category() {
    try {
        const response = await fetch(HTTPURL + 'api/categories-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

// get insert id
export async function insertId() {
    try {
        const response = await fetch(HTTPURL + `api/get-last-insert-id`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}