import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import { category_destroy, get_category } from '../../../apis/category'
import CustomTable from '../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Course Type',
        selector: 'category_name',
        sortable: true,
    },

    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];

const AllCategory = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {

        async function get_data() {
            const result = await get_category();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, category_name, price } = elem;
                // general-setting/category/edit/
                return {
                    no: `#${index + 1}`,
                    category_name: category_name,
                    action: <div data-tag="allowRowEvents">
                         <Link class="edit-btn" to={`/admin/general-setting/category/edit/${id}`}>
              <i class="fa fa-pencil" aria-hidden="true"></i></Link>
                        <button className="dlt-btn" onClick={() => destroy(id)}> <i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete this category ?");
            if (result) {
                category_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Course Type List</h4>
                </div>
                <div className='col-md-8'>
                    <Link className='ui button float-end' to="/admin/general-setting/category/add">Add Course Type
                    </Link>
                </div>
            </div>
            {
                showResults ?
                    <CustomTable
                        columns={columns}
                        data={data}
                    />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default AllCategory;


