import { toast } from "react-toastify";
import { HTTPURL } from "../../../constants/Matcher";

// add branch
export async function course_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/course-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// get all branches
export async function course_all() {
    try {
        const response = await fetch(HTTPURL + 'api/branches/courses-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch edit 
export async function course_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/course-edit?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch details 
export async function course_details(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/course-edits?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch update 
export async function course_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/branches/course-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch delete
export async function course_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/course-destroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
