import { toast } from "react-toastify";
import { HTTPURL } from '../../constants/Matcher';





// subject add 
export async function homeWork_add(data) {
    var requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
    };
    try {
        const response = await fetch(HTTPURL + 'api/teacher/add-home-work', requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all work 
export async function homeWork_all() {
    try {
        const response = await fetch(HTTPURL + 'api/teacher/all-home-works');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// subject edit 
export async function homeWork_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/teacher/edit-home-work/?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// subject update 
export async function homeWork_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/teacher/update-home-work/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// subject delete
export async function homeWork_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/teacher/destroy-home-work?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


