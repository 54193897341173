import React, { useEffect, useState } from 'react';
import DataLoader from '../../../../Utils/DataLoader.jsx';
import { data_format } from '../../../../Utils/Utils.js';
import CustomTable from '../../pages/CustomTable';
import { get_question } from '../../../../apis/testManagement/Question.jsx';
import QuestionAdd from './QuestionAdd.jsx';
export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },  
    {
        name: 'Time',
        selector: 'time',
        sortable: true,
    },
    {
        name: 'Schedule Time',
        selector: 'schedule',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];
const QuestionList = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [duplicateItemId, setDuplicateItemId] = useState(null);
    const [status, setStatus] = useState("")
    const userId = JSON.parse(localStorage.getItem('userData'));
    useEffect(() => {
        async function get_data() {
            const result = await get_question();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, question_name, test_type, time, branch_add_time_question } = elem;
                const questionTime = JSON.parse(branch_add_time_question);
                let isAlreadyAdded = false;
                let startTime = "";
                let endTime = "";
                questionTime && questionTime.forEach((timeElem) => {
                    if (timeElem.question_id === elem.id && timeElem.user_id === userId.data?.id) {
                        isAlreadyAdded = true;
                        startTime = timeElem.start_time;
                        endTime = timeElem.end_time;
                    }
                });
                return {
                    no: `#${index + 1}`,
                    name: question_name,
                    test_type,
                    time:`${time} minute`,
                    schedule:<div style={{width:'75%',textTransform:'lowercase',whiteSpace:"pre-wrap"}}>{isAlreadyAdded ? `${data_format(startTime)}  ${data_format(endTime)}` : '-'}</div>,
                    action: <div data-tag="allowRowEvents">
                        <div className='class-btn'>
                            <button
                                onClick={() => {
                                    setDuplicateItemId(id);
                                    setIsModalOpen(true);
                                }}
                                className="dlt-btn"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                            >
                                {isAlreadyAdded ? "Edit Schedule" : "Add Schedule"}
                            </button>
                        </div>
                    </div>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        get_data();
    }, []);
    return (
        <>
            <div className="right-nav" id="dash-event">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Question Add Time</h4>
                    </div>
                </div>
                {
                    showResults ?
                        <CustomTable
                            columns={columns}
                            data={data}
                        />
                        :
                        <DataLoader />
                }
            </div>
            <QuestionAdd question_id={duplicateItemId} isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen} status={status} setStatus={setStatus} />
        </>
    )
}
export default QuestionList;
