import React from 'react';
export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
    },
    {
        name: 'Year',
        selector: 'year',
        sortable: true,
    },
    {
        name: 'Term',
        selector: 'term',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Page',
        selector: 'page',
        sortable: true,
    },
    {
        name: 'Modify',
        selector: 'modify',
        sortable: true,
    },
    {
        name: 'Delete',
        selector: 'action',
        sortable: true,
    },
    {
        name: 'Play',
        selector: 'play',
        sortable: true,
    }
];

const EnvironmentVariable = () => {

    return (
        <div className="right-nav" id="dash-event">
            <div className='col-4-lg'>
                <div className='ui button'>Environment Variable</div>
            </div>
            <div className="modal-body">
                <p>
                    <a className="ui button" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Smtp Details</a>

                    <button className="ui button" type="button" data-bs-toggle="collapse" data-bs-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2">Admin Setting</button>

                </p>
                <div className="row">
                    <div className="col">
                        <div className="collapse multi-collapse" id="multiCollapseExample1">
                            <div className="card card-body">
                                Some placeholder content for the first collapse component of this multi-collapse example. This panel is hidden by default but revealed when the user activates the relevant trigger.
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="collapse multi-collapse" id="multiCollapseExample2">
                            <div className="card card-body">
                                <p>Email: "admin@gmail.com"</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EnvironmentVariable;


