import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { get_question_detail } from '../../../../apis/testManagement/Question';
import { HTTPURL } from '../../../../constants/Matcher';
const AllQuestionaireDetail = () => {
    const [questionnaireDatail, setQuestionnaire] = useState([]);
    console.log('grade_id', questionnaireDatail?.grade_id);
    const [questionData, setQuestionData] = useState([]);
    const id = useParams();
    useEffect(() => {
        async function get_data() {
            const data = await get_question_detail(id);
            setQuestionnaire(data?.data[0]);
            if (data?.data[0]?.question_data) {
                setQuestionData(JSON.parse(data?.data[0]?.question_data))
            }
        }
        get_data();
    }, []);
    const date = new Date(questionnaireDatail?.created_at);
    const month = (date?.getMonth() + 1).toString().padStart(2, '0');
    const day = date?.getDate().toString().padStart(2, '0');
    const year = date?.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    console.log('formattedDate',formattedDate);
    return (
        <div>
            <div className="right-nav" id="dash-event">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Questionnaire Detail</h4>
                        {/* <Link className='edit-btn' to={`/admin/students/member-edit/${id?.id}`}>Modify</Link> */}
                    <Link className='edit-btn' to={`/admin/test-management/questionaire-all`}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                    </div>
                    <div className="modal-body">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="form-horizontal">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Grade</label>
                                                <p>{questionnaireDatail?.grade_id ? `grade ${questionnaireDatail?.grade_id}` : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Term</label>
                                                <p>{questionnaireDatail?.term_id ? `${questionnaireDatail?.term_id}` : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Course Type</label>
                                                <p>{questionnaireDatail?.category_name ? ` ${questionnaireDatail?.category_name}` : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <p>{questionnaireDatail?.subject ? questionnaireDatail?.subject : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Time</label>
                                                <p>{questionnaireDatail?.time ? questionnaireDatail?.time : '-'} mins</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Date</label>
                                                <p>{formattedDate ? formattedDate : '-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                {questionData && questionData?.map((elem, i) => {
                    return (
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div className="form-horizontal">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Question Type</label>
                                                        <p>{elem?.test_type ? elem?.test_type : '-'}</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                            <div className="form-group">
                                                                <label>Image</label>
                                                                <img src={`${HTTPURL}uploads/${elem?.image}`} height={100} width={100} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Passege</label>
                                                                <p>{elem?.passage ? elem?.passage : '-'}</p>
                                                            </div>
                                                        </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Question ({i + 1})</label>
                                                        <p>{elem?.question ? elem?.question : '-'}</p>
                                                    </div>
                                                </div>
                                                {elem?.test_type == 'objective' ?
                                                    <div className='row'>
                                                        <div className='col-md-2'>
                                                            <div className="form-group">
                                                                <label>option 1</label>
                                                                <p>{elem?.option1 ? elem?.option1 : '-'}</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <div className="form-group">
                                                                <label>option 2</label>
                                                                <p>{elem?.option2 ? elem?.option2 : '-'}</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <div className="form-group">
                                                                <label>option 3</label>
                                                                <p>{elem?.option3 ? elem?.option3 : '-'}</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <div className="form-group">
                                                                <label>option 4</label>
                                                                <p>{elem?.option4 ? elem?.option4 : '-'}</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <div className="form-group">
                                                                <label>option 5</label>
                                                                <p>{elem?.option5 ? elem?.option5 : '-'}</p>
                                                            </div>
                                                        </div>
                                                    </div> : ''}
                                                    <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Answer</label>
                                                        <p>{elem?.answer ? elem?.answer : '-'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default AllQuestionaireDetail;
