import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
    // accordion-header active 
    const [tabActive, setTabActive] = useState({
        events: false,
        marketSupporting: false,
        studentManagement: false,
        homeWork: false,
        Marking: false,
    });

    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath.includes('marketing-support')) {
            setTabActive(({ marketSupporting: true }));
        }
        if (currentPath.includes('events')) {
            setTabActive(({ events: true }));
        }
        if (currentPath.includes('homw-work')) {
            setTabActive(({ homeWork: true }));
        }
    }, [location]);

    return (
        <div id="layoutSidenav_nav">
            <div className="left-main-heading">
                <Link to="/teacher/dashboard"><i className="fa-solid fa-table-cells-large"></i> Dashboard</Link>
            </div>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.marketSupporting ? 'active' : ''}`}>
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <i className="fa-solid fa-clipboard-user"></i> Subject Management
                        </button>
                    </h2>
                    <div id="collapseOne" className={`accordion-collapse collapse ${tabActive?.marketSupporting ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <Link to="/teacher/subject-list" className="nav-link">Subjects</Link>
                                {/* <Link to="/teacher/class-list" className="nav-link">Class List</Link> */}
                                {/* <Link to="/teacher/class-details" className="nav-link">Class Details</Link>
                                <Link to="/teacher/class-list/google-class-list" className="nav-link">Online (Google) Class</Link> */}
                                {/* <Link to="/class-details" className="nav-link">Class Details</Link>
                                <Link to="/class-list/google-class-list" className="nav-link">Online (Google) Class</Link> */}
                            </nav>
                        </div>
                    </div>
                </div>
                    <div className="accordion-item">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <Link to="/teacher/student-attendance" className="nav-link homework"> <i class="fa-regular fa-calendar-check"></i>Attendance</Link>
                            </nav>
                        </div>
                    </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.events ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                            <i className="fa-solid fa-calendar-days"></i> Reports
                        </button>
                    </h2>
                    {/* <div id="collapseNine" className={`accordion-collapse collapse ${tabActive?.events ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <Link to="/teacher/events" className="nav-link">Events</Link>
                            </nav>
                        </div>
                    </div> */}
                </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.Marking ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse2">
                            <i className="fa-solid fa-calendar-days"></i> Marking
                        </button>
                    </h2>
                    <div id="collapse3" className={`accordion-collapse collapse ${tabActive?.Marking ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <Link to="/teacher/marking/testItem-list" className="nav-link">Test Item</Link>
                                <Link to="/teacher/marking/markingComment-list" className="nav-link">Marking Comment</Link>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* <div className="accordion-item">
                    <div className="accordion-body">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link to="/teacher/home-work-list" className="nav-link homework"><i class="fa-regular fa-file-word"></i> Homework</Link>
                        </nav>
                    </div>
                </div> */}
                <div className="sb-sidenav-footer"  >
                    <div className="small">Logged in as:</div>
                    Teacher
                </div>
            </div>
        </div>
    )
};
export default Sidebar;