import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { classview } from '../../../../apis/ClassManagment/ClassList'
const ClassView = () => {
    const [classView, setClassView] = useState({});
    const id = useParams();
    useEffect(() => {
        async function get_data() {
            const classView = await classview(id);
            setClassView(classView?.data);
            console.log(classView);
        }
        get_data();
    }, []);
    return (
        <div>
            <h4 className="modal-title">Class Detail</h4>
            <div className="right-nav" id="dash-event">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Classes</h4>
                    <div className='modify-right'>
                        <Link className='edit-btn' to={`/admin/class-list/edit/${id?.id}`}>Modify</Link>
                    </div>
                    </div>
                    <div className="modal-body">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                <div className="form-horizontal">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Branch Name</label>
                                                <p>{classView?.branch_name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <p>{classView?.subject}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Grade</label>
                                                <p>{classView?.grade}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Category Name</label>
                                                <p>{classView?.category_name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Term</label>
                                                <p>{classView?.term}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Year</label>
                                                <p>{classView?.year}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Class Name</label>
                                                <p>{classView?.movie_title}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Period</label>
                                                <p>{classView?.movie_period}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Price</label>
                                                <p>{classView?.movie_price}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Time</label>
                                                <p>{classView?.time_start}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <hr className='mt-3' />
                                            <div className="col-md-12">
                                                <label><b>Google meet</b></label>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>Time Start</label>
                                                        <p>{classView?.time_start}</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label>Time End</label>
                                                        <p>{classView?.time_end}</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='form-group'>
                                                        <label>Google Meet Link</label>
                                                        <p>https://meet.google.com/{classView?.google_meet_code}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ClassView;
