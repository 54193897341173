import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
import { testDetail_edit, testDetail_update } from '../../../../apis/testManagement/TestDetail';
import { useEffect } from 'react';
import { useState } from 'react';


const EditTestDetail = () => {
    const [subjects, setSubject] = useState([]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const id = useParams();
    const onSubmit = async (data) => {
        let result = await testDetail_update(id,data);
        if (result.status) {
            return navigate('/admin/test-management/testDetail');
        }
    }
    useEffect(() => {
        async function get_data() {
            const result = await testDetail_edit(id);
            const { test_type, test_kind, subject, test_detail_title, test_no, test_total_number, test_time, sort, tstm_guid, sample_movie_name } = result?.data[0];
            setValue('test_type', test_type);
            setValue('test_kind', test_kind);
            setValue('test_detail_title', test_detail_title);
            setValue('test_no', test_no);
            setValue('test_total_number', test_total_number);
            setValue('test_time', test_time);
            setValue('tstm_guid', tstm_guid);
            setValue('sort', sort);
            setValue('sample_movie_name', sample_movie_name);
            setTimeout(() => {
                setValue('subject', subject);
            }, 1000);
        }
        get_data();
    }, []);

    useEffect(() => {
        async function get_data() {
            const subject = await subject_all();
            setSubject(subject?.data);
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modify Test Detail</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Test Type</label>
                                    <select className="form-select form-control" {...register("test_type")}>
                                        <option value="">Please choose test type</option>
                                        <option value="1">diagnostic</option>
                                        <option value="2">formative</option>
                                        <option value="3">benchmark</option>
                                        <option value="4">summative</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Test Kind</label>
                                    <div className='highlight_inner'>
                                        <div className=" checkbox">
                                            <label> <input type="radio" id="event_offline" name="Objective" value="Objective"
                                                {...register('test_kind')}
                                            /> Objective </label>
                                        </div>
                                        <div className=" checkbox">
                                            <label> <input type="radio" id="event_online" name="Subjective" value="Subjective"
                                                {...register('test_kind')}
                                            /> Subjective </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Subject</label>
                                    <select className="form-select form-control" {...register("subject")}>
                                        <option value="">Please choose subject</option>
                                        {
                                            subjects && subjects?.map((elem) => {
                                                return <option value={elem?.id}>{elem?.subject}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>test detail Title</label>
                                    <input type="text" className="form-control" 
                                        {...register('test_detail_title')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>test no.</label>
                                    <input type="number" className="form-control" name="test_number"  {...register('test_no')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>test total num</label>
                                    <input type="number" className="form-control" name="test_total_number"  {...register('test_total_number')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>test total time</label>
                                    <input type="text" className="form-control" name="test_time"
                                        {...register('test_time')}
                                    />minute
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Sort</label>
                                    <select className=" form-selectform-control" name="sort" {...register('sort')}>
                                        <option value="1">1. Main (Default)</option>
                                        <option value="2">2</option>
                                        <option value="3">3. OC</option>
                                        <option value="4">4. WEMG</option>
                                        <option value="5">5. Selective</option>
                                        <option value="6">6. CS Class Material</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12. HSC</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>tstm guid</label>
                                    <input type="text" className="form-control" name="tstm_guid"
                                        {...register('tstm_guid')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>sample movie name</label>
                                    <input type="text" className="form-control" name="sample_movie_name"
                                        {...register('sample_movie_name')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default EditTestDetail;
