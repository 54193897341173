import { toast } from "react-toastify";
import {HTTPURL} from '../../constants/Matcher';

export async function forgot_Password(data) {
    try {
        const response = await fetch(HTTPURL + 'api/forgot-password', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        // console.log(error.message);
        return error.message;
    }
}



export async function reset_Password(data) {
    try {
        const response = await fetch(HTTPURL + 'api/reset-password', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        // console.log(error.message);
        return error.message;
    }
}