import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

// add Selective_trial_test_
export async function Selective_trial_test_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/add-selective-trail-test', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// get all Selective_trial_test_
export async function Selective_trial_test_all() {
    try {
        const response = await fetch(HTTPURL + 'api/all-selective-trail-tests');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// Selective_trial_test_ edit 
export async function Selective_trial_test_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/edit-selective-trail-test?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// Selective_trial_test_ update 
export async function Selective_trial_test_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/update-selective-trail-tests/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// Selective_trial_test_ delete
export async function Selective_trial_test_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/delete-selective-trail-tests?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
