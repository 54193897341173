import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import { courses_all_data, course_destroy } from '../../../apis/Course'
import CustomTable from '../CustomTable';
export const columns2 = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Course Type',
        selector: 'CoursType',
        sortable: true,
    },
    {
        name: 'Course',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Terms',
        selector: 'term',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },

    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];
const AllCourses = () => {
    const [showResults, setShowResults] = useState(false);
    const [Course, setCourse] = useState([]);
    const [status, setStatus] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [duplicateItemId, setDuplicateItemId] = useState(null);
    useEffect(() => {
        async function get_data() {
            const result = await courses_all_data();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, name, status, course_type_id,grade,term } = elem;

                return {     
                    no: `#${index + 1}`,
                    name: name,
                    status: status == '0' ? 'INACTIVE' : 'ACTIVE',
                    CoursType: course_type_id ? course_type_id : '-',
                    grade:grade,
                    term:term,
                    action: <div data-tag="allowRowEvents">
                        <Link class="edit-btn" to={`/admin/course-managment/course/edit/${id}`}>
                        <i class="fa fa-pencil" aria-hidden="true"></i></Link>
                        <button class="dlt-btn" onClick={() => destroy(id)}> <i class="fa fa-trash" aria-hidden="true"></i></button>
                        </div>
                };
            });
            setCourse(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete this course ?");
            if (result) {
                course_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Course List</h4>
                </div>
                <div className='col-md-8'>
                    <Link className='ui button float-end' to="/admin/course-managment/course/add">Add Course
                    </Link>
                </div>
            </div>
            {
                showResults ?
                    <CustomTable
                        columns={columns2}
                        data={Course}
                    />
                    :
                    <DataLoader />
            }
    

        </div>

    )
}
export default AllCourses;




