import React, { useEffect, useState } from 'react';
import { get_question } from '../../../../apis/testManagement/Question.jsx';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
import { get_text_books } from '../../../../apis/TextBook.jsx';

const AddSubjects = ({ setSub, sub }) => {
  const [forms, setForms] = useState([]);
  const [question, setQuestion] = useState([]);
  const [textBook, setTextBook] = useState([]);

  useEffect(() => {
    const id = localStorage.getItem('totalUnits') || '1'; // Default to '1' if not available
    const totalUnits = parseInt(id, 10);

    const initialForms = Array.from({ length: totalUnits }, (_, index) => ({
      unit_id: index + 1,
      week: '',
      q_id: '',
      u_image: '',
      u_audio: '',
      description: '',
      u_document: '',
      link: '',
      text_book_id: ''
    }));
    setForms(initialForms);
    setSub(initialForms); // Set initial forms to the parent component
  }, [setSub]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const questionList = await get_question();
        const textBookList = await get_text_books();
        setQuestion(questionList?.data || []);
        setTextBook(textBookList?.data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleLectureInputChange = (e, i) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[i][name] = value;
    setForms(updatedForms);
    setSub(updatedForms);
  };

  const handleLectureImgChange = (e, index) => {
    const { name, files } = e.target;
    const updatedForms = [...forms];
    updatedForms[index][name] = files[0] || null; // Handle empty file input
    setForms(updatedForms);
    setSub(updatedForms);
  };

  return (
    <div className="accordion" id="accordionExample">
      {forms?.map((form, i) => (
        <div key={i} className="accordion-item">
          <h2 className="accordion-header" id={`heading-${i}`}>
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${i}`}
              aria-expanded={i === 0 ? true : false}
              aria-controls={`collapse-${i}`}
            >
              Unit {i + 1}
            </button>
          </h2>
          <div
            id={`collapse-${i}`}
            className={`accordion-collapse collapse ${i === 0 ? 'show' : ''}`} 
            aria-labelledby={`heading-${i}`}
            data-bs-parent="#accordionExample"
          >
            <div className="modal-body">
              <div className="row">
                {/* Hidden unit_id field */}
                <input type="hidden" name="unit_id" value={form.unit_id} />
                {/* Document Upload */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Upload Document {i + 1}</label>
                    <input
                      type="file"
                      className="form-control"
                      name="u_document"
                      onChange={(e) => handleLectureImgChange(e, i)}
                      accept=".pdf"
                    />
                  </div>
                </div>
                {/* Embedded Link */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Embedded Link</label>
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Enter Link"
                      value={form.link}
                      name="link"
                      onChange={(e) => handleLectureInputChange(e, i)}
                    />
                  </div>
                </div>
                {/* Image Upload */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Upload Image</label>
                    <input
                      type="file"
                      className="form-control"
                      name="u_image"
                      onChange={(e) => handleLectureImgChange(e, i)}
                    />
                  </div>
                </div>
                {/* Audio Upload */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Upload Audio</label>
                    <input
                      type="file"
                      className="form-control"
                      name="u_audio"
                      onChange={(e) => handleLectureImgChange(e, i)}
                      accept="audio/*"
                    />
                  </div>
                </div>
                {/* Questionnaire */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Questionnaire</label>
                    <select
                      className="form-control form-select"
                      value={form.q_id}
                      name="q_id"
                      onChange={(e) => handleLectureInputChange(e, i)}
                    >
                      <option value="">Choose Question</option>
                      {question?.map((elem) => (
                        <option key={elem?.id} value={elem?.id}>
                          {elem?.question_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Text Book */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Text Book</label>
                    <select
                      className="form-control form-select"
                      name="text_book_id"
                      value={form.text_book_id}
                      onChange={(e) => handleLectureInputChange(e, i)}
                    >
                      <option value="">Choose text book</option>
                      {textBook?.map((elem) => (
                        <option key={elem?.id} value={elem?.id}>
                          {elem?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Description */}
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      placeholder="Enter Description"
                      value={form.description}
                      name="description"
                      onChange={(e) => handleLectureInputChange(e, i)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddSubjects;
