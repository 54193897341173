import React, { useEffect, useState } from 'react';
import { usersAll } from '../../../apis/Users.jsx';
import DataLoader from '../../../Utils/DataLoader.jsx';
import CustomTable from '../CustomTable.jsx';
import { branch_all } from '../../../apis/Branches';
import { Link } from 'react-router-dom';
const Users = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [checked, setChecked] = useState(false);
  const [branchlist, setBranchList] = useState([]);
  const [branchId, setBranchId] = useState('')
  const [userList, setUserList] = useState(false);
  const [teacherCheckbox, setTeacherCheckbox] = useState(false);
  const [studentCheckbox, setStudentCheckbox] = useState(false);
  console.log('teacherCheckbox',teacherCheckbox,studentCheckbox)
  const columns = [
    {
      name: 'No.',
      selector: 'no',
      sortable: true,
    },
    {
      name: 'Name',
      selector: 'first_name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'User Type',
      selector: 'roles',
      sortable: true,
    },
    {
      name: 'Number',
      selector: 'mobile',
      sortable: true,
    },
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
    },
    // {
    //   name: 'View',
    //   selector: 'action',
    //   sortable: true,
    // },
  ];
  const branch_id = branchId
  async function get_data() {

    const result = await usersAll(studentCheckbox || teacherCheckbox ? id ? id : [] : [], branch_id);
    const arrayData = result?.data?.map((elem, index) => {
      const { id, first_name, mobile, email, last_name, created_at, roles } = elem;
      return {
        no: `#${index + 1}`,
        first_name: `${first_name} ${last_name}` ? `${first_name} ${last_name == null ? '' : last_name}` : '-',
        email: email ? email : '-',
        mobile: mobile ? mobile == 'null' ? '-' : mobile : '-',
        date: created_at ? created_at : '-',
        roles: roles ? roles : '-',
        action: roles === 'Teacher' ? <div data-tag="allowRowEvents"><Link className='edit-btn' to={`/admin/viewAttendance/${id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link></div> : ''
      };
    });
    setData(arrayData, setShowResults(true));
  }
  useEffect(() => {
    const fetchData = async () => {
      const branchList = await branch_all();
      setBranchList(branchList?.data);
    }
    fetchData();
    get_data();
    if (branchId) {
      if (!teacherCheckbox && !studentCheckbox) {
        setId('')
      }
      get_data();
    } else {
      setChecked(false);
    }
  }, [branchId, id]);
  const get_user_change = event => {
    const { checked, value } = event.currentTarget;
    setUserList({ ...checked, checked })
    setId(
      prev => checked
        ? [...prev, value]
        : prev?.filter(val => val !== value)
    );
  };
  return (
    <div className="right-nav" id="dash-event">
      <div className='row'>
        <div className='col-md-4'>
          <h4 className='modal-title'>Users List</h4>
        </div>
        <div className='col-md-8'>
          {/* <Link className='ui button float-end' to="/admin/events/add">New Event</Link> */}
        </div>
      </div>
      <div className='row'>
        <div className="col-md-6">
          <div className="form-group">
            <label>Branch</label>
            <select className="form-select form-control"
              onChange={(e) => { e.target.value === '' ? setChecked(false) : setChecked(true); setBranchId(e.target.value); setTeacherCheckbox(false); setStudentCheckbox(false) }}
            >
              <option value="">CHOOSE BRANCH</option>
              {
                branchlist?.length > 0 ?
                  branchlist && branchlist?.map((elem) => {
                    return (
                      <option value={elem?.id} >{elem?.branch_name}</option>
                    )
                  })
                  :
                  <option>No result</option>
              }
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            {/* <select className="form-select form-control">
            <option value="">CHOOSE USER TYPE</option> */}
            {/* {checked && ( */}
              <>
                <label>User Type</label>
                <div className="col-md-6">
                  <div className='d-flex'>
                    <div className='d-flex align-items-center'>
                      <input class="form-check-input"
                        type="checkbox"
                        value='2' id='tea'
                        checked={teacherCheckbox}
                        onChange={(e) => (get_user_change(e), setTeacherCheckbox(!teacherCheckbox))}
                      /> <label for="tea" className=" ms-3 mb-0">Teacher</label>
                    </div>
                    <div className='d-flex align-items-center ms-5'>
                      <input class="form-check-input" id='stu'
                        type="checkbox"
                        value='3'
                        checked={studentCheckbox}
                        onChange={(e) => (get_user_change(e), setStudentCheckbox(!studentCheckbox))}
                      /> <label for="stu" className=" ms-3 mb-0 ">student</label>
                    </div>
                  </div>
                </div>
              </>
           {/* )}  */}
            {/* </select> */}

          </div>
        </div>


      </div>
      {userList ?
        showResults ?
          <CustomTable
            columns={columns}
            data={data}
          />
          :
          <DataLoader /> : <CustomTable
          columns={columns}
          data={data}
        />
      }
    </div>
  )
}
export default Users;
