import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader';
import { Selective_trial_test_all, Selective_trial_test_destroy } from '../../../../apis/Selective_trial_test'
import CustomTable from '../../CustomTable';
import EditTrialTest from './Edit';
export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Question Name',
        selector: 'question_name',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];
const AllTrialTest = () => {
    const [grade, setGrade] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [status, setStatus] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [duplicateItemId, setDuplicateItemId] = useState(null);
    useEffect(() => {
        const get_data = async () => {
            try {
                const result = await Selective_trial_test_all();
                const arrayData = result?.data?.map((elem, index) => {
                    const { id,  status, name,question_name } = elem;
                        return {
                            no: `#${index + 1}`,
                            question_name: question_name,
                            status: status === '0' ? 'Inactive' : 'Active',
                            name: name,
                            action: (
                                <div data-tag="allowRowEvents">
                                    <button
                                        onClick={() => {
                                            setDuplicateItemId(id);
                                            setIsModalOpen(true);
                                            setStatus('grade');
                                        }}
                                        className="dlt-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop"
                                    >
                                     <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </button>
                                    <button className="dlt-btn" onClick={() => destroy(id)}>
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </div>
                            ),
                        };
                })
                setGrade(arrayData);
                setShowResults(true);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const destroy = async (id) => {
            const result = window.confirm('Are you sure you want to delete this trial test?');
            if (result) {
                await Selective_trial_test_destroy(id);
            }
            get_data();
        };
        get_data();
    }, []);
    return (
        <>
            <div className="modal-content">
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="right-nav" id="dash-event">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <h4 className='modal-title'>Selective Trail Test List</h4>
                                </div>
                                <div className='col-md-8'>
                                    <Link className='ui button float-end' to="/admin/course-managment/selective-trial-test/add">Add Selective Trial Test
                                    </Link>
                                </div>
                            </div>
                            {
                                showResults ?
                                    <CustomTable
                                        columns={columns}
                                        data={grade}
                                    />
                                    :
                                    <DataLoader />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <EditTrialTest duplicateItemId={duplicateItemId} isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen} status={status} setStatus={setStatus} />
        </>
    )
}
export default AllTrialTest;



