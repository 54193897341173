import React, { useEffect, useState } from 'react';
import { get_question } from '../../../../apis/testManagement/Question.jsx';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
import AddLecture from '../Lecture/Add.jsx';
import { get_text_books } from '../../../../apis/TextBook.jsx';
const EditLecture = ({ setLecture, lecture, setLectureData, lectureData }) => {
    const [subjects, setSubject] = useState([]);
    const [question, setQuestion] = useState([]);
    const [textBook, setTextBook] = useState([]);
    const handleLectureDataChange = (index, fieldname, value) => {
        if (fieldname == 'subject_id') {
            localStorage.setItem('courseSubjectID', value)
        }
        const updatedData = [...lectureData];
        updatedData[index] = { ...updatedData[index], [fieldname]: value };
        setLectureData(updatedData);
    };
    //lecture handle change for image
    const handleLectureImgChange = (e, index) => {
        const { name, files } = e.target;
        const list = [...lectureData];
        list[index][name] = files[0];
        setLectureData(list);
    }

    useEffect(() => {
        const fetchData = async () => {
            const questionList = await get_question();
            const result = await subject_all();
            const textBookList = await get_text_books();
            setQuestion(questionList?.data);
            setSubject(result?.data);
            setTextBook(textBookList?.data);
        };
        fetchData();
    }, []);
    return (
        <>

            {
                lectureData && lectureData.length > 0 ?

                    lectureData && lectureData?.map((ele, index, data) => {
                        return (
                            <>
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel1" aria-labelledby="pills-home-tab">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-6" key={index}>
                                                <div className="form-group">
                                                    <label>Upload Document {index + 1}</label>
                                                    <input type="file" className="form-control"
                                                        name='u_document'
                                                        onChange={(e) => handleLectureImgChange(e, index)}
                                                        accept=".pdf" />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Embedded Link</label>
                                                    <input type="url" className="form-control"
                                                        placeholder='Enter Link'
                                                        name='link'
                                                        value={ele?.link}
                                                        onChange={(e) => handleLectureDataChange(index, 'link', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Upload Image</label>
                                                    <input type="file" className="form-control"
                                                        name='u_image'
                                                        onChange={(e) => handleLectureImgChange(e, index)} />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Upload Audio</label>
                                                    <input type="file" className="form-control"
                                                        name='u_audio'
                                                        accept='audio/*'
                                                        onChange={(e) => handleLectureImgChange(e, index)} />

                                                </div>
                                            </div>
                                            <div className="col-md-6" >
                                                <div className="form-group">
                                                    <label>Unit </label>
                                                    <select className="form-control form-select"
                                                        value={ele?.unit} name='unit'
                                                        onChange={(e) => handleLectureDataChange(index, 'unit', e.target.value)} >
                                                        <option value="">Choose Unit</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                    </select>
                                                    {/* <input type="text" className="form-control"
                                                        placeholder='Enter Unit'
                                                        value={ele?.unit}
                                                        name='unit'
                                                        onChange={(e) => handleLectureDataChange(index, 'unit', e.target.value)}
                                                    /> */}
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Week</label>
                                                    <input type="text" className="form-control"
                                                        placeholder='Enter Week'
                                                        value={ele?.week}
                                                        name='week'
                                                        onChange={(e) => handleLectureDataChange(index, 'week', e.target.value)}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-md-6" >
                                                <div className="form-group">
                                                    <label>Questionaire</label>
                                                    <select className="form-control form-select"
                                                        value={ele?.q_id}
                                                        name='q_id'
                                                        onChange={(e) => handleLectureDataChange(index, 'q_id', e.target.value)} >
                                                        <option value="">Choose Question</option>
                                                        {
                                                            question && question?.map((elem) => {
                                                                return (
                                                                    <option value={elem?.id}>{elem?.question_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Subject</label>
                                                    <select className="form-control form-select"
                                                        name='subject_id'
                                                        value={ele?.subject_id}
                                                        onChange={(e) => handleLectureDataChange(index, 'subject_id', e.target.value)}>
                                                        <option value="">Choose Subject</option>
                                                        {
                                                            subjects && subjects?.map((elem) => {
                                                                return <option value={elem?.id}>{elem?.subject}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Text Book</label>
                                                    <select className="form-control form-select"
                                                        name='text_book_id'
                                                        value={ele.text_book_id}
                                                        onChange={(e) => handleLectureDataChange(index, 'text_book_id', e.target.value)}>
                                                        <option value="">Choose text book</option>
                                                        {
                                                            textBook && textBook?.map((elem) => {
                                                                return <option value={elem?.id}>{elem?.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Description</label>
                                                    <textarea type="text" className="form-control"
                                                        placeholder='Enter Description'
                                                        value={ele?.description}
                                                        name='description'
                                                        onChange={(e) => handleLectureDataChange(index, 'description', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-2">
                                    <div className='add_btn'>
                                        {
                                            lecture.length !== 1 && (
                                                <button className='btn-warning' onClick={() => handleLectureRemoveClick(i)}>Remove</button>
                                            )
                                        }
                                        {
                                            lecture.length - 1 === i && (
                                                <button className='btn-success' onClick={() => handleLectureAddClick()}>Add</button>
                                            )
                                        }
                                    </div>
                                </div> */}
                                    <hr />
                                </div>
                            </>
                        )
                    })
                    :
                    <AddLecture setLecture={setLecture} lecture={lecture} />
            }

        </>
    )
}
export default EditLecture;






