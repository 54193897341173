import { toast } from "react-toastify";
import {HTTPURL} from '../../constants/Matcher';

export async function teacher_login(data) {
    try {
        const local = `http://127.0.0.1:4040/`;
        const response = await fetch(HTTPURL + 'api/teacher/teacher', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            localStorage.setItem("userData", JSON.stringify(responseData));
            var now = new Date().getTime();
            localStorage.setItem('setupTime', now);
            localStorage.setItem('email',responseData?.data?.email)
            localStorage.setItem('fullname',responseData?.data?.fullname)
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
