import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { branch_add_question_time } from '../../../api/branches/Branch';

const QuestionAdd = ({ isModalOpen, setIsModalOpen, question_id, setStatus }) => {
    const userId = JSON.parse(localStorage.getItem('userData'));
    const { 
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm();
    const navigate = useNavigate();
    const onSubmit = async (data) => {
        data.question_id = question_id;
        data.user_id = userId.data?.id;
        let result = await branch_add_question_time(data);
        if (result?.status) {
            { setIsModalOpen(false); setStatus("") }
            window.location.reload();
            return navigate('/admin/assesment/all');
        }
    }
    

    useEffect(() => {
        setIsModalOpen(false);
    }, []);
    
    // const maxDate = new Date();
    // maxDate.setFullYear(maxDate.getFullYear() );
    // const TodayDate = maxDate.toISOString().split('T')[0];

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1);
    const TodayDate = maxDate.toISOString().split('T')[0] + "T00:00";
    return (
        <>
            <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden={isModalOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="mx-auto modal-title mt-2" id="staticBackdropLabel">Add Schedule for Questionnaire </h5>
                            <button onClick={() => { setIsModalOpen(false); setStatus("") }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal"
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Time</label>
                                            <input type="datetime-local"  {...register('start_time', { required: true })} min={TodayDate} className='form-control' />
                                        </div>
                                        {errors.start_time && errors.start_time.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>End Time</label>
                                            <input type="datetime-local" {...register('end_time', { required: true })} min={TodayDate} className='form-control' />
                                        </div>
                                        {errors.end_time && errors.end_time.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type='submit' className="btn btn-primary">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default QuestionAdd;

