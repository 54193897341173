import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";


// get all items
export async function get_naplanCriteria() {
    try {
        const response = await fetch(HTTPURL + 'api/writing-criteria-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}



// add item
export async function add_naplanCriteria(data) {
    try {
        const response = await fetch(HTTPURL + 'api/writing-criteria-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// item delete
export async function naplanCriteria_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/writing-criteria-destroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// item edit 
export async function naplanCriteria_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/writing-criteria-edit?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// item update 
export async function naplanCriteria_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/writing-criteria-update?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
