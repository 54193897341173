import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Category_edit, Category_update } from '../../../../apis/Catergory';
import { get_Category } from '../../../../apis/Catergory'
const Edit_category = ({ isModalOpen, setIsModalOpen, duplicateItemId, status, setStatus }) => {
    console.log('dsf',duplicateItemId);
    const [checkValidation, setCheckValidation] = useState(true);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

        //grade get data api
        useEffect(()=>{
            async function get_data() {
                const result = await Category_edit(duplicateItemId);
                if (result?.data?.length > 0) {
                    const { name, status } = result?.data[0];
                    setValue('name', name);
                    setValue('status', status);
                            }
            }
            if (isModalOpen) {
            get_data();
        }
        },[isModalOpen, duplicateItemId])
  
    async function getData() {
        const result = await get_Category();
    }
    useEffect(() => {
        getData();
        return (
            setIsModalOpen(false)
        )
    }, []);
    const onSubmit = async (data) => {
            let result = await Category_update(duplicateItemId, data);
            if (result?.status) {
                { setIsModalOpen(false); setStatus("") }
                window.location.reload();
                // getData();
                // return navigate('/admin/grades/all');
          
        }
    }
    return (
        <>
            <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden={isModalOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="mx-auto modal-title mt-2" id="staticBackdropLabel">Edit Data</h5>
                            <button onClick={() => { setIsModalOpen(false); setStatus("") }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal"
                                onSubmit={handleSubmit(onSubmit)} >
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Category Name</label>
                                            <input type="text" className="form-control"
                                                placeholder='Enter Category Name'
                                                {...register('name', { required: true })}
                                            />
                                        </div>
                                        {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>status</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio1" value="1" />
                                                <label class="form-check-label" for="inlineRadio1">Active</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio2" value="0" />
                                                <label class="form-check-label" for="inlineRadio2">Inactive</label>
                                            </div>
                                        </div>
                                        {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {!checkValidation?
                                     <button type='submit' className="btn btn-primary" data-bs-dismiss="modal" disabled>Save</button>:
                                     <button type='submit' className="btn btn-primary" >Save</button>}
                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Edit_category;


