import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams} from 'react-router-dom';
import {  add_study_material } from '../../../apis/ClassManagement/ClassList';
import { useEffect } from 'react';

const EditStudyMaterial = (id) => {
    const [image, setImage] = useState({ data: '' });
    let images = [];
    {console.log('edit study',id.id.id)}
    const handleFileChange = (e) => {
        console.log('e.target',e.target.files);
        for (let i = 0; i < e.target.files.length; i++) 
        {
            images.push(e.target.files[i]);
        }
        setImage({data:images});
    }


    const {
        register,
        getValues,
        setValue,
        handleSubmit,
        formState: { errors }
    } = useForm();
    // const id = useParams();
    const navigate = useNavigate();
    const onSubmit = async (data) => {
           const form = new FormData();
        image.data.forEach(img => {
            form.append('study_file', img);
        });
        const dataID=id.id.id
        form.append("class_id",`${dataID}`);
        let result = await add_study_material(form);
        if (result.status) {
            return navigate('/admin/class-lists');
        }
    }
    
    const fetchData = async () => {
        // let res = await class_list_edit(id);
            // setValue('study_file', res?.data?.file)
    }

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Class Time</label>
                                    <input type="file" className="form-control" multiple {...register("study_file")} onChange={(e) => handleFileChange(e)} />
                            
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type='submit' className="btn btn-primary">Save</button>
                </div>
            </div>
        </form>
    )
}

export default EditStudyMaterial;
