import { class_list_all } from "../../../apis/ClassManagement/ClassList";
import { branch_all } from "../../../apis/Branches";
import { subject_all } from "../../../apis/ClassManagement/Subjects";
import { grade_all } from "../../../apis/Grade";
import { get_terms } from '../../../apis/Common';

export const branchList = async () => 
{
    try {
        const result = await branch_all();
        // Assuming result is an array of objects with properties value and label
        const transformedOptions = result?.data;
        return transformedOptions;
      } catch (error) {
        console.error('Error fetching data:', error);
        return error;
      }
}

// export const termList = async () => 
// {
//     try {
//         const result = await get_terms();
//         // Assuming result is an array of objects with properties value and label
//         const transformedOptions = result?.data;
//         return transformedOptions;
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         return error;
//       }
// }

export const termList = async () => 
{
    try {
        const result = await get_terms();
        // Assuming result is an array of objects with properties value and label
        const transformedOptions = result?.data?.map(item => ({ value: item?.id, label: item.name }));
        return transformedOptions;
      } catch (error) {
        console.error('Error fetching data:', error);
        return error;
      }
}
export const subjectList = async () => 
{
    try {
        const result = await subject_all();
        // Assuming result is an array of objects with properties value and label
        const transformedOptions = result?.data?.map(item => ({ value: item?.id, label: item.subject }));
        return transformedOptions;
      } catch (error) {
        console.error('Error fetching data:', error);
        return error;
      }
}


       
export const gradeList = async () => 
{
    try {
        const result = await grade_all();
        // Assuming result is an array of objects with properties value and label
        const transformedOptions = result?.data?.map(item => ({ value: item?.id, label: item.name === 0 ? `Year ${item.name} (kindy)` : `Year ${item.name}`}));
        return transformedOptions;
      } catch (error) {
        console.error('Error fetching data:', error);
        return error;
      }
}

