import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { grade_update, grade_edit } from '../../../apis/Grade';
import { grade_all } from '../../../apis/Grade'
const EditGrade = ({ isModalOpen, setIsModalOpen, duplicateItemId, status, setStatus }) => {
    const [checkValidation, setCheckValidation] = useState(true);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const regexPattern = /^(year|Year)\s?(1[0-2]|[0-12])$/i;
    const checkregex = (name) => {
        console.log('name', name);
        if (regexPattern.test(name)) {
            console.log("Valid string");
            setCheckValidation(true);
        } else {
            setCheckValidation(false);
        }
    }
    //grade get data api
    useEffect(() => {
        async function get_data() {
            const result = await grade_edit(duplicateItemId);
            if (result?.data?.length > 0) {
                const { name, status } = result?.data[0];
                setValue('name', name);
                setValue('status', status);
            }
        }
        if (isModalOpen) {
            get_data();
        }
    }, [isModalOpen, duplicateItemId])

    async function getData() {
        const result = await grade_all();
    }
    useEffect(() => {
        getData();
        return (
            setIsModalOpen(false)
        )
    }, []);
    const onSubmit = async (data) => {
        if (!checkValidation) {
            console.log('not valid');
        } else {
            let result = await grade_update(duplicateItemId, data);
            if (result?.status) {
                { setIsModalOpen(false); setStatus("") }
                window.location.reload();
                // getData();
                // return navigate('/admin/grades/all');
            }
        }
    }
    return (
        <>
            <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden={isModalOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="mx-auto modal-title mt-2" id="staticBackdropLabel">Edit Data</h5>
                            <button onClick={() => { setIsModalOpen(false); setStatus("") }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal"
                                onSubmit={handleSubmit(onSubmit)} >
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Grade Name</label>
                                            {/* <select className="form-control" name="name" {...register('name', { required: true })}>
                                                <option value="">Choose Grade</option>
                                                <option value="1">Year 1</option>
                                                <option value="2">Year 2</option>
                                                <option value="3">Year 3</option>
                                                <option value="4">Year 4</option>
                                                <option value="5">Year 5</option>
                                                <option value="6">Year 6</option>
                                                <option value="7">Year 7</option>
                                                <option value="8">Year 8</option>
                                                <option value="9">Year 9</option>
                                                <option value="10">Year 10</option>
                                                <option value="11">Year 11</option>
                                                <option value="12">Year 12</option>
                                            </select> */}
                                            <input type="text" className="form-control"
                                                placeholder='Enter Grade Name'
                                                {...register('name', { required: true })}
                                                onChange={(e) => checkregex(e.target.value)}
                                            />
                                        </div>
                                        {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                                        {checkValidation ? '' : <span className="validation-error">Grade name should be year and number between 0 to 12</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>status</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio1" value="1" />
                                                <label class="form-check-label" for="inlineRadio1">Active</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio2" value="0" />
                                                <label class="form-check-label" for="inlineRadio2">Inactive</label>
                                            </div>
                                        </div>
                                        {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {!checkValidation ?
                                        <button type='submit' className="btn btn-primary" data-bs-dismiss="modal" disabled>Save</button> :
                                        <button type='submit' className="btn btn-primary" data-bs-dismiss="modal">Save</button>}

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditGrade;


