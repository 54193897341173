import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { classview } from '../../../api/ClassList'
const ClassView = () => {
    const [classView, setClassView] = useState({});
    const [image, setImage] = useState([]);
    const id = useParams();
    useEffect(() => {
        async function get_data() {
            const classView = await classview(id);
            setClassView(classView?.data);
            setImage(JSON.parse(classView?.data?.study_files))
        }
        get_data();
    }, []);
    
    return (
        <div>
            <h4 className="modal-title">Class Detail</h4>
            <div className="right-nav" id="dash-event">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Classes</h4>
                    {/* <div className='modify-right'>
                        <Link className='edit-btn' to={`/branch/classList/edit/${id?.id}`}>Modify</Link>
                    </div> */}
                    </div>
                    <div className="modal-body">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                <div className="form-horizontal">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Branch Name</label>
                                                <p>{classView?.branch_name?classView?.branch_name:'-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <p>{classView?.subject?classView?.subject:'-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Grade</label>
                                                <p>{classView?.grade?classView?.grade:'-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Course Type</label>
                                                <p>{classView?.category_name?classView?.category_name:'-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Term</label>
                                                <p>{classView?.term?classView?.term:'-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Year</label>
                                                <p>{classView?.year?classView?.year:'-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Class Name</label>
                                                <p>{classView?.movie_title?classView?.movie_title:'-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Lessons</label>
                                                <p>{classView?.movie_period?classView?.movie_period:'-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Unit Price</label>
                                                <p>{classView?.movie_price?classView?.movie_price:'-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Time</label>
                                                <p>{classView?.time_start?classView?.time_start:'-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <hr className='mt-3' />
                                            <div className="col-md-12">
                                                <label><b>Google meet</b></label>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>Time Start</label>
                                                        <p>{classView?.time_start?classView?.time_start:'-'}</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label>Time End</label>
                                                        <p>{classView?.time_end?classView?.time_end:'-'}</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label>Google Meet Link</label>
                                                        <p>https://meet.google.com/{classView?.google_meet_code}</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label>Day</label>
                                                        <p>{classView?.date_name?classView?.date_name:'-'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <hr className='mt-3' />
                                            <div className="col-md-12">
                                                <label><b>study material</b></label>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                        { image.length > 0 ? 
                                                            image && image?.map((elem) => {
                                                                return (
                                                                    <div className='col-md-3'>
                                                                        <img className='class-view' src={elem?.file_name} />
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div>NO study materials.</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ClassView;
