import { toast } from "react-toastify";
import { HTTPURL } from "../../../constants/Matcher";

// google class add 
export async function google_class_add(data)
{
    try {
        const response = await fetch(HTTPURL + 'api/google-meet-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all class-google 
export async function google_class_list()
{
    try {
        const response = await fetch(HTTPURL + 'api/google-classess-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// class-google delete
export async function google_class_list_destroy(id) 
{
    try {
        const response = await fetch(HTTPURL + `api/google-classess-destroy?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// class-list edit 
export async function google_meet_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/google-meet-edit/?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// class-list update 
export async function google_meet_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/google-meet-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}