import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

// add assessment
export async function Assesment_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/add-assessment', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// add grade
export async function Free_Assesment_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/add-free-assessment', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all grades
export async function Assesment_all() {
    try {
        const response = await fetch(HTTPURL + 'api/all-assessments');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function Free_Assesment_all() {
    try {
        const response = await fetch(HTTPURL + 'api/all-free-assessments');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// // get all grades
// export async function grades_all_data() {
//     try {
//         const response = await fetch(HTTPURL + 'api/all-grades-data');
//         const responseData = await response.json();
//         if (responseData.status) {
//             // toast.success(responseData?.message);
//         } else {
//             toast.error(responseData?.message);
//         }
//         return responseData;
//     } catch (error) {
//         console.log(error.message);
//         return error.message;
//     }
// }

// grade edit 
export async function Assesment_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/edit-assessment?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function Free_Assesment_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/edit-free-assessment?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// grade update 
export async function Assesment_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/update-assessment/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch delete
export async function Assesment_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/destroy-assessment?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
