import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";


// get all items
export async function get_item() {
    try {
        const response = await fetch(HTTPURL + 'api/items-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}



// add item
export async function add_items(data) {
    try {
        const response = await fetch(HTTPURL + 'api/item-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// item delete
export async function item_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/item-destroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item edit 
export async function item_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/item-edit?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item update 
export async function item_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/item-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
