import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader.jsx';
import { subject_all, subject_destroy } from '../../../../apis/ClassManagement/Subjects.jsx';
import CustomTable from '../../CustomTable.jsx';

export const columns = [
  {
    name: 'No.',
    selector: 'no',
    sortable: true,
  },
  {
    name: 'Subject',
    selector: 'subject',
    sortable: true,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: true,
  }
];

const AllSubjects = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    async function get_data() {
      const result = await subject_all();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, subject } = elem;
        return {
          no: `#${index + 1}`,
          subject: subject,
          action: <div data-tag="allowRowEvents">
            <Link class="edit-btn" to={`/admin/general-setting/subject/edit/${id}`}>
              <i class="fa fa-pencil" aria-hidden="true"></i></Link>
            <button className="dlt-btn" onClick={() => destroy(id)}>  <i class="fa fa-trash" aria-hidden="true"></i></button>
          </div>
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = (id) => {
      const result = window.confirm("Are you sure you want to delete this subject?");
      if (result) {
        subject_destroy(id);
      }
      get_data();
    }
    get_data();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      <div className='row'>
        <div className='col-md-4'>
          <h4 className='modal-title'>Subject List</h4>
        </div>
        <div className='col-md-8'>
          <Link className='ui button float-end' to="/admin/general-setting/subject/add">Add Subject</Link>
        </div>
      </div>
      {
        showResults ?

          <CustomTable
            columns={columns}
            data={data}
          />
          :
          <DataLoader />
      }
    </div>
  )
}

export default AllSubjects