import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";

// on changes request get test type
export async function get_data_test_type(id){
    try {
        const response = await fetch(HTTPURL + `api/get-test-type?id=${id}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

// get all items
export async function get_testDetail() {
    try {
        const response = await fetch(HTTPURL + 'api/test-details-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

// add item
export async function add_testDetail(data) {
    try {
        const response = await fetch(HTTPURL + 'api/test-detail-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// item delete
export async function testDetail_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/test-detail-destroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// item edit 
export async function testDetail_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/test-detail-edit?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// item update 
export async function testDetail_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/test-detail-update?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
