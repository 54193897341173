import React, { useRef } from "react";
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { reset_Password } from "../../api/ForgotPassword";
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../../image/cslogo-300x34.png';

const ResetPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch
    } = useForm();

    const password = useRef({});
    const navigate = useNavigate();
    password.current = watch("password", "");

 const token = useParams();

    const onSubmit = async (data) => {
        data.roles = 'Teacher';
        data.token=token?.token;
        let result = await reset_Password(data);
        if (result) {
            return navigate('/branch');
        } 
    }

    return (
        <>
            <div id="layoutAuthentication" className="login-page">
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header">
                                            <div className="login-head-logo"><img src={Logo} alt="CSLogo_white.png" /></div>
                                        </div>
                                        <div className="card-body">
                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="card-body text-black">
                                                    <div className="form-outline mb-4">
                                                        <Form.Field>
                                                            <label className="form-label" htmlFor="Password">Password</label>
                                                            <input
                                                                placeholder='Password'
                                                                type="password"
                                                                {...register('password', { required: true })}
                                                                // {...register("password", {
                                                                //     required: true,
                                                                //     // pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                                                                // })}
                                                                className="form-control form-control-lg"
                                                            />
                                                        </Form.Field>
                                                        {errors.password && errors.password.type === "required" && <span className="validation-error">This is required</span>}
                                                        {/* {errors.password && errors.password.type === "pattern" && <span className="validation-error">At least 6 chars long,digit,upper case,lower case</span>} */}
                                                    </div>
                                                    <div className="form-outline mb-4">
                                                        <Form.Field>
                                                            <label className="form-label" htmlFor="Password">Confirm Password</label>
                                                            <input
                                                                placeholder='confirm Password'
                                                                name="confirmPassword"
                                                                type="password"
                                                                {...register('confirmPassword', {
                                                                    required: true,
                                                                    validate: value =>
                                                                        value === password.current || "The passwords do not match"
                                                                })}
                                                                // {...register("password", {
                                                                //     required: true,
                                                                //     // pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                                                                // })}
                                                                className="form-control form-control-lg"
                                                            />
                                                        </Form.Field>
                                                        {errors.confirmPassword && errors.confirmPassword.type === "required" && <span className="validation-error">This is required</span>}
                                                        {errors.confirmPassword && <p className="validation-error">{errors.confirmPassword.message}</p>}
                                                    </div>
                                                    <div className="d-flex justify-content-center pt-3">
                                                        <Button type='submit' className="btn btn-warning btn-lg ms-2 sap-btn-dark">Submit</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
};

export default ResetPassword;