import React,{useEffect} from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { add_items, item_edit, item_update } from '../../../../apis/testManagement/Items'
const AddItem = () => 
{
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await add_items(data);
        if (result.status) {
            return navigate('/admin/test-management/items');
        }
    }

  return (
    <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Item</h4>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-12">
                            <div className="form-group">
                                <labell>Item Name</labell>
                                <input type="text" className="form-control"
                                placeholder='Enter Item Name'
                                      {...register('item_name', { required: true })}
                                />
                            </div>
                                {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type='submit' className="btn btn-primary">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
  )
}

const EditItem = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const id = useParams();

    const onSubmit = async (data) => {
        let result = await item_update(id, data);
        if (result.status) {
            return navigate('/admin/test-management/items');
        }
    }
    const fetchData = async () => {
        let res = await item_edit(id);
        setValue('item_name', res?.data[0]?.item_name);
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modify Item</h4>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Item Name</label>
                                    <input type="text" className="form-control"
                                    placeholder='Enter Item Name'
                                        {...register('item_name', { required: true })}
                                    />
                                </div>
                                {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type='submit' className="btn btn-primary">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export {AddItem, EditItem};
