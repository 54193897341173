import { toast } from "react-toastify";
import { HTTPURL } from '../../constants/Matcher';





// student_enroll_list
export async function student_enroll_list(branch_id) 
{
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-enrolled-student?id=${branch_id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// student_enroll_list
export async function free_student_list(branch_id) 
{
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-free-student?id=${branch_id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// student_enroll_list
export async function student_enroll_view(id) 
{
    try {
        const response = await fetch(HTTPURL + `api/branches/endrolled-student-details?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// add teacher
export async function enroll_student_payment(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/branch-update-to-enrolled', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}