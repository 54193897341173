import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import { text_book_destroy, get_text_books } from '../../../apis/TextBook'
import CustomTable from '../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    // {
    //     name: 'Grade',
    //     selector: 'grade',
    //     sortable: true,
    // },
    // {
    //     name: 'Term',
    //     selector: 'term',
    //     sortable: true,
    // },
    // {
    //     name: 'Subject',
    //     selector: 'subject',
    //     sortable: true,
    // },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Price',
        selector: 'price',
        sortable: true,
    },
    // {
    //     name: 'Link',
    //     selector: 'link',
    //     sortable: true,
    // },

    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];

const AllTextBooks = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {

        async function get_data() {
            const result = await get_text_books();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, name, price, link, status, subject, grade, term } = elem;
                return {
                    no: `#${index + 1}`,
                    name: name,
                    price: price,
                    link: link,
                    status: status=='1'?'ACTIVE':'INACTIVE',
                    subject: subject,
                    grade: grade,
                    term: term,
                    action: <div data-tag="allowRowEvents">
                    <Link className='edit-btn' to={`/admin/course-managment/text-book/edit/${id}`}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                    <button className="dlt-btn" onClick={() => destroy(id)}> <i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete this category ?");
            if (result) {
                text_book_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Text Books List</h4>
                </div>
                <div className='col-md-8'>
                    <Link className='ui button float-end' to="/admin/course-managment/text-book/add">Add Text Books
                    </Link>
                </div>
            </div>
            {
                showResults ?
                    <CustomTable
                        columns={columns}
                        data={data}
                    />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default AllTextBooks;


