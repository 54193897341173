import { branch_all } from "../../../api/common";
import { subject_all } from "../../../api/Subject";
import { grade_all } from "../../../api/common";

export const branchList = async () => 
{
    try {
        const result = await branch_all();
        // Assuming result is an array of objects with properties value and label
        const transformedOptions = result?.data;
        return transformedOptions;
      } catch (error) {
        console.error('Error fetching data:', error);
        return error;
      }
}

export const subjectList = async () => 
{
    try {
        const result = await subject_all();
        // Assuming result is an array of objects with properties value and label
        const transformedOptions = result?.data?.map(item => ({ value: item?.id, label: item.subject }));
        return transformedOptions;
      } catch (error) {
        console.error('Error fetching data:', error);
        return error;
      }
}

export const gradeList = async () => 
{
    try {
        const result = await grade_all();
        // Assuming result is an array of objects with properties value and label
        const transformedOptions = result?.data?.map(item => ({ value: item?.id, label: item.name }));
        return transformedOptions;
      } catch (error) {
        console.error('Error fetching data:', error);
        return error;
      }
}

