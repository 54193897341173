import { toast } from "react-toastify";
import { HTTPURL } from '../../constants/Matcher';

export async function get_category() {
    try {
        const response = await fetch(HTTPURL + 'api/categories-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}




// get all grades
export async function grade_all() {
    try {
        const response = await fetch(HTTPURL + 'api/grades-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// get all term
export async function get_terms() {
    try {
        const response = await fetch(HTTPURL + 'api/terms-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get all course
export async function course_all() {
    try {
        const response = await fetch(HTTPURL + 'api/courses-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all days
export async function get_days() {
    try {
        const response = await fetch(HTTPURL + 'api/days-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}


// get all branches
export async function branch_all() {
    try {
        const response = await fetch(HTTPURL + 'api/branches-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get all class-list 
export async function class_list_all()
{
    try {
        const response = await fetch(HTTPURL + 'api/class-list-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all subjects 
export async function subject_all()
{
    try {
        const response = await fetch(HTTPURL + 'api/subject-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
