import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { student_enroll_list, enroll_student_payment } from '../../../apis/studentManagement/StudentManagement';
import { branch_all } from '../../../apis/Branches';
import EnrolmentStatus from './EnrolmentStatus';
import { toast } from "react-toastify";
const Student_enroll_view = () => {
    const [enrollView, setEnrollView] = useState([]);
    const [branch, setBranch] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [enrollStatus, setEnrollStatus] = useState('');
    const [subject,setSubject]=useState([]);
    const [term,setTerm]=useState([]);
    const [branchId,setBranchId]=useState('');
    const id = useParams();
    const navigate = useNavigate();
    const submitData = async () => {
        const formData = {
            'grade_id': enrollView?.grade_id,
            'user_id': enrollView?.user_id,
            'total_price': 10,
            'branch_id': branchId,
            'subject_id': enrollView?.subject_id,
            'payment_method': paymentMethod
        }
        if (enrollStatus === '1') {
            const res = await enroll_student_payment(formData);
            if (res?.status) {
                return navigate('/admin/students/members');
            }
        } else {
            toast.error('Enrolled status is required');
        }

    }
    useEffect(() => {
        async function get_data() {
            const data = await student_enroll_list(id);
            const branch = await branch_all();
            setEnrollView(data?.data?.results);
            setBranchId(data?.data?.results?.branch)
            
            // localStorage.setItem('course', JSON.stringify(data?.data?.results?.course || '-'));
            if(data?.data?.termWeekData){
                setSubject(JSON.parse(data?.data?.termWeekData?.subjectJson))
                setTerm(data?.data?.termWeekData?.term_id.split(','))
            }
            setBranch(branch?.data)
        }
        get_data();
    }, []);
    console.log('splitData',term);
    return (
        <div>
            <div className="right-nav" id="dash-event">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Student Detail</h4>
                        {/* <Link className='edit-btn' to={`/admin/students/member-edit/${id?.id}`}>Modify</Link> */}
                        <Link className='edit-btn' to={`/admin/students/members`}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>

                    </div>
                    <div className="modal-body">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="form-horizontal">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <p>{enrollView?.first_name ? enrollView?.first_name : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <p>{enrollView?.last_name ? enrollView?.last_name : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <p>{enrollView?.email ? enrollView?.email : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Mobile</label>
                                                <p>{enrollView?.student_number && enrollView?.student_number !== 'null' ? enrollView?.student_number : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Date of birth</label>
                                                <p>{enrollView?.date_of_birth ? enrollView?.date_of_birth : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Grade</label>
                                                <p>{enrollView?.grade ? enrollView?.grade : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Course </label>
                                                <p>{enrollView?.course ? enrollView?.course : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Term</label>
                                                <p>{enrollView?.term ? enrollView?.term : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Branch</label>
                                                <p>{enrollView?.branch_name ? enrollView?.branch_name : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <p>{enrollView?.subject ? enrollView?.subject : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>School</label>
                                                <p>{enrollView?.school ? enrollView?.school : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Student Type</label>
                                                <p>{enrollView?.student_type == 1 ? 'Enrolled' : 'Non-Enrolled'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Contact Details</h4>
                    </div>
                    <div className="modal-body">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="form-horizontal">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Address</label>
                                                <p>{enrollView?.address ? enrollView?.address : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>City</label>
                                                <p>{enrollView?.city ? enrollView?.city : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>State</label>
                                                <p>{enrollView?.state ? enrollView?.state : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Country</label>
                                                <p>{enrollView?.country ? enrollView?.country : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Post Code</label>
                                                <p>{enrollView?.post_code ? enrollView?.post_code : '-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Parent's Personal Details</h4>
                    </div>
                    <div className="modal-body">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="form-horizontal">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Parent's First Name</label>
                                                <p>{enrollView?.p_first_name ? enrollView?.p_first_name : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Parent's Last Name</label>
                                                <p>{enrollView?.p_last_name ? enrollView?.p_last_name : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Parent's Email</label>
                                                <p>{enrollView?.p_email ? enrollView?.p_email : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Parent's Mobile</label>
                                                <p>{enrollView?.father_mobilenumber && enrollView?.father_mobilenumber !== 'null' ? enrollView?.father_mobilenumber : '-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {enrollView?.student_type == '0' ? <div className='row'>
                    <hr />          
                <div className="modal-header">
                        <h4 className="modal-title">Shift to Enrolled</h4>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group '>
                            <label>Branch</label>
                            <select className='form-select form-control' onChange={(e)=>setBranchId(e.target.value)} >
                                <option value="">Choose a branch</option>
                                {
                                    branch && branch?.map((elem) => {
                                        return (
                                            <option key={enrollView?.branch} value={elem?.id}  selected={elem.id == enrollView?.branch ? 'selected' : null}>
                                            {elem.branch_name}
                                          </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor='enrolled'>Enrolled Status: </label>
                            <div className='highlight_inner'>
                                <div className=" checkbox">
                                    <label>
                                        <input type="checkbox" name='student_type' id="enrolled" value="1"
                                            onChange={(e) => setEnrollStatus(e.target.value)}
                                            required /> Yes </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor='enrolled'>Payment Method </label>
                            <div className='highlight_inner'>
                                <div className=" checkbox">
                                    <label>
                                        <input type="radio" name='payment_method' id="enrolled" value="cash"
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                        /> Cash </label>
                                </div>
                                <div className=" checkbox">
                                    <label>
                                        <input type="radio" name='payment_method' id="enrolled" value="online"
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                        /> Online </label>
                                </div>
                                <div className=" checkbox">
                                    <label>
                                        <input type="radio" name='payment_method' id="enrolled" value="payLater"
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                        /> Pay Later </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={() => submitData()}>
                        Save
                    </button>
                </div> : ''}

                <EnrolmentStatus subject={subject} term={term}/>
            </div>
        </div>

    )
}

export default Student_enroll_view;
