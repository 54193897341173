import { toast } from "react-toastify";
import { HTTPURL } from "../../../constants/Matcher";

export async function class_list_add(data)
{
    var requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
    };
    try {
        const response = await fetch(HTTPURL + 'api/class-list-add', requestOptions);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all teacher class-list 
export async function class_list_all(id) {
    try {
        const response = await fetch(HTTPURL + `api/teacher/teacher-classlist/?id=${id}`);
        const responseData = await response.json();
        // if (responseData.status) {
        //     toast.success(responseData?.message);
        // } else {
        //     toast.error(responseData?.message);
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// teacher class-list edit 
export async function class_list_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/class-list-edit/?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// class-list update 
export async function class_list_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/class-list-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}




// class-list delete
export async function class_list_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/class-list-distroy?id=${id}`)
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// classview
export async function classview(id) {
    try {
        const response = await fetch(HTTPURL + `api/class-list-view/?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_student_list_attendance(id) {
    try {
        const response = await fetch(HTTPURL + `api/teacher/get-student-class-attendance/?id=${id}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_student_attendance_date(date) {
    try {
        const response = await fetch(HTTPURL + `api/teacher/get-attendance/?date=${date}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// google class add 
export async function submit_attendance(data)
{
    try {
        const response = await fetch(HTTPURL + 'api/teacher/add-attendance', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get all admin class-list 
export async function admin_class_list_all(id) {
    try {
        const response = await fetch(HTTPURL + `api/class-list-edit/?id=${id}`);
        const responseData = await response.json();
        // if (responseData.status) {
        //     toast.success(responseData?.message);
        // } else {
        //     toast.error(responseData?.message);
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}