import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { class_list_all, add_study_material } from '../../../apis/ClassManagement/ClassList';
import { useEffect } from 'react';

const AddStudyMaterial = () => {
    const [classNameList, setClassNameList] = useState([]);
    const [image, setImage] = useState({ data: '' });
    let images = [];

    const handleFileChange = (e) => {
        console.log('e.target',e.target.files);
        for (let i = 0; i < e.target.files.length; i++) 
        {
            images.push(e.target.files[i]);
        }
        setImage({data:images});
    }


    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const onSubmit = async (data) => {
           const form = new FormData();
        image.data.forEach(img => {
            form.append('study_file', img);
        });
        form.append("class_id", getValues('class_name'));
        let result = await add_study_material(form);
        if (result.status) {
            return navigate('/admin/class-lists');
        }
    }
    
    useEffect(() => {
        const fetchData = async () => {
            const classNamelist = await class_list_all();
            setClassNameList(classNamelist?.data);
        };
        fetchData();
    }, []);
    return (
        <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Class</label>
                            <select className="form-select form-control" name="class_name" {...register("class_name")}>
                                <option value="">Choose Class</option>
                                {
                                    classNameList && classNameList?.map((elem) => {
                                        return <option value={elem?.id}>{elem?.c_name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Upload Material</label>
                                    <input type="file" className="form-control" multiple {...register("study_file")} onChange={(e) => handleFileChange(e)} />
                            
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type='submit' className="btn btn-primary">Save</button>
                </div>
            </div>
        </form>
    )
}

export default AddStudyMaterial;
