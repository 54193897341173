import React, { useEffect, useState } from 'react';
import { get_question } from '../../../../apis/testManagement/Question.jsx';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
import AddHomeWork from './Add.jsx';
import {get_text_books} from '../../../../apis/TextBook.jsx';
const EditHomework = ({ setHomeWork, homeWork, setHomeWorkData, homeWorkData }) => {
    const [subjects, setSubject] = useState([]);
    const [question, setQuestion] = useState([]);
    const [textBook, setTextBook] = useState([]);
    const handleHomeworkDataChange = (index, fieldname, value) => {
        if (fieldname == 'subject_id') {
            localStorage.setItem('courseSubjectID', value)
        }
        const updatedData = [...homeWorkData];
        updatedData[index] = { ...updatedData[index], [fieldname]: value };
        setHomeWorkData(updatedData);
    };
    //lecture handle change for image
    const handlehomeworkImgChange = (e, index) => {
        const { name, files } = e.target;
        const list = [...homeWorkData];
        list[index][name] = files[0];
        setHomeWorkData(list);
    }
    useEffect(() => {
        const fetchData = async () => {
            const questionList = await get_question();
            const result = await subject_all();
            const textBookList=await get_text_books();
            setQuestion(questionList?.data);
            setSubject(result?.data);
            setTextBook(textBookList?.data);
        };
        fetchData();
    }, []);
    return (
        <>

            {
                homeWorkData && homeWorkData.length > 0 ?

                    homeWorkData && homeWorkData?.map((ele, index, data) => {
                        return (
                            <>
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel1" aria-labelledby="pills-home-tab">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-6" key={index}>
                                                <div className="form-group">
                                                    <label>Upload Document {index + 1}</label>
                                                    <input type="file" className="form-control"
                                                        name='u_document'
                                                        onChange={(e) => handlehomeworkImgChange(e, index)} />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Embedded Link</label>
                                                    <input type="url" className="form-control"
                                                        placeholder='Enter Link'
                                                        name='link'
                                                        value={ele?.link}
                                                        onChange={(e) => handleHomeworkDataChange(index, 'link', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Upload Image</label>
                                                    <input type="file" className="form-control"
                                                        name='u_image'
                                                        onChange={(e) => handlehomeworkImgChange(e, index)} />

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Upload Audio</label>
                                                    <input type="file" className="form-control"
                                                        name='u_audio'
                                                        onChange={(e) => handlehomeworkImgChange(e, index)} />

                                                </div>
                                            </div>
                                            <div className="col-md-6" >
                                                <div className="form-group">
                                                    <label>Unit </label>
                                                    <input type="text" className="form-control"
                                                        placeholder='Enter Unit'
                                                        value={ele?.unit}
                                                        name='unit'
                                                        onChange={(e) => handleHomeworkDataChange(index, 'unit', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Week</label>
                                                    <input type="text" className="form-control"
                                                        placeholder='Enter Week'
                                                        value={ele?.week}
                                                        name='week'
                                                        onChange={(e) => handleHomeworkDataChange(index, 'week', e.target.value)}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-md-6" >
                                                <div className="form-group">
                                                    <label>Questionaire</label>
                                                    <select className="form-control form-select"
                                                        value={ele?.q_id}
                                                        name='q_id'
                                                        onChange={(e) => handleHomeworkDataChange(index, 'q_id', e.target.value)} >
                                                        <option value="">Choose Question</option>
                                                        {
                                                            question && question?.map((elem) => {
                                                                return (
                                                                    <option value={elem?.id}>{elem?.question_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Subject</label>
                                                    <select className="form-control form-select"
                                                        name='subject_id'
                                                        value={ele?.subject_id}
                                                        onChange={(e) => handleHomeworkDataChange(index, 'subject_id', e.target.value)}>
                                                        <option value="">Choose Subject</option>
                                                        {
                                                            subjects && subjects?.map((elem) => {
                                                                return <option value={elem?.id}>{elem?.subject}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Text Book</label>
                                                <select className="form-control form-select"
                                                    name='text_book_id'
                                                    value={ele.text_book_id}
                                                    onChange={(e) => handleHomeworkDataChange(index, 'text_book_id', e.target.value)}>
                                                    <option value="">Choose text book</option>
                                                    {
                                                        textBook && textBook?.map((elem) => {
                                                            return <option value={elem?.id}>{elem?.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Description</label>
                                                    <textarea type="text" className="form-control"
                                                        placeholder='ENTER Description'
                                                        value={ele?.description}
                                                        name='description'
                                                        onChange={(e) => handleHomeworkDataChange(index, 'description', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-2">
                                <div className='add_btn'>
                                    {
                                        lecture.length !== 1 && (
                                            <button className='btn-warning' onClick={() => handleLectureRemoveClick(i)}>Remove</button>
                                        )
                                    }
                                    {
                                        lecture.length - 1 === i && (
                                            <button className='btn-success' onClick={() => handleLectureAddClick()}>Add</button>
                                        )
                                    }
                                </div>
                            </div> */}
                                    <hr />
                                </div>
                            </>
                        )
                    })
                    :
                    <AddHomeWork setHomeWork={setHomeWork} homeWork={homeWork} />

            }

        </>
    )
}
export default EditHomework;






