import React from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { subject_all } from '../../../api/Subject.jsx';
import { class_list_add } from '../../../api/ClassList.jsx';
import { grade_all } from '../../../api/common.jsx';
import { get_category } from '../../../api/common.jsx';
import { get_terms } from '../../../api/common.jsx';
import { get_days } from '../../../api/common';
import { useState, useEffect } from 'react';

const AddClassList = () => {
    const [subjects, setSubject] = useState([]);
    const [gradelist, setGradeList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [termsList, setTermsList] = useState([]);
    const [file, setFile] = useState(null);
    const [days, setDays] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [image, setImage] = useState({ data: '' });

    let images = [];
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();

    const handleFileChange = (e) => {
        console.log('e.target', e.target.files);
        for (let i = 0; i < e.target.files.length; i++) {
            images.push(e.target.files[i]);
        }
        setImage({ data: images });
    }

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const form = new FormData();
        form.append('branch',b_id)
        form.append("file_path", file);
        form.append("class_info", getValues('class_info'));
        form.append("grade", getValues('grade'));
        form.append("kind", getValues('kind'));
        form.append("movie_period", getValues('movie_period'));
        form.append("movie_price", getValues('movie_price'));
        form.append("movie_title", getValues('movie_title'));
        form.append("sort", '1');
        form.append("subject_id", getValues('subject_id'));
        form.append("term", getValues('term'));
        form.append("year", getValues('year'));
        form.append("day", getValues('day'));
        form.append("time_start", getValues('time_start'));
        form.append("time_end", getValues('time_end'));
        form.append("google_meet_code", getValues('google_meet_code'));
        if (image.length > 0) {
            for (const img of image) {
                form.append('study_file', img);
            }
        }
            
        if (currentStep === 3) {
            let result = await class_list_add(form);
            if (result.status) {
                return navigate('/branch/classList/all');
            }
        } else {
            // Move to the next step
            setCurrentStep(currentStep + 1);
        }

    }
    const b_name=localStorage.getItem('b_name')
    const b_id=localStorage.getItem('b_id')
    useEffect(() => {
        async function get_data() {
            const result = await subject_all();
            const categorylist = await get_category();
            const gradeList = await grade_all();
            const termslist = await get_terms();
            const days = await get_days();
            setGradeList(gradeList?.data);
            setCategoryList(categorylist?.data);
            setSubject(result?.data);
            setTermsList(termslist?.data);
            setDays(days?.data);
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Classes</h4>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(1)} className={`nav-link ${currentStep === 1 ? 'active ' : ''}`} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Add Class</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(2)} className={`nav-link ${currentStep === 2 ? 'active ' : ''}`} id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Online Google Class</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(3)} className={`nav-link ${currentStep === 3 ? 'active ' : ''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Study Material</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            {currentStep === 1 && (
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Course Type</label>
                                                <select className="form-select form-control"  {...register("kind", { required: true })}>
                                                    <option value="">CHOOSE COURSE TYPE</option>
                                                    {
                                                        categoryList && categoryList?.map((elem) => {
                                                            return (
                                                                <option value={elem?.id}>{elem?.category_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {errors.kind && errors.kind.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Grade</label>
                                                <select className="form-control form-select" {...register("grade", { required: true })}>
                                                    <option value="">CHOOSE GRADE</option>
                                                    {
                                                        gradelist && gradelist?.map((elem) => {
                                                            return (
                                                                <option value={elem?.id}>{elem?.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {errors.grade && errors.grade.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Year</label>
                                                <select className="form-control form-select" {...register("year", { required: true })}>
                                                    <option value="">CHOOSE YEAR</option>
                                                    <option value="2020">2020</option>
                                                    <option value="2021">2021</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2027">2027</option>
                                                    <option value="2028">2028</option>
                                                    <option value="2029">2029</option>
                                                    <option value="2030">2030</option>
                                                </select>
                                            </div>
                                            {errors.year && errors.year.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Term</label>
                                                <select className="form-control form-select" {...register("term", { required: true })}>
                                                    <option value="">CHOOSE TERM</option>
                                                    {
                                                        termsList && termsList?.map((elem) => {
                                                            return (
                                                                <option value={elem?.id}>{elem?.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {errors.term && errors.term.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <select className="form-control form-select" {...register("subject_id", { required: true })}>
                                                    <option value="">CHOOSE SUBJECT</option>
                                                    {
                                                        subjects && subjects?.map((elem) => {
                                                            return <option value={elem?.id}>{elem?.subject}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {errors.subject_id && errors.subject_id.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <hr className='mt-3' />
                                            <div className="col-md-12">
                                                <label><b>Type</b></label>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>Title</label>
                                                        <input type="text" className="form-control"
                                                            placeholder='ENTER TITLE'
                                                            {...register('movie_title', { required: true })}
                                                        />
                                                    </div>
                                                    {errors.movie_title && errors.movie_title.type === "required" && <span className="validation-error">This is required</span>}
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label>File:</label>
                                                        <input
                                                            type="file" className='form-control'
                                                            {...register("file_path")}
                                                            onChange={(e) => setFile(e.target.files[0])}
                                                        />
                                                    </div>
                                                    {errors.file_path && errors.file_path.type === "required" && <span className="validation-error">This is required</span>}
                                                </div>
                                            </div>


                                        </div>


                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Period</label>
                                                <input type="text" className="form-control"
                                                    placeholder='ENTER PERIOD'
                                                    {...register('movie_period', { required: true })} /> days
                                            </div>
                                            {errors.movie_period && errors.movie_period.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Price</label>
                                                <input type="text" className="form-control"
                                                    placeholder='ENTER PRICE'
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    {...register('movie_price', { required: true })} /> $
                                            </div>
                                            {errors.movie_price && errors.movie_price.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Class Info.</label>
                                                <textarea className="form-control"
                                                    placeholder='ENTER CLASS INFO'
                                                    rows="3" {...register('class_info', { required: true })}></textarea>
                                            </div>
                                            {errors.class_info && errors.class_info.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {currentStep === 2 && (
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className='col-md-3 col-sm-12'>
                                                <div className='form-group mt-4'>
                                                    <label>Branch</label>
                                                    <input type="text" readOnly className="form-control" name="time_start" {...register("branch")} defaultValue={b_name} />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label>Day</label>
                                                    <select className="form-select form-control" {...register("day", { required: true })}>
                                                        <option value="">CHOOSE DAY</option>
                                                        {
                                                            days && days?.map((elem) => {
                                                                return (<option value={elem?.id}>{elem?.name}</option>);
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                {errors.day && errors.day.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                            <div className="col-md-6">
                                                <div className='time'>
                                                    <div className="form-group">
                                                        <label>Class Time</label>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label>Start</label>
                                                                <input type="time" className="form-control" name="time_start" {...register("time_start", { required: true })} />
                                                            </div>
                                                            {errors.time_start && errors.time_start.type === "required" && <span className="validation-error">This is required</span>}
                                                            <div className='col-md-6'>
                                                                <label>End</label>
                                                                <input type="time" className="form-control" {...register("time_end", { required: true })} />
                                                            </div>
                                                            {errors.time_end && errors.time_end.type === "required" && <span className="validation-error">This is required</span>}
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Google Meet Code <span style={{ color: 'red' }}> (* Code Only)</span></label>
                                                    <span>https://meet.google.com/</span>
                                                    <input type="text" className="form-control"
                                                        placeholder='ENTER GOOGLE MEET CODE'
                                                        {...register("google_meet_code", { required: true })} />
                                                </div>
                                                {errors.google_meet_code && errors.google_meet_code.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            )}
                            {currentStep === 3 && (
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Upload Material</label>
                                                    <input type="file" className="form-control"
                                                        multiple {...register("study_file", { required: true })} onChange={(e) => handleFileChange(e)} />

                                                </div>
                                                {errors.study_file && errors.study_file.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            {currentStep > 1 && (
                                <button type="button" className="btn btn-secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                                    Previous
                                </button>
                            )}
                            {currentStep < 3 && (
                                <button type="submit" className="btn btn-primary">
                                    Next
                                </button>
                            )}
                            {currentStep === 3 && (
                                <button type="submit" className="btn btn-primary">
                                    Save
                                </button>
                            )}
                        </div>
                    </form>
                </div>

            </div>
        </div >
    )
}

export default AddClassList;
