import React, { useState,useEffect } from 'react';
import { Form} from 'semantic-ui-react';
import { useForm , useFieldArray} from "react-hook-form";
import { useNavigate, useParams} from 'react-router-dom';
import { class_list_all } from '../../../../apis/ClassManagement/ClassList';
import { class_detail_edit, class_detail_update } from '../../../../apis/ClassManagement/ClassDetails';

import FileViewer from 'react-file-viewer';

const EditClassDetails = () => {
    const [className, setClassName] = useState([]);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: {
        list: [{ movie_name:"",file_path:"",movie_time:'',book_page:'',sort:'',media_id:'',maths_es:'',maths_en:'',maths_l2:'',maths_l3:'' }]
      }
    });
    const id = useParams();

    const {
        fields,
      } = useFieldArray({
        control,
        name: "list"
      });

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await class_detail_update(id,data);
        if (result.status) {
            return navigate('/admin/class-details');
        }
    }

    const fetchData = async () => 
    {
        let res = await class_detail_edit(id);
        setTimeout(() => {
            setValue(`class_id`, res?.data[0]?.class_id);
        },1000); 
    }

    useEffect(() => 
    {
        async function get_data() {
            const result = await class_list_all();
            setClassName(result?.data);
        }
          get_data();  
          fetchData();
    },[]);

    const file = '/public/admin_fav.png'; // Set the path to your file
    // Define the supported file types and their corresponding file viewers
    const fileTypes = {
      txt: 'text',
      pdf: 'pdf',
      doc: 'doc',
      docx: 'docx',
      xls: 'xls',
      xlsx: 'xlsx',
      ppt: 'png',
      pptx: 'pptx',
    };

    const type = file.split('.').pop(); // Extract the file extension
    const fileType = fileTypes[type] || ''; // Default to 'text' if the type is not recognized

    return (
        <div className="modal-content">
            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className="col-md-3 col-sm-12">
                        <div className='form-group'>
                            <label>Select a class:</label>
                            <select className='mt-2 mb-4 form-select form-control' {...register("class_id")}>
                                <option value="">Choose Class</option>
                                {
                                    className && className?.map((elem) => {
                                        return <option value={elem?.id}>{elem?.c_name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className='form-group'>
                            <label>File:</label>
                            <input type="file" className='form-control' {...register("file_path")}/>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className='form-group'>
                            <label>File Viewer</label>
                            <FileViewer
                                fileType={fileType}
                                filePath={file}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditClassDetails;

