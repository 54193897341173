import React from "react";
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { teacher_login } from "../../apis/Login";
import { useNavigate,Link } from 'react-router-dom';
import Logo from '../../../image/cslogo-300x34.png';

const TeacherLogin = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => 
    {
        data.roles = 'Teacher';
        let result = await teacher_login(data);
        if (!result) {
            return navigate('/teacher');
        } else {
            return result?.data?.roles === 'teacher' || result?.data?.roles === 'Teacher' ? navigate('/teacher/dashboard') : "/teacher";
        }
    }

    return (
        <>
            <div id="layoutAuthentication" className="login-page">
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header">
                                            <div className="login-head-logo"><img src={Logo} alt="CSLogo_white.png" /></div>
                                        </div>
                                        <div className="card-body">
                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="card-body text-black">
                                                    <div className="form-outline mb-4">
                                                        <Form.Field>
                                                            <label className="form-label" htmlFor="Email Address">EMAIL</label>
                                                            <input
                                                                placeholder='Email'
                                                                type="email"
                                                                {...register("email",
                                                                    {
                                                                        required: true,
                                                                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                                    })}
                                                                className="form-control form-control-lg"
                                                            />
                                                        </Form.Field>
                                                        {errors.email && errors.email.type === "required" && <span className="validation-error">This is required</span>}
                                                        {errors.email && errors.email.type === "pattern" && <span className="validation-error">Must be a valid email</span>}
                                                    </div>

                                                    <div className="form-outline mb-4">
                                                        <Form.Field>
                                                            <label className="form-label" htmlFor="Password">Password</label>
                                                            <input
                                                                placeholder='Password'
                                                                type="password"
                                                                {...register('password', {required: true})}
                                                                // {...register("password", {
                                                                //     required: true,
                                                                //     // pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                                                                // })}
                                                                className="form-control form-control-lg"
                                                            />
                                                        </Form.Field>
                                                        {errors.password && errors.password.type === "required" && <span className="validation-error">This is required</span>}
                                                        {/* {errors.password && errors.password.type === "pattern" && <span className="validation-error">At least 6 chars long,digit,upper case,lower case</span>} */}
                                                    </div>
                                                    <div className="text-right">
                                                    <Link to="/teacher/forgot-password">Forgot Password</Link>
                                                    </div>
                                                    <div className="d-flex justify-content-center pt-3">
                                                       <Button type='submit' className="btn btn-warning btn-lg ms-2 sap-btn-dark">Sign In</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
};

export default TeacherLogin ;