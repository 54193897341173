import React, { useEffect, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { class_list_all } from '../../../apis/ClassManagment/ClassList';
import { homeWork_update, homeWork_edit } from '../../../apis/HomeWork'


const EditHomeWork = () => {
    const [classlist, setClassList] = useState([]);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const id = useParams();

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        async function get_data() {
            const result = await class_list_all(userData?.data?.id);
            setClassList(result?.data);
        }
        get_data();
    }, []);



    const onSubmit = async (data) => {
        let result = await homeWork_update(id, data);
        if (result.status) {
            return navigate('/teacher/admin/home-work-list');
        }
    }
    const fetchData = async () => {
        let res = await homeWork_edit(id);
        setValue('class_id', res?.data?.c_id);
        setValue('status', res?.data?.status);
        setValue('description', res?.data?.description);
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modify homework</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Class</label>
                                    <select className="form-select form-control" name="event_type" {...register("class_id")}>
                                        <option value=''>CHOOSE CLASS</option>
                                        {
                                            classlist && classlist?.map((elem) => {
                                                return (
                                                    <option value={elem?.id}>{elem?.c_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>status</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio1" value="1" />
                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio2" value="0" />
                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label> Description</label>
                                    <textarea className="form-control"
                                     placeholder='ENTER DESCRIPTION'
                                    name="description" rows="3" {...register('description')}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}


export default EditHomeWork;