import { toast } from "react-toastify";
import { HTTPURL } from "../../constants/Matcher";

export async function class_list_add(data)
{
    var requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
    };
    try {
        const response = await fetch(HTTPURL + 'api/class-list-add', requestOptions);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all class-list 
export async function class_list_all()
{
    try {
        const response = await fetch(HTTPURL + 'api/class-list-all');
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// class-list edit 
export async function class_list_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/class-list-edit/?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// class-list update 
export async function class_list_update(id,data)
{
    var requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
    };


    try {
        const response = await fetch(HTTPURL + `api/class-list-update/?id=${id?.id}`, requestOptions);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// class-list delete
export async function class_list_destroy(id) 
{
    try {
        const response = await fetch(HTTPURL + `api/class-list-distroy?id=${id}`)
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// classview
export async function classview(id) {
    try {
        const response = await fetch(HTTPURL + `api/class-list-view/?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// uplods study material
export async function add_study_material(data)
{
    var requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
    };
    try {
        // const local =  `http://127.0.0.1:4040/`;
        const response = await fetch(HTTPURL + 'api/upload-study-material', requestOptions);
        const responseData = await response.json();
        if(responseData.status)
        {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
