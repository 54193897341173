import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { branch_all } from '../../../apis/common';
import { google_class_add } from '../../../apis/ClassManagment/GoogleClass';
import { grade_all } from '../../../apis/common';
import { class_list_all } from '../../../apis/common';
import { get_days } from '../../../apis/common';

import { useEffect } from 'react';

const GoogleClassAdd = () => {
    const [show, setShow] = useState(false);
    const [branchlist, setBranchList] = useState([]);
    const [gradelist, setGradeList] = useState([]);
    const [classNameList, setClassNameList] = useState([]);
    const [days, setDays] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await google_class_add(data);
        if (result.status) {
            return navigate('/teacher/admin/class-list');
        }
    }
    // onchange 
    const onChangeBrach = (e) => {
        const [name, value] = e.target;
        setShow(true);
    }
    useEffect(() => {
        const fetchData = async () => {
            const branchList = await branch_all();
            const gradeList = await grade_all();
            const classNamelist = await class_list_all();
            const days = await get_days();
            setBranchList(branchList?.data);
            setGradeList(gradeList?.data);
            setClassNameList(classNamelist?.data);
            setDays(days?.data)
        };
        fetchData();
    }, []);
    return (
        <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
                <div className='col-md-3 col-sm-12'>
                    <div className='form-group mt-4'>
                        <label>Branch</label>
                        <select className='form-select form-control' {...register("branch")} onChange={(e) => onChangeBrach(e)}>
                            <option value="">Choose a branch</option>
                            {
                                branchlist?.length > 0 ?
                                    branchlist && branchlist?.map((elem) => {
                                        return (
                                            <option value={elem?.id}>{elem?.branch_name}</option>
                                        )
                                    })
                                    :
                                    <option>No result</option>
                            }
                        </select>
                    </div>
                </div>
            </div>
            <hr />
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Grade</label>
                            <select className="form-select form-control" {...register("grade")}>
                                <option value="">Choose Grade</option>
                                {
                                    gradelist?.length > 0 ?
                                        gradelist && gradelist?.map((elem) => {
                                            return (
                                                <option value={elem?.id}>{elem?.name}</option>
                                            )
                                        })
                                        :
                                        <option>No result</option>
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="form-group">
                            <label>Day</label>
                            <select className="form-select form-control" {...register("day")}>
                                <option value="">Choose Day</option>
                                {
                                    days && days?.map((elem) => {
                                        return (<option value={elem?.id}>{elem?.name}</option>);
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Class</label>
                            <select className="form-select form-control" name="class_name" {...register("class_name")}>
                                <option value="">Choose Class</option>
                                {
                                    classNameList && classNameList?.map((elem) => {
                                        return <option value={elem?.id}>{elem?.movie_title}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Class Time</label>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label>Start</label>
                                    <input type="time" className="form-control" name="time_start" {...register("time_start")} />
                                </div>
                                <div className='col-md-6'>
                                    <label>End</label>
                                    <input type="time" className="form-control" name="time_end" {...register("time_end")} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Google Meet Code <span style={{ color: 'red' }}> (* Code Only)</span></label>
                            <span>https://meet.google.com/</span>
                            <input type="text" className="form-control" {...register("google_meet_code")} />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type='submit' className="btn btn-primary">Save</button>
                </div>
            </div>
        </form>
    )
}

export default GoogleClassAdd;
