import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { course_edit, course_update } from '../../../api/Course/Course';

const EditCourse = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const id = useParams();

    const fetchData = async () => {
        let res = await course_edit(id);
        setValue('name', res?.data[0]?.name);
        setValue('status', res?.data[0]?.status);
    }
    useEffect(() => {
        fetchData();
    }, []);

    const onSubmit = async (data) => {
        let result = await course_update(id, data);
        if (result.status) {
            return navigate('/branch/course/all');
        }
    }

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modify course</h4>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal"
                        onSubmit={handleSubmit(onSubmit)} >
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>course Name</label>
                                    <input type="text" className="form-control"
                                        {...register('name', { required: true })}
                                    />
                                </div>
                                {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label>Course Image</label>
                                    <input className="form-control" type='file' ref={inputFile}
                                        name='image'  {...register('image')} />
                                </div>
                            </div> */}
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>status</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio1" value="1" />
                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio2" value="0" />
                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type='submit' className="btn btn-primary">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    )
}

export default EditCourse;
