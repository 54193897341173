import React from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { subject_all_by_grade_id ,get_step_of_learning} from '../../../../apis/Common.jsx';
import { class_list_add } from '../../../../branches/api/ClassList.jsx';
import { grade_all } from '../../../../branches/api/common.jsx';
import { course_all } from '../../../../apis/Course.jsx';
import { get_terms } from '../../../../branches/api/common.jsx';
import { get_days } from '../../../../branches/api/ClassList.jsx';
import { branch_all } from '../../../../apis/Branches.jsx';
import { get_question } from '../../../../apis/testManagement/Question.jsx';

const AddClassList = () => {
    const [subjects, setSubject] = useState([]);
    const [branch, setBranch] = useState([]);
    const [gradelist, setGradeList] = useState([]);
    const [course, setCourse] = useState([]);
    const [termsList, setTermsList] = useState([]);
    const [days, setDays] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [images, setImages] = useState([]);
    const [u_price, setUprice] = useState();
    const [sel_grade, setSelGrade] = useState();
    const [question, setQuestion] = useState([]);
    const [learnigStep,setLearningStep]=useState([]);

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();

    const handleFileChange = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            if (!images.includes(e.target.files[i])) {
                setImages(pre => [...pre, e.target.files[i]])
            }

        }
    }
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const form = new FormData();
        form.append('c_name', getValues('c_name'))
        form.append('branch', getValues('branch'))
        // form.append("file_path", file);
        // form.append("class_info", getValues('class_info'));
        form.append("grade", sel_grade);
        form.append("course_id", getValues('course_id'));
        // form.append("movie_period", getValues('movie_period'));
        form.append("movie_price", u_price);
        // form.append("movie_title", getValues('movie_title'));
        form.append("sort", '1');
        form.append("subject_id", getValues('subject_id'));
        form.append("term", getValues('term'));
        form.append("year", getValues('year'));
        // form.append("c_time_start", getValues('c_time_start'));
        // form.append("c_time_end", getValues('c_time_end'));
        form.append("day", getValues('day'));
        form.append("time_start", getValues('time_start'));
        form.append("time_end", getValues('time_end'));
        form.append("google_meet_code", getValues('google_meet_code'));
        form.append("question_id", getValues('question_id'));
        form.append("status", getValues('status'));
        // form.append('study_file', image);
        for (const img of images) {
            form.append('study_file', img);
        }
        if (currentStep === 4) {
            let result = await class_list_add(form);
            if (result.status) {
                return navigate('/admin/class-lists');
            }
        } else {
            // Move to the next step
            setCurrentStep(currentStep + 1);
        }
    }

    const getValId = (e) => {
        const s_id = parseInt(e.target.value);
        const selectedCategory = course.find((elem) => elem.id === s_id);
        console.log(selectedCategory);
        const { grade_id, price } = selectedCategory;
        setUprice(price);
        setSelGrade(grade_id)
    }
    useEffect(() => {
        async function get_data() {
            const categorylist = await course_all();
            const gradeList = await grade_all();
            const termslist = await get_terms();
            const days = await get_days();
            const branch = await branch_all();
            const question = await get_question();
            const getData =await get_step_of_learning();
            setGradeList(gradeList?.data);
            setCourse(categorylist?.data);
            setTermsList(termslist?.data);
            setDays(days?.data);
            setBranch(branch?.data);
            setQuestion(question?.data);
            setLearningStep(getData?.data);
        }
        get_data();
    }, []);
    useEffect(() => {
        async function getSubject() {
            const result = await subject_all_by_grade_id(sel_grade);
            setSubject(result?.data);
        }
        getSubject();
    }, [sel_grade]);
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Classes</h4>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                        {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(1)} className={`nav-link ${currentStep === 1 ? 'active ' : ''}`} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Add Class</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(2)} className={`nav-link ${currentStep === 2 ? 'active ' : ''}`} id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Online Google Class</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(3)} className={`nav-link ${currentStep === 3 ? 'active ' : ''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Study Material</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => setCurrentStep(4)} className={`nav-link ${currentStep === 4 ? 'active ' : ''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Questionnaire</button>
                            </li>
                        </ul> */}
                        <div className="tab-content" id="pills-tabContent">
                            {/* {currentStep === 1 && ( */}
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group doller-add">
                                            <label>Class Name</label>
                                            <input type="text" className="form-control"
                                                placeholder='Enter Class Name'
                                                {...register('c_name', { required: true })} />
                                        </div>
                                        {errors.c_name && errors.c_name.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Step of learnig</label>
                                            <select className="form-select form-control"  {...register("learning_step", { required: true })} >
                                                <option value="">CHOOSE STEP OF LEARNING </option>
                                                {
                                                learnigStep && learnigStep?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {errors.learning_step && errors.learning_step.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Course</label>
                                            <select className="form-select form-control"  {...register("course_id", { required: true })} onChange={(e) => getValId(e)}>
                                                <option value="">Choose Course </option>
                                                {
                                                    course && course?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {errors.course_id && errors.course_id.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Grade</label>
                                            <select className="form-control form-select" value={sel_grade} {...register("grade")} disabled>
                                                <option value="">CHOOSE GRADE</option>
                                                {
                                                    gradelist && gradelist?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {/* {errors.grade && errors.grade.type === "required" && <span className="validation-error">This is required</span>} */}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Year</label>
                                            <select className="form-control form-select" {...register("year", { required: true })}>
                                                <option value="">CHOOSE YEAR</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                            </select>
                                        </div>
                                        {errors.year && errors.year.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Term</label>
                                            <select className="form-control form-select" {...register("term", { required: true })}>
                                                <option value="">CHOOSE TERM</option>
                                                {
                                                    termsList && termsList?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {errors.term && errors.term.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Subject</label>
                                            <select className="form-control form-select" {...register("subject_id", { required: true })}>
                                                <option value="">CHOOSE SUBJECT</option>
                                                {
                                                    subjects && subjects?.map((elem) => {
                                                        return <option value={elem?.id}>{elem?.subject}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {errors.subject_id && errors.subject_id.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group doller-add">
                                            <label>Unit Price</label>
                                            <input type="text" className="form-control"
                                                placeholder='ENTER PRICE'
                                                value={u_price || 0.00}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                {...register('movie_price')} disabled /><div className='doller'>$</div>
                                        </div>
                                        {/* {errors.movie_price && errors.movie_price.type === "required" && <span className="validation-error">This is required</span>} */}
                                    </div>

                                </div>
                                {/* <div className="row">
                                        <div className="col-12">
                                            <hr className='mt-3' />
                                            <div className="col-md-12">
                                                <label><b>Type</b></label>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>Title</label>
                                                        <input type="text" className="form-control"
                                                            placeholder='ENTER TITLE'
                                                            {...register('movie_title', { required: true })}
                                                        />
                                                    </div>
                                                    {errors.movie_title && errors.movie_title.type === "required" && <span className="validation-error">This is required</span>}
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label>File:</label>
                                                        <input
                                                            type="file" className='form-control'
                                                            {...register("file_path")}
                                                            onChange={(e) => setFile(e.target.files[0])}
                                                        />
                                                    </div>
                                                    {errors.file_path && errors.file_path.type === "required" && <span className="validation-error">This is required</span>}
                                                </div>
                                            </div>


                                        </div>


                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Lessons</label>
                                                <input type="text" className="form-control"
                                                    placeholder='ENTER PERIOD'
                                                    {...register('movie_period', { required: true })} /> days
                                            </div>
                                            {errors.movie_period && errors.movie_period.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                       
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Class Info.</label>
                                                <textarea className="form-control"
                                                    placeholder='ENTER CLASS INFO'
                                                    rows="3" {...register('class_info', { required: true })}></textarea>
                                            </div>
                                            {errors.class_info && errors.class_info.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div> */}
                            </div>
                            {/* )} */}
                            {/* {currentStep === 2 && ( */}
                            <div className="modal-header">
                                <h4 className="modal-title">Online Google Class</h4>
                            </div>
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Branch</label>
                                                <select className="form-select form-control"  {...register("branch", { required: true })}>
                                                    <option value="">CHOOSE BRANCH</option>
                                                    <option value="1">test BRANCH</option>
                                                    {
                                                        branch && branch?.map((elem) => {
                                                            return (
                                                                <option value={elem?.id}>{elem?.branch_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label>Day</label>
                                                <select className="form-select form-control" {...register("day", { required: true })}>
                                                    <option value="">CHOOSE DAY</option>
                                                    {
                                                        days && days?.map((elem) => {
                                                            return (<option value={elem?.id}>{elem?.name}</option>);
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {errors.day && errors.day.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                        <div className="col-md-6">
                                            <div className='time'>
                                                <div className="form-group">
                                                    <label>Class Time</label>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label>Start</label>
                                                            <input type="time" className="form-control" name="time_start" {...register("time_start", { required: true })} />
                                                        </div>
                                                        {errors.time_start && errors.time_start.type === "required" && <span className="validation-error">This is required</span>}
                                                        <div className='col-md-6'>
                                                            <label>End</label>
                                                            <input type="time" className="form-control" {...register("time_end", { required: true })} />
                                                        </div>
                                                        {errors.time_end && errors.time_end.type === "required" && <span className="validation-error">This is required</span>}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Google Meet Code <span style={{ color: 'red' }}> (* Code Only)</span></label>
                                                <span>https://meet.google.com/</span>
                                                <input type="text" className="form-control"
                                                    placeholder='ENTER GOOGLE MEET CODE'
                                                    {...register("google_meet_code", { required: true })} />
                                            </div>
                                            {errors.google_meet_code && errors.google_meet_code.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* )} */}
                            {/* {currentStep === 3 && ( */}
                              {/* <div className="modal-header">
                                <h4 className="modal-title">Study Material</h4>
                            </div>
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Upload Material</label>
                                                <input type="file" className="form-control"
                                                    multiple {...register("study_file", { required: true })} onChange={(e) => handleFileChange(e)} />

                                            </div>
                                            {errors.study_file && errors.study_file.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                </div>
                            </div> */}


                            {/* )} */}
                            {/* {currentStep === 4 && ( */}
                              <div className="modal-header">
                                <h4 className="modal-title">Questionnaire</h4>
                            </div>
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Questionnaire</label>
                                                <select className="form-control form-select" {...register("question_id")}>
                                                    <option value="">CHOOSE QUESTION</option>
                                                    {
                                                        question && question?.map((elem) => {
                                                            return (
                                                                <option value={elem?.id}>{elem?.question_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {/* {errors.course_type_id && errors.course_type_id.type === "required" && <span className="validation-error">This is required</span>} */}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>status</label>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio1" value="1" />
                                                    <label class="form-check-label" for="inlineRadio1">Active</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio2" value="0" />
                                                    <label class="form-check-label" for="inlineRadio2">Inactive</label>
                                                </div>
                                            </div>
                                            {/* {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* )} */}
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">
                                Save
                            </button>
                            {/* {currentStep > 1 && (
                                <button  className="btn btn-secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                                    Previous
                                </button>
                            )}
                            {currentStep < 4 && (
                                <button  className="btn btn-primary">
                                    Next
                                </button>
                            )}
                            {currentStep === 4 && (
                                <button type="submit" className="btn btn-primary">
                                    Save
                                </button>
                            )} */}
                        </div>
                    </form>
                </div>

            </div>
        </div >
    )
}

export default AddClassList;
