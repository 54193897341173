import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get_website_data } from '../../../api/WebsitEnv'
const Websit_env_view = () => {
    const [envView, setEnvView] = useState([]);
    useEffect(() => {
        async function get_data() {
            const envView = await get_website_data();
            setEnvView(JSON.parse(envView?.data[0]?.env_data));
        }
        get_data();
    }, []);
    return (
        <div>
            <h4 className="modal-title">Website Env</h4>
            <div className="right-nav" id="dash-event">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Website Env Detail</h4>
                    </div>
                    <div className="modal-body">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                <div className="form-horizontal">
                                    <div className="row">
                                    <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Max Week Term(1)</label>
                                                <p>{envView?.m_w_term1 ? envView?.m_w_term1 : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Max Week Term(2)</label>
                                                <p>{envView?.m_w_term2 ? envView?.m_w_term2 : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Max Week Term(3)</label>
                                                <p>{envView?.m_w_term3 ? envView?.m_w_term3 : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Max Week Term(4)</label>
                                                <p>{envView?.m_w_term4 ? envView?.m_w_term4 : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Start Date Term(1)</label>
                                                <p>{envView?.s_d_term1 ? envView?.s_d_term1 : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Start Date Term(2)</label>
                                                <p>{envView?.s_d_term2 ? envView?.s_d_term2 : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Start Date Term(3)</label>
                                                <p>{envView?.s_d_term3 ? envView?.s_d_term3 : '-'}</p>
                                            </div>
                                        </div> 
                                         <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Start Date Term(4)</label>
                                                <p>{envView?.s_d_term4 ? envView?.s_d_term4 : '-'}</p>
                                            </div>
                                        </div> 
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Current Term</label>
                                                <p>{envView?.c_term ? envView?.c_term : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Current Year</label>
                                                <p>{envView?.c_year ? envView?.c_year : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Classtest Barnches</label>
                                                <p>{envView?.ct_branches ? envView?.ct_branches : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Default Period 4 PA</label>
                                                <p>{envView?.e_d_period ? envView?.e_d_period : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Sdate G</label>
                                                <p>{envView?.g_date ? envView?.g_date : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Sdate M</label>
                                                <p>{envView?.m_date ? envView?.m_date : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Sdate E</label>
                                                <p>{envView?.s_date ? envView?.s_date : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Sdate W</label>
                                                <p>{envView?.w_date ? envView?.w_date : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Test Period</label>
                                                <p>{envView?.t_period ? envView?.t_period : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Holi Off Test Review Period</label>
                                                <p>{envView?.h_period ? envView?.h_period : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Off Test Review Period</label>
                                                <p>{envView?.ot_period ? envView?.ot_period : '-'}</p>
                                            </div>
                                        </div><div className="col-md-3">
                                            <div className="form-group">
                                                <label>Test Review Period</label>
                                                <p>{envView?.r_period ? envView?.r_period : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Review Date</label>
                                                <p>{envView?.r_date ? envView?.r_date : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Unit Test Start Week</label>
                                                <p>{envView?.ut_s_week ? envView?.ut_s_week : '-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Websit_env_view;
