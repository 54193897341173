import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import CustomTable from '../CustomTable';
import { teacher_all, teacher_destroy } from '../../../apis/Teachers';
import {get_step_of_learning} from '../../../apis/Common'

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },
    // {
    //     name: 'Action',
    //     selector: 'action',
    //     sortable: true,
    // },
    
];

const AllLearningDetail = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        async function get_data() {
            const result = await get_step_of_learning();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, name,status } = elem;
                return {
                    no: `#${index + 1}`,
                    name: name,
                    status: status == 0 ? 'Inactive' : 'Active',
                    // action: <><Link className='edit-btn' to={`/admin/teachers-management/edit/${id}`}>Modify</Link><button className="dlt-btn" onClick={() => destroy(id)}> Delete</button></>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete this teacher?");
            if (result) {
                teacher_destroy(id);
            }
            get_data();
        }
        get_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Step Of  Learning</h4>
                </div>
                <div className='col-md-8'>
                    {/* <Link className='ui button float-end' to="/admin/teachers-management/add/">Add Teacher</Link> */}
                </div>
            </div>
            {
                showResults ?
                    
                        <CustomTable
                            columns={columns}
                            data={data}
                        />
                    :
                    <DataLoader />
            }
        </div>
    )
}
export default AllLearningDetail;
