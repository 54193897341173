import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';
import { enrolled_course_status_save, get_eligible_courses, get_enrolled_course_status } from '../../../branches/api/branches/Branch';
import { useNavigate, useParams } from 'react-router-dom';
import { subject_all } from '../../../apis/ClassManagement/Subjects';

const EnrolmentStatus = (subject) => {
  const { id } = useParams();
  const { handleSubmit } = useForm();
  const [termData, setTermData] = useState([]);
  const [selectedTermsByCourse, setSelectedTermsByCourse] = useState({});
  const [termSelectedUnitsByCourse, setTermSelectedUnitsByCourse] = useState({});
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [eligibleCourses, setEligibleCourses] = useState([]);
  const navigate = useNavigate();

  async function get_enroll_data() {
    const result = await get_enrolled_course_status(id);
    const course_selected_data = result?.data || [];
    console.log("coursedata", course_selected_data);
    const result2 = await get_eligible_courses(id);
    setEligibleCourses(result2?.data || []);
  
    // const student_current_term = course_selected_data.student_current_term; // define student_current_term here
    // console.log("studentterm", student_current_term);
    let student_current_term;
    const flattenedData = course_selected_data.flat();
    const termsByCourseId = {};
  
    flattenedData.forEach((term) => {
      const courseId = term.course_id;
      const courseName = term.course_name; // extract course name
      const courseTypeId = term.course_type_id; // extract course type id
      const category_name = term.category_name;
       student_current_term= term.student_current_term
  
      if (!termsByCourseId[courseId]) {
        termsByCourseId[courseId] = [];
      }
      termsByCourseId[courseId].push({ ...term, courseName, courseTypeId, category_name }); // add course name and type id to term object
    });
  

    // Filter out terms that don't have units or have blank data
    Object.keys(termsByCourseId).forEach((courseId) => {
      termsByCourseId[courseId] = termsByCourseId[courseId].filter((term) => {
        return term.data && term.data.length > 0;
      });
    });

    setTermData(termsByCourseId);

    // Automatically check terms that have units per course
    const preSelectedTermsByCourse = {};
    const preSelectedUnitsByCourse = {};

    // Iterate over each course
    Object.keys(termsByCourseId).forEach((courseId) => {
      const courseTerms = termsByCourseId[courseId];

      courseTerms.forEach((term, termIndex) => {
        if (term.data && term.data.length > 0) {
          if (!preSelectedTermsByCourse[courseId]) {
            preSelectedTermsByCourse[courseId] = [];
          }
          if (!preSelectedUnitsByCourse[courseId]) {
            preSelectedUnitsByCourse[courseId] = [];
          }
          console.log("winter***",student_current_term);
          
          // Check if the term is in the student_current_term array
        if  (Array.isArray(student_current_term) && student_current_term.includes(term.name)) {
            preSelectedTermsByCourse[courseId].push(term.name);
          }

          // Parse the term data and preselect units for each subject
          const parsedData = JSON.parse(term.data);
          const selectedUnitsForTerm = parsedData.map((subjectObj) => subjectObj.unit_id);

          // Ensure that units are tracked for the term
          if (!preSelectedUnitsByCourse[courseId][termIndex]) {
            preSelectedUnitsByCourse[courseId][termIndex] = [];
          }

          preSelectedUnitsByCourse[courseId][termIndex] = selectedUnitsForTerm;
        }
      });
    });

    setSelectedTermsByCourse(preSelectedTermsByCourse);
    setTermSelectedUnitsByCourse(preSelectedUnitsByCourse);
  }

  
  useEffect(() => {
    if (subject?.subject && subject?.subject.length > 0) {
      get_enroll_data();
    }
  }, [subject?.subject]);

  const handleCheckboxChange = (courseId, termName) => {
    setSelectedTermsByCourse((prevSelectedTerms) => {
      const updatedSelectedTerms = { ...prevSelectedTerms };
      if (!updatedSelectedTerms[courseId]) {
        updatedSelectedTerms[courseId] = [];
      }
      const termIndex = updatedSelectedTerms[courseId].indexOf(termName);
      if (termIndex === -1) {
        updatedSelectedTerms[courseId].push(termName);
      } else {
        updatedSelectedTerms[courseId].splice(termIndex, 1);
      }
      return updatedSelectedTerms;
    });
  };

  const handleLectureInputChange = (e, courseId, termIndex, subjectIndex, unitIndex) => {
    setTermSelectedUnitsByCourse((prevSelectedUnits) => {
      const updatedUnits = { ...prevSelectedUnits };
      if (!updatedUnits[courseId]) {
        updatedUnits[courseId] = [];
      }
      if (!updatedUnits[courseId][termIndex]) {
        updatedUnits[courseId][termIndex] = [];
      }

      const update = updatedUnits[courseId][termIndex][subjectIndex] || [];

      const unitPosition = update?.indexOf(unitIndex);
      if (unitPosition !== -1) {
        update.splice(unitPosition, 1);
      } else {
        update.push(unitIndex);
      }

      updatedUnits[courseId][termIndex][subjectIndex] = update;
      return updatedUnits;
    });
  };

  const onSubmit = async (data) => {
    // Create a submission payload structured by courseId
    const submissionData = Object.keys(termData).map((courseId) => {
      const terms = termData[courseId]
        .filter((term) => selectedTermsByCourse[courseId]?.includes(term.name))
        .map((term) => term.name)
      return {
        courseId,
        terms: [terms.flat()] // flatten the terms array
      };
    });

    const payload = {
      student_id: id,
      courses: submissionData,
      newcourse:selectedCourse
    };

    console.log("Submission Payload", payload);

    // Call the save API with the structured data
    const result = await enrolled_course_status_save(payload);
    if (result?.status) {
      navigate("/admin/students/members");
      get_enroll_data();
    }
  };

  return (
    <div>
      <section className="courses-sec">
        
<div className="modal-header">
          <h4 className="modal-title">Enrolment Status</h4>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="courses-content">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                {Object.keys(termData).map((courseId, index) => (
                      <li key={courseId} className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${index === 0 ? 'active' : ''}`}
                          id={`course-tab-${courseId}`}
                          data-bs-toggle="tab"
                          data-bs-target={`#course-${courseId}`}
                          type="button"
                          role="tab"
                          aria-controls={`course-${courseId}`}
                          aria-selected={index === 0}
                        >
                          Course ID: {courseId}
                        </button>
                      </li>
                    ))}
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Add Course
                      </a>
                      <ul class="dropdown-menu">
                        {eligibleCourses.map((course) => (
                          <li key={course.id}>
                            <a class="dropdown-item" href="#" onClick={() => setSelectedCourse(course.id)}>
                              {course.name}  ({course.term})
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                    {Object.keys(termData).map((courseId) => (
                      <div
                        key={courseId}
                        className={`tab-pane fade ${courseId === Object.keys(termData)[0] ? 'show active' : ''}`}
                        id={`course-${courseId}`}
                        role="tabpanel"
                        aria-labelledby={`course-tab-${courseId}`}
                      >
                        <h4>Name: {termData[courseId][0].courseName}</h4>
                        <h4>Course Type: {termData[courseId][0].category_name}</h4>
                        {termData[courseId].map((term, termIndex) => (
                          <div key={termIndex} className="terms-sec">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="term"
                                checked={selectedTermsByCourse[courseId]?.includes(term.name) || false}
                                onChange={() => handleCheckboxChange(courseId, term.name)}
                              />
                              <label className="form-check-label" htmlFor="flexCheckChecked">
                                <b>Term: {term.name}</b>
                                {/* {term.data && term.data.length > 0 ? (
                                  <span>Units available for this Term.</span>
                                ) : (
                                  <span>No Unit available for this Term.</span>
                                )} */}
                              </label>
                            </div>
                            {term.data && term.data.length > 0 ? (
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Subject</th>
                                    <th scope="col">Units</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {JSON.parse(term.data).map((subjectObj, subjectIndex) => (
                                    <tr key={subjectIndex}>
                                      <td>
                                        {subject?.subject.find((sub) => sub.id === subjectObj.subject_id)?.sub.label || `${subjectObj.subject}`}
                                      </td>
                                      <td>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                          {subjectObj.unit_id.map((unit, unitIndex) => (
                                            <div key={unitIndex} style={{ border: '1px solid #ccc', padding: '5px 15px 5px 30px',borderRadius: '45px', marginRight: 10, marginBottom: 10 }}>
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  checked={termSelectedUnitsByCourse[courseId]?.[termIndex]?.[subjectIndex]?.includes(unit) || false}
                                                  onChange={(e) => handleLectureInputChange(e, courseId, termIndex, subjectIndex, unit)}
                                                />
                                                {unit}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <p>No units available for this term.</p>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
                <div className="course-btn">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Form>
      </section>
      
    </div>
  );
};

export default EnrolmentStatus;

