import React, { useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { subject_add, subject_edit, subject_update } from '../../../../apis/ClassManagment/Subject';


const EditSubject = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const id = useParams();

    const onSubmit = async (data) => {
        let result = await subject_update(id, data);
        if (result.status) {
            return navigate('/teacher/admin/subject-list');
        }
    }
    const fetchData = async () => {
        let res = await subject_edit(id);
        setValue('subject', res?.data[0]?.subject);
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modify Subject</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Subject</label>
                                    <input type="text" className="form-control"
                                        {...register('subject', { required: true })}
                                    />
                                </div>
                                {errors.subject && errors.subject.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>status</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio1" value="1" />
                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" {...register('status')} id="inlineRadio2" value="0" />
                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}


export default EditSubject;